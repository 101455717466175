var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stratigraphies_3d"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"main",fn:function(){return [(!_vm.archive)?_c('div',{staticClass:"grid grid-cols-4 gap-4 my-10"},[_c('div',{staticClass:"col-span-3 text-white py-3 px-5"},[_vm._v(_vm._s(_vm.$t('components.threed_visualizer.select_archive')))]),_c('VSelect',{staticClass:"col-span-1 p-1 mx-4 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none",attrs:{"model":"archeological_archives","search-field":"name","label":"name","value-field":"id","placeholder":_vm.$t('global.select')},on:{"selected":_vm.getArchive},model:{value:(_vm.archive),callback:function ($$v) {_vm.archive=$$v},expression:"archive"}})],1):_vm._e(),(_vm.archive && _vm.hasThreeDData())?_c('div',[_c('div',{staticClass:"box-content px-4 py-2 text-white flex items-center justify-between"},[_c('div',{staticClass:"left"},[_c('h2',{staticClass:"text-xl"},[_vm._v(_vm._s(_vm.$t('plugins.rometransformed.views.stratigraphies_3d.subtitle')))]),_c('button',{staticClass:"px-3 py-2 text-sm border-slate-900 bg-slate-600 text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:bg-slate-900",on:{"click":_vm.clearCanvas}},[_c('f-icon',{attrs:{"icon":"circle-minus"}}),_vm._v(" "+_vm._s(_vm.$t('actions.clear_canvas')))],1)]),_c('div',{staticClass:"right flex gap-1"},[_c('ButtonShapefileExporter',{attrs:{"title":_vm.$t('actions.export_shapefile'),"model":"stratigraphies","archive":_vm.archive.id,"categoryModel":"interpretations"}}),_c('ButtonShapefileExporter',{attrs:{"title":"Observations SHP","model":"observations","archive":_vm.archive.id}})],1)]),_c('ThreeDVisualizer',{attrs:{"model":"gis/geometries/stratigraphies","archive":_vm.archive.id,"background":_vm.getBackground(),"model-options":{
            scale: 18.5, rotation: -90, pan: true, height: 30, 
            center_x: _vm.archive.center_x, 
            center_y: _vm.archive.center_y, 
            nw: _vm.archive.nw,
            ne: _vm.archive.ne,
            sw: _vm.archive.sw,
            se: _vm.archive.se
          },"fields":[
            {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', related: 'cardable', type: 'text'},
            {name: _vm.$t('views.cards.fields.code'),                                         field: 'code', type: 'text'},
            {name: _vm.$t('views.cards.fields.archive'),                                      field: 'name', related: 'archive', type: 'text'},
            {name: _vm.$t('views.cards.fields.tags'),                                      field: 'name', related: 'tags', type: 'text'},
            {name: _vm.$t('plugins.rometransformed.views.observations.single'),               field: 'name', related: 'cardable', subrelated: 'observation', type: 'text'},
            {name: _vm.$t('plugins.rometransformed.views.types.single'),                      field: 'name', related: 'cardable', subrelated: 'types', relation: 'multiple', type: 'text'},
            {name: _vm.$t('plugins.rometransformed.views.methodologies.single'),              field: 'name', related: 'cardable', subrelated: 'methodologies', relation: 'multiple', type: 'text'},
            {name: _vm.$t('plugins.rometransformed.views.zones.single'),                      field: 'name', related: 'cardable', subrelated: 'zones', relation: 'multiple', type: 'text'},
            {name: _vm.$t('plugins.rometransformed.views.interpretations.single'), 
              related: 'cardable',
              field: 'interpretation_id', model: 'interpretations', type: 'lemma', label: 'name',
              pivots: [ 
                {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', type: 'text'},
              ],
              name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.interpretation'), 
              title: _vm.$t('plugins.rometransformed.views.stratigraphies.interpretation.title')},
              {name: _vm.$t('plugins.rometransformed.views.observations.single'), field: 'code', related: 'cardable', subrelated: 'observation', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.observations.fields.quota'), field: 'quota', related: 'cardable', subrelated: 'observation', type: 'number'},
          ],"localizations":[
            {slug: 'stratigraphies', single: _vm.$t('plugins.rometransformed.views.stratigraphies.single'), name: _vm.$t('plugins.rometransformed.views.stratigraphies.single')},
            {slug: 'observations', single: _vm.$t('plugins.rometransformed.views.observations.single'), name: _vm.$t('plugins.rometransformed.views.observations.single')},
          ],"legend":"legend/interpretations","legend-name":_vm.$t('plugins.rometransformed.views.interpretations.title')}})],1):(_vm.archive && !_vm.hasThreeDData())?_c('div',{staticClass:"h-full w-full text-white text-center py-20 flex flex-col items-center"},[_c('div',{staticClass:"mb-50"},[_vm._v(_vm._s(_vm.$t('components.threed_visualizer.archive_missing_data')))]),_c('button',{staticClass:"mt-5 px-3 py-2 text-sm border-slate-900 bg-slate-600 text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:bg-slate-900",on:{"click":_vm.clearCanvas}},[_c('f-icon',{attrs:{"icon":"circle-minus"}}),_vm._v(" "+_vm._s(_vm.$t('actions.clear_canvas')))],1)]):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }