<template>
  <div id="app">
    <TopBar />
    <NavBar classes="" layout="horizontal" @showNav="showNav"/>
    <router-view />
    <modals-container />
    <!-- <div :class="'grid bg-gray-100 min-h-screen w-full h-full '+ (show ? 'grid-cols-5' : 'grid-cols-15')">
      <NavBar classes="hidden md:block col-span-1" @showNav="showNav"/>
      <div :class="show ? 'col-span-5 md:col-span-4' : 'col-span-15 md:col-span-14'" @click="hideUserMenu">
        <div class="text-xs text-gray-700">
          <router-view />
          <modals-container />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import NavBar from '@/components/NavBar.vue'
import TopBar from '@/components/TopBar.vue'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Backoffice',
    // all titles will be injected into this template
    titleTemplate: '%s | '+ (process.env.VUE_APP_NAME || 'ReSole')
  },
  components: {
    NavBar, TopBar
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
      locale: 'app/locale',
      showUserMenu: 'app/showUserMenu'
    }),
  },
  data: () => ({
    show: true,
  }),
  methods: {
    hideUserMenu(){
      this.$store.dispatch(`app/showUserMenu`, {showUserMenu: false})
    },
    showNav(show){
      this.show = show
    }
  }
}
</script>