<template>
  <div class="text-xs">
    <draggable v-model="repeating" draggable=".item" @end="updateList">
      <div v-for="(repeated, index) in repeating" :key="'repeating-'+index" class="item grid grid-cols-10 gap-1 m-1">
        <div class="outline-none h-10 px-1 flex item-center col-span-8">
          <div v-if="!disable" class="px-1 flex flex-col justify-center cursor-move"><f-icon icon="sort" /></div>
          <input 
            :class="classes" 
            :value="repeated"
            type="text"
            :disabled="disable"
            @input="updateInput($event, index)" />
        </div>
        <button v-if="!disable" class="bg-red-700 hover:bg-blue-dark text-white h-10 py-2 px-1/5 text-xs rounded col-span-2" @click="removeRepeated(repeated)"><f-icon icon="trash-can" /></button>
      </div>
      <div class="grid grid-cols-10 gap-1 m-1">
        <div class="outline-none h-10 px-1 flex align-center col-span-8">
          <div v-if="!disable" class="px-2 flex flex-col justify-center"></div>
          <input 
            type="text"
            :disabled="disable"
            :class="classes + ' col-span-7'" 
            v-model="newRepeated" />
        </div>
        <button v-if="!disable" class="bg-green-700 hover:bg-blue-dark text-white h-10 py-2 px-1/5 text-xs rounded col-span-2" @click="addRepeated(newRepeated)"><f-icon icon="plus" /></button>
      </div>
    </draggable>
  </div>
</template>
<script type="text/javascript">
  import draggable from 'vuedraggable'
  export default {
    name: 'RepeatingText',
    components: {
      draggable
    },
    props: {
      field: {
        type: Object,
        required: true
      },
      value: {
        required: true,
      },
      classes: {
        default: null
      },
      disable: {
        default: false
      },
      addRepeatedText: {
        default: 'Add'
      },
      removeRepeatedText: {
        default: 'Remove'
      },
    },
    data() {
      return {
        repeating: [],
        newRepeated: null,
        disabled: false
      }
    },
    // watch: { 
    //   value: function(newVal, oldVal) {
    //     console.log('entities changed: ', newVal, ' | was: ', oldVal)
    //     this.handleText()
    //   }
    // },
    mounted() {
      this.handleText()
    },
    methods: {
      addRepeated() {
        if(!this.newRepeated) {
          this.$toasted.show(this.$i18n.t('modals.error.cant_be_null')) 
          return
        }
        this.repeating.push(this.newRepeated)
        this.$emit('input', this.repeating.join('|'))
        this.newRepeated = null
      },
      removeRepeated(oldVal) {
        this.repeating.splice(this.repeating.indexOf(oldVal), 1);
        this.$emit('input', this.repeating.join('|'))
      },
      handleText(){
        if(this.value) this.repeating = this.value.split('|')
      },
      updateInput(e, index) {
        this.repeating[index] = e.target.value
        this.$emit('input', this.repeating.join('|'))
      },
      updateList(){
        this.$emit('input', this.repeating.join('|'))
      }
    }
  }
</script>
<style>
</style>