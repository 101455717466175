<template>
  <div class="modal modal-importer">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-8">
      <h1 class="text-xl">{{ title }}</h1>
      <p class="my-5" v-html="text" />
      <FileUploader v-if="!shapefiles || shapefiles.length === 0"
        classes="w-full rounded-md"
        :is-simple="true" 
        @inputData="updateShapefileData" 
        :model-name="model"
        :model-file-version="null" 
        :file-types-allowed="fileTypesAllowed || null" 
        mode="shapefile"
      />
      <div v-else>
        <div class="flex flex-col items-center px-2 py-3 bg-white text-blue tracking-wide uppercase border cursor-pointer place-items-center w-full rounded-md">
          <div v-if="shapefiles && shapefiles.length > 0">
            <div class="flex flex-row flex-wrap justify-center overflow-y-auto h-32 my-2 content-center">
              <div v-for="(file, index) in shapefiles" :key="'file-'+index" class="p-1 rounded bg-green-700 text-white m-1 text-xs">{{ file.file }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions mt-5 flex justify-between">
        <button v-if="shapefiles && shapefiles.length > 0" class="bg-green-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="importFile()">{{ importText }}</button>
        <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import FileUploader from '@/components/FileUploader'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'ModalShapefileImporter',
  components: {
    FileUploader, LoadingSpinner
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    importText: {
      type: String,
      default: 'Import'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String,
      default: 'locations'
    },
    fileTypesAllowed: {
      type: Array,
      default: () => [
        {name: 'shp', type: 'application/octet-stream'},
        {name: 'dbf', type: 'application/x-dbf'},
        {name: 'shx', type: 'application/octet-stream'},
        //{name: 'zip', type: 'application/zip'},
      ]
    },
    update: {},
    archiveText: {
      type: String
    }
  },
  data: function(){
    return {
      showError: false,
      error: [],
      shapefiles: [],
      archive: null,
      isLoading: false,
    }
  },
  computed: {
    uploadsPath(){
      return process.env.VUE_APP_UPLOADS_PATH || null
    },
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async importFile(){
      this.isLoading = true
      try{
        await api.create(`locations/shapefile/import`, {shapefiles: this.shapefiles, model: this.model}).then((response) => {
          console.log(response)
          this.isLoading = false
          this.$toasted.show(this.$i18n.t('modals.importer.confirmed')) 
          this.$emit('close')
        })
      }catch(e){
        this.isLoading = false
        this.$toasted.show(this.$i18n.t('modals.error.import')) 
      }
    },
    updateShapefileData(uploaded) { // grab filname from uploader component
      // push uploaded file in shapefiles
      this.shapefiles = uploaded
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
