var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"places"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.places.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"card","has-location":true,"check-owner":true,"fields":[

          // Base Card fields
          {text: _vm.$t('views.cards.fields.card_separator'), type: 'separator'},
          {name: _vm.$t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_visible')},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_published')},
          {name: _vm.$t('plugins.ereditaculturali.views.places.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: _vm.select_user },
          {name: _vm.$t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},
          
          {text: _vm.$t('plugins.ereditaculturali.views.places.placenames_separator'), type: 'separator'},
          {
            field: 'cards', type: 'relations-manager', related: 'card', model: 'cards', label: 'name', span: '4', insert: true, relatedModel: 'relateds', relatedModelApi: 'cards', relatedExtendedModelApi: 'placenames', relatedExtendedModel: 'VBP\\EreditaCulturali\\Models\\Placename', ordering: true,
            fields: [
              {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.region'), field: 'region', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.type'), field: 'type_id', type: 'select', model: 'placename_types', label: 'name' },
              {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.literal_transcript'), field: 'literal_transcript', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.latin_transcript'), field: 'latin_transcript', type: 'text'},
              {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.notes'), field: 'notes', type: 'text'},
              {name: _vm.$t('views.cards.fields.references'), field: 'references', related: 'card', model: 'references', type: 'multi-select', label: 'citation', labels:['citation'], taggable: true},
              {name: _vm.$t('views.cards.fields.reference_sources'), field: 'reference_sources', related: 'card', model: 'reference_sources', type: 'multi-select', label: 'name', taggable: true},
            ],
            name: _vm.$t('plugins.ereditaculturali.views.placenames.relations_title'), 
            title: _vm.$t('plugins.ereditaculturali.views.placenames.title'),
            addNewText: _vm.$t('plugins.ereditaculturali.views.placenames.add_new'),
            labels: ['code', 'name'],
            labeled: true,
            template: '1-1'
          },

          {name: _vm.$t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
            fields: [ 
              {name: _vm.$t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.classification_id'), field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
              {name: _vm.$t('views.locations.fields.location'), field: 'location', type: 'location', span: '4'},
              {name: _vm.$t('views.locations.fields.polygonz'), field: 'polygonz', type: 'geometry', geometry: 'polygonz', mode: 'map', span: '4'},
            ],
            pivots: [ 
              {name: _vm.$t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
              {name: _vm.$t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
            ],
            pivotTitle: _vm.$t('plugins.ereditaculturali.views.places.locations.pivot')
          },
        ]}}):_vm._e(),(_vm.id)?_c('div',{staticClass:"mt-2"},[(_vm.canLoad)?_c('FileManager',{attrs:{"mode":_vm.crud_mode,"model-id":parseInt(_vm.id),"model":_vm.model,"entity-data":_vm.entity,"related":true,"uploaderText":_vm.$t('plugins.ereditaculturali.views.places.uploader_text'),"file-types-allowed":[
            {name: 'PNG', type: 'image/png'},
            {name: 'JPG', type: 'image/jpeg'},
            {name: 'TIFF', type: 'image/tiff'},
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'OBJ', type: 'text/plain'},
            {name: 'PDF', type: 'application/pdf'}
          ],"file-model-types":[
            {type: 'document', name: _vm.$t('views.cards.files.types.document'),  fields: ['reference', 'license', 'description']},
            {type: 'raster',   name: _vm.$t('views.cards.files.types.raster'),    fields: ['reference', 'license', 'description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
            {type: 'image', name: _vm.$t('views.cards.files.types.image'),  fields: ['reference', 'license', 'description']},
            {type: 'photo', name: _vm.$t('views.cards.files.types.photo'),  fields: ['reference', 'license', 'description']},
            {type: 'threed_model', name: _vm.$t('views.cards.files.types.threed_model'), fields: ['reference', 'license', 'description', 'file_id']},
            {type: 'threed_texture', name: _vm.$t('views.cards.files.types.threed_texture'), fields: ['reference', 'license', 'description', 'file_id']},
            {type: 'spheric_picture', name: _vm.$t('views.cards.files.types.spheric_picture'), fields: ['reference', 'license', 'description']},
          ],"file-model-fields":[
            {name: _vm.$t('views.cards.files.fields.reference'), field: 'reference', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.license'), field: 'license', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.description'), field: 'description', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: _vm.$t('views.cards.files.fields.file_select')},
            {name: _vm.$t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
          ]}}):_vm._e()],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }