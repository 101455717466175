<template>
  <div class="observations">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.observations.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.rometransformed.views.observations.title')" 
          model="observations"
          model-name-field="code"
          :check-owner="true"
          :check-roles="['stratigraphy_manager']"
          :columns="[
            {name: $t('plugins.rometransformed.views.observations.fields.code'), field: 'code', related: 'card'},
            {name: $t('plugins.rometransformed.views.stratigraphies.title'), field: 'stratigraphies_count'},
            {name: $t('plugins.rometransformed.views.observations.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
            {name: $t('views.cards.fields.user'), parent: 'user', field: 'name', related: 'card'},
            {field: 'actions'},
          ]"
          :search-filters="[
            {field: 'code',     type: 'text',   label: $t('plugins.rometransformed.views.observations.fields.code')},
            {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: $t('plugins.rometransformed.views.observations.fields.archive'), placeholder: $t('views.archives.select')}
          ]"

        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'Observations',
  components: {
    BaseLayout, CrudTable
  }
}
</script>