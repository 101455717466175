<template>
  <div class="modal modal-importer">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-8">
      <h1 class="text-xl">{{ title }}</h1>
      <p class="my-5" v-html="text" />
      <FileUploader v-if="!media || media.length === 0"
        classes="w-full rounded-md"
        :is-simple="true" 
        @inputData="updateMediaFileData" 
        :model-name="model"
        :model-file-version="null" 
        :file-types-allowed="fileTypesAllowed || null" 
      />
      <div v-else>
        <div class="flex flex-col items-center px-2 py-3 bg-white text-blue tracking-wide uppercase border cursor-pointer place-items-center w-full rounded-md">
          <div v-if="media && media.length > 0">
            <div class="flex flex-row flex-wrap justify-center overflow-y-auto h-32 my-2 content-center">
              <div v-for="(file, index) in media" :key="'file-'+index" class="p-1 rounded bg-green-700 text-white m-1 text-xs">
                <img v-if="isImage(file.file)" class="h-50" :src="file.thumb['300'].file" />
                <div v-else>{{ file.file }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions mt-5 flex justify-between">
        <button v-if="media && media.length > 0" class="bg-green-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="importFile()">{{ importText }}</button>
        <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import FileUploader from '@/components/FileUploader'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'ModalMediaImporter',
  components: {
    FileUploader, LoadingSpinner
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    importText: {
      type: String,
      default: 'Import'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String
    },
    fileTypesAllowed: {
      type: Array
    },
    update: {},
    archiveText: {
      type: String
    }
  },
  data: function(){
    return {
      showError: false,
      error: [],
      media: [],
      archive: null,
      isLoading: false,
    }
  },
  computed: {
    uploadsPath(){
      return process.env.VUE_APP_UPLOADS_PATH || null
    },
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async importFile(){
      this.isLoading = true
      try{
        api.create(`${this.model}/media/import`, {media: this.media}).then(imported => {
          if(imported){
            this.isLoading = false
            this.$toasted.show(this.$i18n.t('modals.importer.confirmed')) 
            this.$emit('close')
          }else{
            this.$toasted.show(this.$i18n.t('modals.error.import')) 
          }
        })
      }catch(e){
        console.log(e)
        this.isLoading = false
        this.$toasted.show(this.$i18n.t('modals.error.import')) 
        this.$toasted.error(e)
      }
    },
    updateMediaFileData(uploaded) { // grab filname from uploader component
      // push uploaded file in media
      this.media = uploaded
      this.$nextTick(()=>{
        this.importFile()
      })
    },
    isImage(file){
      let is_image = false
      if(file.includes('jpg') || file.includes('png')) is_image = true
      return is_image
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
