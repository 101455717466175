<template>
  <div class="places">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.places.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.ereditaculturali.views.places.title')" 
          model="places"
          model-name-field="code"
          :check-owner="false"
          :check-roles="['archive_manager', 'card_manager']"
          :columns="[
            {name: $t('plugins.ereditaculturali.views.places.fields.code'), field: 'code', related: 'card'},
            {name: $t('plugins.ereditaculturali.views.places.fields.name'), field: 'name', related: 'card'},
            {name: $t('plugins.ereditaculturali.views.places.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
            {name: $t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', view: 'check', icon: 'eye'},
            {name: $t('views.cards.fields.is_published'), field: 'is_published', related: 'card', view: 'check', icon: 'globe'},
            {field: 'actions'},
          ]"
          :cards-importer="true"
          :cards-importer-archive-text="$t('plugins.ereditaculturali.views.places.importer_archive_text')"
          cards-import-model="place-import-model.xlsx"
          :cards-file-types-allowed="[
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'CSV', type: 'text/csv'}
          ]"
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'Places',
  components: {
    BaseLayout, CrudTable
  }
}
</script>