<template>
  <div class="modal modal-importer">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-8">
      <h1 class="text-xl">{{ title }}</h1>
      <p class="my-5" v-html="text" />
      <div>
        <input type="radio" id="upload" value="upload" v-model="importMode" class="p-2 mx-1 my-2" />
        <label for="one" class="mx-1 my-2">{{ $t('modals.importer.radio_upload_file') }}</label>
        <input type="radio" id="media" value="media" v-model="importMode" class="p-2 mx-1 my-2" />
        <label for="two" class="mx-1 my-2">{{ $t('modals.importer.radio_choose_file') }}</label>
      </div>
      <div v-if="importMode === 'upload'">
        <FileUploader v-if="!file"
          classes="w-full rounded-md"
          :is-simple="true" 
          @inputData="updateFileData" 
          :model-name="model"
          :model-file-version="null" 
          :file-types-allowed="fileTypesAllowed || null" 
        />
      </div>
      <div v-else>
        <div v-if="medias.length > 0" class="p-2 bg-gray-200 rounded-lg mt-1">
          <div class="grid grid-cols-3 gap-1 overflow-y-scroll h-200">
            <div v-for="(media, index) in medias" :key="`file-${index}`" :class="'border-4 cursor-pointer py-3 px-3 text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:text-green-700 flex flex-row item-center ' +  (file === media.file ? 'bg-gray-500' : 'bg-green-700')">
              <div @click="updateFileData(media, true)">
                <p class="w-20 break-words bg-green-900 px-2 py-1 rounded mt-1"><f-icon class="text-lg" icon="file" /> {{ media.id }}</p><p class="w-20 break-words mt-2 text-xs">{{ media.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="file">
        <div class="flex flex-col items-center px-2 py-3 bg-white text-blue tracking-wide uppercase border cursor-pointer place-items-center w-full rounded-md">
          {{ file }}
        </div>
        <VSelect v-if="hasArchive"
          v-model="archive"
          :model="'archives'"
          :search-field="'name'"
          :label="'name'"
          value-field="id"
          :placeholder="archiveText"
          class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
      </div>
      <div class="actions mt-5 flex justify-between">
        <button v-if="file" class="bg-green-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="importFile()">{{ importText }}</button>
        <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import FileUploader from '@/components/FileUploader'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'ModalImporter',
  components: {
    FileUploader, LoadingSpinner
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    importText: {
      type: String,
      default: 'Import'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String
    },
    fileTypesAllowed: {
      type: Array
    },
    update: {},
    archiveText: {
      type: String
    },
    hasArchive: {
      type: Boolean,
      default: true
    }
  },
  data: function(){
    return {
      showError: false,
      error: [],
      file: null,
      archive: null,
      isLoading: false,
      importMode: 'upload',
      medias: []
    }
  },
  watch: {
    importMode(value) {
      if(value === 'media') { this.loadMedia() } 
      else { this.medias = [] }
    }
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async importFile(){
      if(!this.archive && this.hasArchive) {
        this.$toasted.show(this.$i18n.t('modals.error.missing_archive')) 
        return
      }
      this.isLoading = true
      try{
        let imported = await api.create(`${this.model}/import`, {file: this.file, archive: this.archive})
        if(imported.success) {
          this.$emit('close')
        }else{
          this.$toasted.show(this.$i18n.t('modals.error.import')) 
          if(imported.data && imported.data.response.data) this.$toasted.show(imported.data.response.data.message)
        }
        this.isLoading = false
      }catch(e){
        this.isLoading = false
        this.$toasted.show(this.$i18n.t('modals.error.import')) 
        //this.$toasted.show(e.stack || e)
      }
    },
    updateFileData(media, single = false) { // grab filname from uploader component
      // use file to import data
      const vm = this
      vm.file = single ? media.file : media[0].file
    },
    async loadMedia(){
      await api.fetch('files', null, {'params': {
        'per_page': '300',
        'order': 'DESC',
        'order_by': 'updated_at',
        // 'per_page': '99999', // TODO: gestire paginazione file manager
        'format': 'xlsx' // TODO: estendere ad altri formati
      }}).then((response) => {
        this.medias = response.data
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
