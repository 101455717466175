<template>
  <div :class="classes">
    <LoadingSpinner :toggle="isLoading" />
      <div v-if="locations && locations.length > 0" class="mt-2">
        <div class=" bg-slate-200 p-2 rounded-t-md flex flex-row justify-between">
          <div class="has-tooltip text-xs text-gray-400">
            <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mb-10">{{ $t('components.locations_manager.tips') }}</span>
            <f-icon icon="circle-question" />
          </div>
          <button class="bg-blue-600 px-3 py-2 text-xs text-white rounded ml-1" @click="addLocation">{{ $t('components.locations_manager.add_location') }}</button>
        </div>
        <div class="grid grid-cols-5 gap-2 mt-2">
          <div class="flex flex-col col-span-2">
            <div v-for="(location, index) in entityLocations" :key="'entity-location-'+index" class="rounded bg-slate-200 px-3 py-2 mb-1 flex flex-row items-center">
              <div class=" grow">
                <span class="text-base">{{ location.name }}</span>
                <span class="ml-1 px-2 py-1 rounded-md bg-green-100 text-xs text-green-900"><f-icon icon="vector-square" /> {{ location.type }}</span></div>
              <button @click="editLocation(location)" class="bg-blue-600 px-3 py-2 text-xs text-white rounded ml-1"><f-icon icon="pen-to-square" /></button>
              <button @click="removeLocation(location)" class="bg-red-600 px-3 py-2 text-xs text-white rounded ml-1"><f-icon icon="circle-minus" /></button>
            </div>
          </div>
          <div style="height: 500px; width: 100%" class="mb-2 col-span-3">
            <l-map
              ref="locationsMap"
              no-blocking-animations
              :zoom="mapParams.zoom"
              :center="defaultCoords()"
              :options="mapOptions"
              style="height: 100%"
              @ready="initMap()"
              @click="showPopup"
              >
              <l-tile-layer :url="mapParams.url" :attribution="mapParams.attribution" :options="tileOptions" />
              <l-control-layers :options="controlLayersOptions"/>
              <l-wms-tile-layer
                v-for="(layer, index) in wmsLayerGroup"
                  :key="'wms-layer-'+index"
                  :base-url="layer.url"
                  :layers="layer.layer"
                  :visible="layer.visible"
                  :format="layer.format"
                  :transparent="layer.transparent"
                  :name="layer.name"
                  :attribution="layer.attribution"
                  layer-type="base"
              />
              <l-geosearch :options="geosearchOptions" />
              <div v-if="popupShow && popup" class="absolute top-[60px] w-full shadow-2xl flex flex-col items-center">
                <div v-for="(element, index) in popup" :key="'popup-'+index" class="bg-white rounded-lg shadow-2xl z-[999] p-3 flex flex-col items-center border-2 border-slate-200">
                  <div class="mb-2 text-base">{{ element.name }}</div>
                  <div class="grid grid-cols-3 gap-y-1">
                    <div class="col-span-1 px-2 py-1 rounded-l bg-slate-200 text-right">{{ $t('components.locations_manager.location_type') }}</div>
                    <div class="col-span-2 text-green-800 bg-slate-100 px-2 py-1 rounded-r"><f-icon icon="vector-square" /> {{ element.type }}</div>
                    <div class="col-span-1 px-2 py-1 rounded-l bg-slate-200 text-right">{{ $t('components.locations_manager.location_cards') }}</div>
                    <div v-if="element[model] && element[model].length > 0" class="px-2 py-1 bg-slate-100 rounded-r col-span-2">
                      <div v-for="(entity, index) in element[model]" :key="'location-entity-'+index">
                        {{ entity.code }}
                      </div>
                    </div>
                    <div v-else class="px-2 py-1 bg-slate-100 rounded-r col-span-2">{{ $t('components.locations_manager.no_entities') }}</div>
                  </div>
                  <button v-if="!element.owned" class="text-green-800 mt-3 bg-green-200 rounded px-2 py-1 hover:bg-slate-200" @click="associateLocation(element)"><f-icon icon="link" /> {{ $t('components.locations_manager.associate') }}</button>
                </div>
              </div>
            </l-map>
          </div> 
        </div>
      </div>
      <div v-else>
        <div>{{ $t('components.locations_manager.no_geometry') }}</div>
      </div>
  </div>
</template>
<script>

import { latLng, Util } from "leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet-geosearch/assets/css/leaflet.css";

import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import ModalDelete from '@/components/ModalDelete'
import ModalEntity from "./ModalEntity.vue";

export default {
  name: 'LocationManager',
  components: {
    LoadingSpinner
  },
  props: {
    disable: {
      default: false
    },
    classes: {
      default: null
    },
    field: {
      default: null,
      required: true
    },
    digitizeType: {
      default: 'polygon'
    },
    id: {
      type: [String, Number],
      default: null
    },
    fields: {
      type: Array,
      default: () => [],
      required: true
    },
    model: {
      type: String,
      default: 'cards'
    },
    pivots: {
      type: Array,
      default: () => [],
      required: false
    },
    pivotTitle: {
      type: String,
      default: 'Pivot fields'
    },
    wmsLayers: {
      type: Array,
    },
  },
  data() {
    return {
      disabled: false,
      locations: [],
      isLoading: false,
      map: null,
      layers: null,
      clickedLayers: [],
      drawing: {},
      centroid: null,
      mapParams: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 15,
        currentZoom: 11.5
      },
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false,
      },
      tileOptions: {
        maxZoom: 18,
        maxNativeZoom: 18,
        minZoom: 0,
        continuousWorld: true,
        worldCopyJump: false
      },
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        searchLabel: 'Search...',
        autoClose: true
      },
      popupShow: false,
      popup: [],
      controlLayersOptions: {
        position: 'bottomright'
      },
      wmsLayerGroup: []
    }
  },
  computed: {
    entityLocations() {
      return this.locations.filter(location => location.owned)
    }
  },
  async created(){
    const vm = this
    vm.layers = new window.L.FeatureGroup()
    vm.mapParams.url = process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    vm.mapParams.attribution = process.env.VUE_APP_MAP_ATTRIBUTION || '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    vm.tileOptions.minZoom = process.env.VUE_APP_MAP_MIN_ZOOM || 1
    vm.tileOptions.maxZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    vm.tileOptions.maxNativeZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    let baseMap = {
      name: this.$t('components.locations_manager.base_map_name'),
      visible: true,
      format: 'image/png',
      url: vm.mapParams.url,
      // transparent: true,
      // attribution: ''
    }
    vm.wmsLayerGroup = [baseMap]
    if(vm.wmsLayers && vm.wmsLayers.length > 0) vm.wmsLayerGroup = [...vm.wmsLayers, ...[baseMap]]
  },
  mounted(){
    // this.initMap()
    this.loadLocations()
  },
  methods: {
    async loadLocations(){
      const vm = this
      vm.isLoading = true
      vm.layers.eachLayer(function (layer) {
        vm.layers.removeLayer(layer);
      })
      await api.fetch('locations', null, {'params': {'per_page': '999999'}}).then((response) => {
        vm.locations = response.data.filter(function(location){
          return !!location.pointz || !!location.location || !!location.polygon || !!location.polygonz || !!location.multipointz
        })
        if(vm.locations && vm.locations.length > 0){
          let coords = []
          vm.locations.forEach(location => {
            location.owned = false
            if(location[vm.model] && location[vm.model].length > 0){
              location[vm.model].forEach(entity => {
                if(!location.owned) location.owned = parseInt(vm.id) === entity.id
              })
            }
            if(!!location.pointz || !!location.location){
              location.geometry = location.pointz || location.location
              location.type = location.geometry.type
              location.geometry = new window.L.Marker([location.geometry.coordinates[1],location.geometry.coordinates[0]], {
                icon: vm.getIcon(location)
              }).on('click', function(event){
                vm.popup = []
                vm.popupShow = false
                vm.createPopup(event.latlng, [location])
              })
            }
            if(!!location.polygon || !!location.polygonz){
              coords = []
              location.geometry = location.polygon || location.polygonz
              location.type = location.geometry.type
              location.geometry.coordinates[0].forEach(element => {
                coords.push([element[1],element[0]])
              })
              location.geometry = new window.L.Polygon(coords)
              location.geometry.setStyle({
                fillColor: location.owned ? '#14532d' : '#3388ff',
                color: location.owned ? '#14532d' : '#3388ff',
              })
            }
            if(location.multipointz){
              location.geometry = location.multipointz 
              location.type = location.geometry.type
              // location.geometry =  new window.L.Marker([location.geometry.coordinates[1],location.geometry.coordinates[0]], {
              //   icon: vm.getIcon(location)
              // }).on('click', function(event){
              //   vm.popup = []
              //   vm.popupShow = false
              //   vm.createPopup(event.latlng, [location])
              // })
            }
            location.geometry.id = location.id
            location.geometry.name = location.name
            location.geometry.owned = location.owned
            location.geometry.type = location.type
            if(!location.multipointz) vm.layers.addLayer(location.geometry)
          })
        }
        vm.isLoading = false
      })
    },
    async removeLocation(location){
      // TODO: fix per lo splice del related eliminato, se è primo di tutti gli altri prendono il valore della select di quello eliminato
      const vm = this
      vm.$modal.show(ModalDelete,
        {
          title: vm.$i18n.t('components.locations_manager.remove_location'),
          text: vm.$i18n.t('components.locations_manager.remove_content'),
          deleteText: vm.$i18n.t('actions.remove'),
          cancelText: vm.$i18n.t('actions.cancel'),
          entity: location,
          model: `${vm.model}/${vm.id}/locations`,
          update: () => {
            vm.locations.splice(vm.locations.indexOf(location), 1)
            vm.loadLocations()
          }
        },{
          height: 'auto'
        }
      )
    },
    async associateLocation(location){
      const vm = this
      vm.isLoading = true
      await api.create(`${vm.model}/${vm.id}/associate/location/${location.id}`, null).then(() => {
        vm.loadLocations()
        vm.isLoading = false
      })
    },
    editLocation(location){
      const vm = this
      vm.$modal.show(ModalEntity,
        {
          title: vm.$i18n.t('modals.entity.title', {name: vm.$i18n.t('views.locations.single')}),
          entityUpdateText: vm.$i18n.t('actions.edit'),
          cancelText: vm.$i18n.t('actions.cancel'),
          id: location.id,
          entityFields: vm.fields,
          entityPivots: vm.pivots,
          pivotTitle: vm.pivotTitle,
          relatedId: vm.id,
          relatedModel: vm.model,
          canDelete: false,
          model: `locations`,
          controlLayersOptions: vm.controlLayersOptions,
          wmsLayers: vm.wmsLayerGroup,
          update: () => {
            vm.loadLocations()
          }
        },{
          height: 'auto',
          width: '80%'
        }
      )
    }, 
    addLocation(){
      const vm = this
      vm.$modal.show(ModalEntity,
        {
          title: vm.$i18n.t('modals.entity.title', {name: vm.$i18n.t('views.locations.single')}),
          entityAddText: vm.$i18n.t('actions.save'),
          cancelText: vm.$i18n.t('actions.cancel'),
          entityFields: vm.fields,
          entityPivots: vm.pivots,
          pivotTitle: vm.pivotTitle,
          model: `locations`,
          relatedId: vm.id,
          relatedModel: vm.model,
          controlLayersOptions: vm.controlLayersOptions,
          wmsLayers: vm.wmsLayerGroup,
          update: () => {
            vm.loadLocations()
          }
        },{
          height: 'auto',
          width: '80%'
        }
      )
    },
    initMap(){
      this.$nextTick(() => {
        this.$nextTick(() => {
          const vm = this
          vm.map = vm.$refs.locationsMap.mapObject
          vm.layers.addTo(vm.map)
          vm.map.fitBounds(vm.getLayersBounds())
        })
      })
    },
    defaultCoords(){
      return (process.env.VUE_APP_BASE_LAT && process.env.VUE_APP_BASE_LNG) 
        ? [parseFloat(process.env.VUE_APP_BASE_LNG), parseFloat(process.env.VUE_APP_BASE_LAT)]
        : [parseFloat(12.1234), parseFloat(44.1234)]
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    coords(coordinates){
      return latLng(coordinates[1], coordinates[0])
    },
    showPopup(event) {
      const vm = this
      vm.popup = []
      vm.popupShow = false
      vm.clickedLayers = []
      vm.layers.eachLayer(function(layer) {
        if(vm.contains(layer, event.latlng)) { // point.getLatLng()
          vm.clickedLayers.push(layer)
        }
      })
      if(vm.clickedLayers.length > 0) {
        vm.createPopup(event.latlng, vm.clickedLayers)
      }
    },
    createPopup(latlng, data){
      const vm = this
      data.forEach(element => {
        vm.popup.push(element)
      })
      vm.popupShow = true
    },
    getIcon(element) {
      return window.L.divIcon({
        className: "my-custom-pin",
        html: `<div class="shadow-2xl rounded-xl w-4 h-4 ${element.owned ? 'bg-green-700' : 'bg-blue-700'} border-2 border-white"></div>`
      })
    },
    getLayersBounds(){
      // Fix wrong getBounds calculations
      const vm = this
      const layersBounds = vm.layers.getBounds()
      const ne_lat = layersBounds.getNorthEast().lat.toString()
      const ne_lng = layersBounds.getNorthEast().lng.toString()
      const sw_lat = layersBounds.getSouthWest().lat.toString()
      const sw_lng = layersBounds.getSouthWest().lng.toString()
      // console.log(layersBounds.getNorthEast(), 
      //   ne_lat, ne_lat.toString().indexOf(".") !== -1, 
      //   ne_lng, ne_lng.toString().indexOf(".") !== -1,
      //   sw_lat, sw_lat.toString().indexOf(".") !== -1, 
      //   sw_lng, sw_lng.toString().indexOf(".") !== -1
      // ) 
      const bounds = [
        [
          ne_lat.indexOf(".") == -1 ? ne_lat.slice(0, 2) + "." + ne_lat.slice(2) : ne_lat,
          ne_lng.indexOf(".") == -1 ? ne_lng.slice(0, 2) + "." + ne_lng.slice(2) : ne_lng
        ],
        [
          sw_lat.indexOf(".") == -1 ? sw_lat.slice(0, 2) + "." + sw_lat.slice(2) : sw_lat,
          sw_lng.indexOf(".") == -1 ? sw_lng.slice(0, 2) + "." + sw_lng.slice(2) : sw_lng
        ],
      ]
      return bounds
    },
    contains(geom, p) {
      if(geom._latlngs !== undefined){
        var wn
        wn = this.getWindingNumber(geom, p)
        return (wn !== 0)
      }
      return false
    },
    isLeft(geom, p1, p2) {
      return ((p1.lng - geom.lng) * (p2.lat - geom.lat) -
              (p2.lng - geom.lng) * (p1.lat - geom.lat))
    },
    flatten(a) {
      const vm = this
      var flat
      flat = ((Array.isArray ? Array.isArray(a) : Util.isArray(a)) ? a.reduce(function (accumulator, v) { // i, array
          return accumulator.concat(Array.isArray(v) ? vm.flatten(v) : v)
        }, [])
             : a)
      return flat
    },
    getWindingNumber(geom, p) { // Note that L.Polygon extends L.Polyline
      var i,
          isLeftTest,
          n,
          vertices,
          wn // the winding number counter
      vertices = geom.getLatLngs()
      vertices = this.flatten(vertices) // Flatten array of LatLngs since multi-polylines return nested array.
      // Filter out duplicate vertices.
      vertices = vertices.filter(function (v, i, array) { // remove adjacent duplicates
        if (i > 0 && v.lat === array[i-1].lat && v.lng === array[i-1].lng) {
            return false
        } else {
            return true
        }
      })
      n = vertices.length
      // Note that per the algorithm, the vertices (V) must be "a vertex points of a polygon V[n+1] with V[n]=V[0]"
      if (n > 0 && !(vertices[n-1].lat === vertices[0].lat && vertices[n-1].lng === vertices[0].lng)) {
        vertices.push(vertices[0])
      }
      n = vertices.length - 1
      wn = 0
      for (i=0; i < n; i++) {
        isLeftTest = this.isLeft(vertices[i], vertices[i+1], p)
        if(isLeftTest === 0) { // If the point is on a line, we are done.
          wn = 1
          break
        }else{
          if(isLeftTest !== 0) { // If not a vertex or on line (the C++ version does not check for this)
            if(vertices[i].lat <= p.lat) {
              if(vertices[i+1].lat > p.lat) { // An upward crossing
                if(isLeftTest > 0) { // P left of edge
                   wn++ // have a valid up intersect
                }
              }
            }else{
              if(vertices[i+1].lat <= p.lat) {// A downward crossing
                if(isLeftTest < 0) { // P right of edge
                  wn-- // have a valid down intersect
                }
              }
            }
          } else {
            wn++
          }
        }
      }
      return wn
    }
  }
}
</script>
<style>
.leaflet-top, .leaflet-bottom {
  z-index: 999 !important;
}
.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-touch-icon.leaflet-zoom-animated.leaflet-interactive {
  width: 10px !important;
  height: 10px !important;
  margin-left: -5px !important;
  margin-top: -5px !important;
  border-radius: 10px;
}
.leaflet-control-geosearch form {
  left: auto !important;
  right: 35px;
  border-radius: 4px 4px 4px 4px !important;
  padding: 2px !important;
  border: 2px solid #e5e7eb;
}
.leaflet-control-geosearch form a.reset {
  border-bottom: 0px !important;
  background-color: transparent !important;
  height: 24px !important;
  line-height: 24px !important;
}
.leaflet-control-container .leaflet-top.leaflet-left .geosearch form .glass {
  height: 24px !important;
  padding: 5px 0px !important;
}
.leaflet-control-container .leaflet-top.leaflet-left .geosearch form .results {
  padding: 0px !important;
}
.leaflet-control-geosearch .results.active {
  border-top: 0px !important;
}
</style>