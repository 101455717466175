<template>
  <div class="archeological_archives">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.archeological_archives.single') }}
      </template>
      <template v-slot:main>
        <CrudEntity v-if="canLoad"
          :model="model" :id="id" :mode="crud_mode" :entity="entity" :is-extended-card="true" extends="archive"
          :fields="[
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.code'), field: 'code', type: 'text'},
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.name'), field: 'name', type: 'text'},
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.archive'), field: 'archive_id', type: 'select', model: 'archives', label: 'name' },
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.description'), field: 'description', type: 'text-editor', span: '2'},
            {text: $t('plugins.rometransformed.views.archeological_archives.separator'), type: 'separator'},       
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.nw'), field: 'nw', type: 'text'},          
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.ne'), field: 'ne', type: 'text'},          
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.sw'), field: 'sw', type: 'text'},          
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.se'), field: 'se', type: 'text'},          
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.center_x'), field: 'center_x', type: 'text'},    
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.center_y'), field: 'center_y', type: 'text'},    
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.crs'), field: 'crs', type: 'text'},

            // Interpretations
            {text: $t('plugins.rometransformed.views.archeological_archives.interpretations.separator'), type: 'separator'},
            {
              field: 'interpretations', type: 'relations-manager', model: 'archeological_archives', relatedModel: 'interpretations', relatedSearchField: 'name', relatedLabel: 'name', labels: ['name'], span: '4',
              pivots: [],
              name: $t('plugins.rometransformed.views.archeological_archives.interpretations.name'), 
              title: $t('plugins.rometransformed.views.archeological_archives.interpretations.title'),
              addNewText: $t('plugins.rometransformed.views.archeological_archives.interpretations.add_new'),
              tip: $t('plugins.rometransformed.views.archeological_archives.interpretations.tip'),
              labeled: true
            },
          ]"
        />
        <div v-if="id" class="mt-2">
          <FileManager v-if="canLoad"
            :mode="crud_mode"
            :model-id="parseInt(id)"
            :model="model"
            :entity-data="entity"
            :related="false"
            :uploaderText="$t('plugins.rometransformed.views.archeological_archives.uploader_text')"
            :file-types-allowed="[
              {name: 'PNG', type: 'image/png'},
              {name: 'JPG', type: 'image/jpeg'},
              {name: 'TIFF', type: 'image/tiff'},
              {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
              {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
              {name: 'OBJ', type: 'text/plain'},
            ]"
            :file-model-types="[
              {type: 'document', name: $t('views.cards.files.types.document'),  fields: ['description']},
              {type: 'raster',   name: $t('views.cards.files.types.raster'),    fields: ['description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
              {type: 'image', name: $t('views.cards.files.types.image'),  fields: ['description']},
              {type: 'photo', name: $t('views.cards.files.types.photo'),  fields: ['description']},
              {type: 'threed_model', name: $t('views.cards.files.types.threed_model'), fields: ['description', 'file_id']},
              {type: 'threed_texture', name: $t('views.cards.files.types.threed_texture'), fields: ['description', 'file_id']},
              {type: 'spheric_picture', name: $t('views.cards.files.types.spheric_picture'), fields: ['description']},
            ]"
            :file-model-fields="[
              {name: $t('views.cards.files.fields.description'), field: 'description', type: 'text'},
              {name: $t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
              {name: $t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
              {name: $t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
              {name: $t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
              {name: $t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
              {name: $t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: $t('views.cards.files.fields.file_select')},
              {name: $t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
            ]"
            />
        </div>
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudLoad from '@/components/CrudLoad'
import CrudEntity from '@/components/CrudEntity'
import FileManager from '@/components/FileManager'

export default {
  name: 'ArcheologicalArchive',
  mixins: [
    CrudLoad
  ],
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      model: 'archeological_archives',
    }
  },
  components: {
    BaseLayout, CrudEntity, FileManager
  },
  async beforeMount(){
    this.load(this.mode, this.$route.params.id)
  }
}
</script>