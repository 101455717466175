<template>
  <div class="types">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.types.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.rometransformed.views.types.title')" 
          model="types"
          model-name-field="code"
          :columns="[
            {name: $t('plugins.rometransformed.views.types.fields.code'), field: 'code'},
            {name: $t('plugins.rometransformed.views.types.fields.name'), field: 'name'},
            {field: 'actions'},
          ]"
          :search-filters="[
            {field: 'code', type: 'text', label: $t('plugins.rometransformed.views.types.fields.code')},
            {field: 'name', type: 'text', label: $t('plugins.rometransformed.views.types.fields.name')},
          ]"
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'Types',
  components: {
    BaseLayout, CrudTable
  }
}
</script>