<template>
  <button @click="openExportModal" class="h-8 px-3 py-1 text-xs text-indigo-100 transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-700"><f-icon icon="file-export" /> {{ title || $t('actions.export_shapefile') }}</button>
</template>
<script>
import ModalShapefileExporter from '@/components/ModalShapefileExporter'
export default {
  name: 'ButtonShapefileExporter',
  props: {
    title: {
      type: String,
    },
    model: {
      type: String,
    },
    categoryModel: {
      type: String,
      default: null
    },
    archive: {
      type: Number,
      default: null
    }
  },
  methods: {
    openExportModal(){
      const vm = this
      vm.$modal.show(ModalShapefileExporter,
        {
          title: vm.$i18n.t('modals.exporter.title', {name: vm.title}),
          text: vm.$i18n.t('modals.exporter.shapefile_content'),
          exportText: vm.$i18n.t('actions.export'),
          cancelText: vm.$i18n.t('actions.cancel'),
          model: vm.model,
          archive: vm.archive,
          categoryModel: vm.categoryModel,
          update: (exported) => {
            console.log(exported)
          }
        },{
          height: 'auto'
        }
      )
    },
  }
}
</script>