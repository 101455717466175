<template>
  <div v-if="entity" :class="classes + ' p-4'">
    <div v-if="entity.creator || (entity.card && entity.card.creator)" class="mb-2">
      <span>{{ $t('views.cards.created_by') }}</span> <span class=" font-bold">{{ entity.card ? entity.card.creator.name : entity.creator.name }} {{ entity.card ? entity.card.creator.surname : entity.creator.surname }}</span>
    </div>
    <div class="mb-2">
      <span>{{ $t('views.cards.created_at') }}</span> <span class=" font-bold">{{ datetime(entity.card ? entity.card.created_at : entity.created_at) }}</span>
    </div>
    <div>
      <span>{{ $t('views.cards.updated_at') }}</span> <span class=" font-bold">{{ datetime(entity.card ? entity.card.updated_at : entity.updated_at) }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'EntityInfo',
    props: {
      entity: {
        required: true,
      },
      classes: {
        default: null
      },
    },
    methods: {
      datetime(datetime){
        if(!datetime) return '-'
        let format = new Date(datetime)
        return format.getDate()+'/' + (format.getMonth()+1) + '/'+format.getFullYear()+' - '+ ((format.getHours() < 10 ? '0' : '') + format.getHours()) +':'+ ((format.getMinutes() < 10 ? '0' : '') + format.getMinutes())
      },
    }
  }
</script>