<template>
  <div class="places">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.places.single') }}
      </template>
      <template v-slot:main>
        <CrudEntity v-if="canLoad"
          :model="model" :id="id" :mode="crud_mode" :entity="entity" :is-extended-card="true" extends="card" :has-location="true" :check-owner="true"
          :fields="[

            // Base Card fields
            {text: $t('views.cards.fields.card_separator'), type: 'separator'},
            {name: $t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
            {name: $t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
            {name: $t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', tip: $t('plugins.ereditaculturali.tips.is_visible')},
            {name: $t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: $t('plugins.ereditaculturali.tips.is_published')},
            {name: $t('plugins.ereditaculturali.views.places.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
            {name: $t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: select_user },
            {name: $t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},
            
            {text: $t('plugins.ereditaculturali.views.places.placenames_separator'), type: 'separator'},
            {
              field: 'cards', type: 'relations-manager', related: 'card', model: 'cards', label: 'name', span: '4', insert: true, relatedModel: 'relateds', relatedModelApi: 'cards', relatedExtendedModelApi: 'placenames', relatedExtendedModel: 'VBP\\EreditaCulturali\\Models\\Placename', ordering: true,
              fields: [
                {name: $t('plugins.ereditaculturali.views.placenames.fields.region'), field: 'region', type: 'text'},
                {name: $t('plugins.ereditaculturali.views.placenames.fields.type'), field: 'type_id', type: 'select', model: 'placename_types', label: 'name' },
                {name: $t('plugins.ereditaculturali.views.placenames.fields.literal_transcript'), field: 'literal_transcript', type: 'text'},
                {name: $t('plugins.ereditaculturali.views.placenames.fields.latin_transcript'), field: 'latin_transcript', type: 'text'},
                {name: $t('plugins.ereditaculturali.views.placenames.fields.notes'), field: 'notes', type: 'text'},
                {name: $t('views.cards.fields.references'), field: 'references', related: 'card', model: 'references', type: 'multi-select', label: 'citation', labels:['citation'], taggable: true},
                {name: $t('views.cards.fields.reference_sources'), field: 'reference_sources', related: 'card', model: 'reference_sources', type: 'multi-select', label: 'name', taggable: true},
              ],
              name: $t('plugins.ereditaculturali.views.placenames.relations_title'), 
              title: $t('plugins.ereditaculturali.views.placenames.title'),
              addNewText: $t('plugins.ereditaculturali.views.placenames.add_new'),
              labels: ['code', 'name'],
              labeled: true,
              template: '1-1'
            },

            {name: $t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
              fields: [ 
                {name: $t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
                {name: $t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
                {name: $t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
                {name: $t('views.locations.fields.classification_id'), field: 'classification_id', span: '2', type: 'select', model: 'classifications', label: 'name' },
                {name: $t('views.locations.fields.location'), field: 'location', type: 'location', span: '4'},
                {name: $t('views.locations.fields.polygonz'), field: 'polygonz', type: 'geometry', geometry: 'polygonz', mode: 'map', span: '4'},
              ],
              pivots: [ 
                {name: $t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
                {name: $t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
              ],
              pivotTitle: $t('plugins.ereditaculturali.views.places.locations.pivot')
            },
          ]"
        />
        <div v-if="id" class="mt-2">
          <FileManager v-if="canLoad"
            :mode="crud_mode"
            :model-id="parseInt(id)"
            :model="model"
            :entity-data="entity"
            :related="true"
            :uploaderText="$t('plugins.ereditaculturali.views.places.uploader_text')"
            :file-types-allowed="[
              {name: 'PNG', type: 'image/png'},
              {name: 'JPG', type: 'image/jpeg'},
              {name: 'TIFF', type: 'image/tiff'},
              {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
              {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
              {name: 'OBJ', type: 'text/plain'},
              {name: 'PDF', type: 'application/pdf'}
            ]"
            :file-model-types="[
              {type: 'document', name: $t('views.cards.files.types.document'),  fields: ['reference', 'license', 'description']},
              {type: 'raster',   name: $t('views.cards.files.types.raster'),    fields: ['reference', 'license', 'description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
              {type: 'image', name: $t('views.cards.files.types.image'),  fields: ['reference', 'license', 'description']},
              {type: 'photo', name: $t('views.cards.files.types.photo'),  fields: ['reference', 'license', 'description']},
              {type: 'threed_model', name: $t('views.cards.files.types.threed_model'), fields: ['reference', 'license', 'description', 'file_id']},
              {type: 'threed_texture', name: $t('views.cards.files.types.threed_texture'), fields: ['reference', 'license', 'description', 'file_id']},
              {type: 'spheric_picture', name: $t('views.cards.files.types.spheric_picture'), fields: ['reference', 'license', 'description']},
            ]"
            :file-model-fields="[
              {name: $t('views.cards.files.fields.reference'), field: 'reference', type: 'text'},
              {name: $t('views.cards.files.fields.license'), field: 'license', type: 'text'},
              {name: $t('views.cards.files.fields.description'), field: 'description', type: 'text'},
              {name: $t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
              {name: $t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
              {name: $t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
              {name: $t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
              {name: $t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
              {name: $t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: $t('views.cards.files.fields.file_select')},
              {name: $t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
            ]"
            />
        </div>
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudLoad from '@/components/CrudLoad'
import CrudEntity from '@/components/CrudEntity'
import FileManager from '@/components/FileManager'

export default {
  name: 'Place',
  mixins: [
    CrudLoad
  ],
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      model: 'places',
    }
  },
  components: {
    BaseLayout, CrudEntity, FileManager
  },
  async beforeMount(){
    this.load(this.mode, this.$route.params.id)
  }
}
</script>