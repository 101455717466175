<template>
    <div class="" @mouseleave="isOpen = false" >
      <div class="flex items-center justify-between mx-2 mt-3 relative z-top">
        <div class="">
          <button @mouseover="isOpen = true" type="button" class="block focus:outline-none">
            <router-link v-if="menuItems.route !== 'separator'" :to="menuItems.route" class="flex items-center p-2 space-x-2 text-gray-700 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline" >
                <div v-if="menuItems.icon" class="text-gray-600 flex p-1"><f-icon :icon="menuItems.icon" /></div>
                <div v-text="$t(menuItems.label)" />
            </router-link>
            <div v-else class="flex items-center p-2 space-x-2 text-gray-700 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
              <div v-if="menuItems.icon" class="text-gray-600 flex p-1"><f-icon :icon="menuItems.icon" /></div>
              <div v-text="$t(menuItems.label)" />
            </div>
            <!-- <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
              <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
              <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
            </svg>-->
            </button>
        </div>
        <nav v-if="menuItems.items && menuItems.items.length > 0" :class="isOpen ? 'flex' : 'hidden'" class="mt-10 p-2 absolute top-0 z-top flex flex-col bg-white shadow-2xl rounded-md" @mouseleave="isOpen = false">
            <div v-for="(item, index) in menuItems.items" :key="'menu-sub-item-'+index">
              <router-link :to="item.route" :class="'has-tooltip flex items-center space-x-2 text-gray-700 p-2 rounded font-medium focus:shadow-outline ' + (item.icon ? 'hover:bg-gray-200 focus:bg-gray-200' : '')" >
                  <div v-if="item.icon" class="text-gray-600 w-4 flex justify-center" :class="'mr-1'"><f-icon :icon="item.icon" /></div>
                  <div v-text="$t(item.label)" :class="!item.icon ? 'text-slate-400 font-medium uppercase text-xs border-b flex-grow pb-1 border-slate-200' : ''" />
                </router-link>
            </div>
        </nav>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EntityInfo',
    props: {
      menuItems: {
        type: [Array, Object],
        required: true,
      },
    },
    data() {
      return {
        isOpen: false,
      }
    },
  }
  </script>