<template>
  <div :class="'dropzone-wrapper ' + ((isSimple) ? 'simple ' : ' ') + ((classes) ? classes : '')">
    <LoadingSpinner :toggle="isLoading" />
    <div v-if="success != ''" class="alert alert-success" role="alert">
      {{success}}
    </div>
    <h3 v-if="uploaderTitle && !isSimple" v-html="uploaderTitle"></h3>
    <form @submit.prevent="formSubmit" enctype="multipart/form-data">
      <div class="dropzone box-content" :classes="classes" >
        <label class="flex flex-col items-center px-2 py-3 bg-white text-blue tracking-wide border-2 border-gray-300 cursor-pointer place-items-center" :class="classes" @drop.prevent="onFileChange" @dragover.prevent @dragover="dragover">
          <svg class="w-10 h-10" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" /></svg>
          <div class="mask text-center text-sm">
            <div class="file-thumb" v-if="media && media.length > 0">
              
              <div v-if="!isSimple" class="mt-2">
                <div>{{ $t('actions.file_fields') }}</div>
                <select v-model="form['type']" class="mb-1 block w-full py-1 px-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs text-gray-700">
                  <option disabled value="">{{ $t('global.select') }}</option>
                  <option v-for="(option, index) in types" :key="`static-option-${index}`" :value="option.type">
                    {{ option.name }}
                  </option>
                </select>
                <div v-if="form['type']">
                  <div v-for="(field, index) in file_fields" :key="'file-field-'+index">
                    <CrudInput
                      v-if="field.type === 'text'"
                      v-model="form[field.field]"
                      :type="field.type"
                      :field="field"
                      :placeholder="field.name"
                      classes="outline-none w-full h-7 px-2 mb-1 text-sm text-gray-700 placeholder-gray-600 border rounded-md focus:shadow-outline" />
                    <VSelect v-if="field.type === 'select'"
                      v-model="form[field.field]"
                      :model="field.related"
                      :search-field="field.label"
                      :label="field.label"
                      value-field="id"
                      placeholder="Seleziona..."
                      :icon="true"
                      iconName="file"
                      class="block w-full h-7 px-2 mb-1 text-sm border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                    </VSelect>
                  </div>
                </div>
                <div class="text-xs" v-html="$t('modals.importer.single_file_fields')" />
                <!--<input type="text" v-model="version" placeholder="Versione del documento... (es.: 1.5)">-->
              </div>

              <div class="flex flex-row flex-wrap justify-center overflow-y-auto my-2 content-center">
                <div v-for="(file, index) in media" :key="'file-'+index" class="p-1 rounded bg-green-700 text-white m-1 text-xs">
                  <div>{{ file.name}}</div>
                </div>
              </div>
              <div v-if="error" v-html="error"></div>
              <progress v-else max="100" :value.prop="uploadPercentage"></progress>

            </div>
            <div class="no-upload" v-else>
              <span v-if="isSimple" v-html="uploaderTitle"></span>
            </div>
          </div>
          <input type="file" class="file hidden" multiple
            ref="file" v-on:change="onFileChange">
        </label>
      </div>
      <div v-if="media && media.length > 0" class="">
        <button class="bg-green-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded mt-4">{{ $t('actions.upload_files') }}</button>
      </div>
    </form>
    <p v-if="warnings" class="mt-5" v-html="warnings" />   
  </div>              
</template>
<script>

import _ from 'lodash'
import { fromBlob } from 'geotiff'; // GeoTIFF, { fromUrl, fromUrls, fromArrayBuffer, fromBlob }
import { mapGetters } from 'vuex'
import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import CrudInput from '@/components/CrudInput'

export default {
  name: 'FileUploader',
  components: {
    LoadingSpinner, CrudInput
  },
  props: {
    locale: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    isSimple: {
      type: Boolean,
      default: false
    },
    modelId: {
      type: Number,
      default: null
    },
    modelName: {
      type: String,
      default: null
    },
    modelFileVersion: {
      type: Number,
      default: null
    },
    fileTypesAllowed: {
      type: Array
    },
    classes: {
      type: String,
      default: null
    },
    types: {
      type: Array,
      default: null
    },
    fields: {
      type: Array,
      default: null
    },
    mode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      file_fields: [],
      file_type: {},
      form: {},
      media: [],
      uploaded: [],
      original: null,
      success: '',
      error: null,
      uploadPercentage: 0,
      config: {
        'headers': {
          'Accept': 'application/json'
        }
      },
      uploaderTitle: null,
      canUpload: false,
      isLoading: false,
      warnings: null
    };
  },
  created() {
    this.uploaderTitle = this.title || this.$i18n.t('modals.importer.upload_only') + ((this.fileTypesAllowedTitle) ? this.fileTypesAllowedTitle : 'jpg, pdf')
  },
  async beforeMount(){    
    if(this.user.post_max_size && this.user.max_file_uploads){
      this.warnings = this.$i18n.t('modals.importer.warnings', {
        post_max_size: this.user.post_max_size,
        max_file_uploads: this.user.max_file_uploads
      })
    }
  },
  mounted() {
    // default value field assignations
    // for(var j = 0; j < this.fields.length; j++) {
    //   this.$set(this.form, this.fields[j].field, {})
    // }
  },
  computed: {
    fileTypesAllowedTitle(){
      return (this.fileTypesAllowed) ? _(this.fileTypesAllowed).map('name').value().join(', ') : null
    },
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user'
    })
  },
  watch: {
    form: {
      handler: function() { 
        if(this.form.type){ // setup file fileds by selecting file typology
          this.file_type = this.types.filter(type => { return type.type === this.form.type})[0]
          this.file_fields = this.fields.filter(field => { return this.file_type.fields.includes(field.field) })
        }
      },
      deep: true
    }
  },
  methods: {
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('border-green-600')) {
        event.currentTarget.classList.remove('border-gray-300');
        event.currentTarget.classList.add('border-green-600');
      }
    },
    async onFileChange(e){
      const vm = this
      vm.media = e.target.files || e.dataTransfer.files // second case on drag-and-drop
      if(vm.media.length > this.user.max_file_uploads) {
        vm.$toasted.error(vm.$i18n.t('modals.error.max_file_uploads_exceeded'))
        vm.media = []
        return
      }
      console.log('[parsing media...]', vm.media)
      // geotiff handling
      if(vm.media.length === 1 && vm.media[0].type === "image/tiff"){
        const tiff = await fromBlob(vm.media[0])
        const image = await tiff.getImage()
        //const origin = image.getOrigin()
        //const resolution = image.getResolution()
        const bbox = image.getBoundingBox() // x,y nw - x,y se
        // console.log('[this is a tiff!]', tiff, image, origin, resolution, bbox)
        vm.$set(vm.form, 'type', 'raster')
        vm.form.ne_lat = bbox[0]
        vm.form.ne_lng = bbox[1]
        vm.form.sw_lat = bbox[2]
        vm.form.sw_lng = bbox[3]
      }
      this.canUpload = true
    },
    async formSubmit() {
        let vm = this;
        let formData = new FormData(); // Prepare form data
        vm.error = null;
        
        // check file type (loaded from config or default)
        for (const i of Object.keys(vm.media)) {
          let fileTypes = (vm.fileTypesAllowed) 
            ? _(vm.fileTypesAllowed).map('type').value() 
            : [ 'application/pdf', 'image/jpeg', 'image/png' ]
          if(!fileTypes.includes(vm.media[i].type)){
            vm.$toasted.show(vm.$i18n.t('modals.error.upload_file_type'))
            // vm.media.splice(i, 1);
          }
        }// end if passed validation

        // proceed with upload all media
        for (let i = 0 ; i < vm.media.length ; i++) {
          console.log('[parsing each media uploaded...]', vm.media[i])
          formData.append("media[]", vm.media[i])
        }
        const config = {
          headers: { 'content-type': 'multipart/form-data' },
          onUploadProgress: function( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }

        // Setup form data
        formData.append('model',    vm.modelName || null)
        formData.append('id',       vm.modelId || null)
        formData.append('locale',   vm.locale && vm.locale.locale ? vm.locale.locale : null)
        formData.append('version',  vm.modelFileVersion ? parseInt(vm.modelFileVersion,10)+1 : (vm.version ? parseInt(vm.version,10)+1 : 1))
        formData.append('mode',     vm.mode || null)
        for(let field in vm.form)   formData.append(field, vm.form[field])

        vm.isLoading = true
        await api.upload(formData, config).then(function(response){
          if(response.data.data.success){
            console.log('[after upload]', response.data.data)
            // push uploaded file in array
            vm.uploaded = response.data.data.media
            vm.isLoading = false
            vm.$emit("inputData", vm.uploaded)
            vm.$toasted.show(vm.$i18n.t('modals.importer.uploaded'))
          }else{
            vm.$toasted.show(response.data.data.errors[0])  
            vm.isLoading = false
          }
        }).catch(function (error) {
          vm.isLoading = false
          console.log(error)
          vm.$toasted.show(vm.$i18n.t('modals.error.upload'))
        })

        // and at last reset file input...
        vm.form = {}
        vm.media = []
    }
  }
}
</script>