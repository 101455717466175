<template>
  <div v-if="type === 'radio'" :class="classes">
    <div v-for="(option, index) in field.options" :key="'radio'+index" class="flex flex-row items-center content-center mr-3">
      <input type="radio" :value="option.value" :name="field.field" :checked="value === option.value" :id="'radio-'+option.value" :disabled="disable" @input="updateInput">  
      <label :for="'radio-'+option.value" class="ml-1">{{ option.label }}</label>
    </div>
  </div>
  <input v-else
    :name="field.field"
    :value="value"
    :checked="value"
    :type="type"
    :class="classes"
    :disabled="disable"
    @input="updateInput" />
</template>
<script type="text/javascript">
  export default {
    name: 'CrudInput',
    props: {
      field: {
        type: Object,
        required: true
      },
      value: {
        required: true,
      },
      classes: {
        default: null
      },
      disable: {
        default: false
      },
      type: {
        default: 'text'
      }
    },
    data() {
      return {
        disabled: false
      }
    },
    methods: {
      updateInput(event) {
        this.$emit('input', this.type === 'checkbox' 
          ? event.target.checked 
          : event.target.value
        )
      }
    }
  }
</script>
<style>
</style>