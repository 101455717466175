<template>
  <div class="stratigraphies">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.stratigraphies.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.rometransformed.views.stratigraphies.title')" 
          model="stratigraphies"
          model-name-field="code"
          :check-owner="true"
          :check-roles="['stratigraphy_manager']"
          :columns="[
            {name: $t('plugins.rometransformed.views.stratigraphies.fields.code'), field: 'code', related: 'card'},
            {name: $t('plugins.rometransformed.views.stratigraphies.fields.observation'), field: 'name', parent: 'observation', model: 'observations'},
            {name: $t('plugins.rometransformed.views.stratigraphies.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
            {name: $t('plugins.rometransformed.views.interpretations.single'), related: 'interpretations', field: 'name'},
            {field: 'actions'},
          ]"
          :cards-importer="true"
          :cards-importer-archive-text="$t('plugins.rometransformed.views.stratigraphies.importer_archive_text')"
          :cards-file-types-allowed="[
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'CSV', type: 'text/csv'}
          ]"
          cards-import-model="rt3d_import_model.xlsx"
          :search-filters="[
            {field: 'code',     type: 'text',   label: $t('plugins.rometransformed.views.stratigraphies.fields.code')},
            {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: $t('plugins.rometransformed.views.stratigraphies.fields.archive'), placeholder: $t('views.archives.select')}
          ]"
          :shapefile-importer="true"
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'Stratigraphies',
  components: {
    BaseLayout, CrudTable
  }
}
</script>