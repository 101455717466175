<template>
  <div class="grid grid-cols-4 gap-2">
    <div class="flex pb-1">
      <span class="text-sm border rounded-l px-3 py-2 bg-gray-300 whitespace-no-wrap">Y</span>
      <input class="border rounded-r px-3 py-2 w-full" :value="value[0]" type="text" :disabled="disable" @input="updateInputX" />
    </div>
    <div class="flex pb-1">
      <span class="text-sm border rounded-l px-3 py-2 bg-gray-300 whitespace-no-wrap">X</span>
      <input class="border rounded-r px-3 py-2 w-full" :value="value[1]" type="text" :disabled="disable" @input="updateInputY" />
    </div>
    <div class="flex pb-1">
      <span class="text-sm border rounded-l px-3 py-2 bg-gray-300 whitespace-no-wrap">Z</span>
      <input class="border rounded-r px-3 py-2 w-full" :value="value[2]" type="text" :disabled="disable" @input="updateInputZ" />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PointZ',
    props: {
      value: {
        required: true,
      },
      disable: {
        default: false
      }
    },
    data() {
      return {
        disabled: false
      }
    },
    created() {

    },
    mounted() {

    },
    methods: {
      updateInputX(event) {
        this.$set(this.value,0,event.target.value)
        this.$emit('input', this.value)
      },
      updateInputY(event) {
        this.$set(this.value,1,event.target.value)
        this.$emit('input', this.value)
      },
      updateInputZ(event) {
        this.$set(this.value,2,event.target.value)
        this.$emit('input', this.value)
      }
    }
  }
</script>
<style>
</style>