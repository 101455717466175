<template>
  <div :id="'lemmatizer-'+lemmaModel" class="border border-gray-300 rounded-md">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-2" v-if="canLoad">
      <h3 class="text-md">{{ title }}</h3>
      <p class="my-3" v-html="text" />
      <div v-if="tip" class="has-tooltip text-xs text-gray-400">
        <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mt-8">{{ tip }}</span>
        <f-icon icon="circle-question" />
      </div>
      <div v-if="lemmas.length > 0">
        <div v-for="(lemma, index) in lemmas" :key="'lemma-'+index" class="mb-5 bg-gray-300 p-2 rounded">
          <VSelect
            v-model="lemma.id"
            :model="lemmaModel === 'relateds' ? 'cards' : lemmaModel"
            :search-field="lemmaField"
            :label="lemmaField"
            value-field="id"
            @input="change(lemma)"
            class="block w-full p-1 my-2 border text-xs border-gray-300 bg-white rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
          <div v-for="(pivot, index) in lemmaPivots" :key="'field-'+index">
            <label v-if="labeled" :for="pivot.field" class="text-sm font-medium text-gray-700 flex gap-1">
              <div class="pivot-label">{{ pivot.name }}</div>
              <div v-if="pivot.tip" class="has-tooltip text-xs text-gray-400">
                <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mt-8">{{ pivot.tip }}</span>
                <f-icon icon="circle-question" />
              </div>
            </label>
            <!-- Input Type TEXTEDITOR -->
            <TextEditor
              v-if="pivot.type === 'text-editor'"
              :disable="disable"
              v-model="lemma.pivot[pivot.field]"
              @input="change(lemma)"
              classes-text="bg-white" />
            <!-- Input Type TEXT -->
            <CrudInput
              v-if="pivot.type === 'text'"
              v-model="lemma.pivot[pivot.field]"
              :field="pivot"
              :type="pivot.type"
              :placeholder="pivot.name"
              :disable="disable"
              @input="change(lemma)"
              classes="outline-none w-full h-8 px-2 mb-1 text-xs text-gray-800 placeholder-gray-600 border rounded focus:shadow-outline" />
            <!-- Input Type STATIC SELECT -->
            <select v-if="pivot.type === 'static-select'" v-model="lemma.pivot[pivot.field]" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" @change="change(lemma)" :disable="disable">
              <option disabled value="">{{ $t('global.select') }}</option>
              <option v-for="(option, index) in pivot.options" :key="`static-option-${index}`" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <VSelect
              v-if="pivot.type === 'select'"
              v-model="lemma.pivot[pivot.field]"
              :model="pivot.model"
              :search-field="pivot.label"
              :label="pivot.label"
              :labels="pivot.labels"
              :default-options="pivot.options"
              :value-field="pivot.valueField || 'id'"
              @input="change(lemma)"
              :disable="disable"
              class="block w-full p-1 my-2 border text-xs border-gray-300 bg-white rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
          </div>
          <button v-if="!disable" :class="(lemma.changed ? 'bg-yellow-600 ' : 'bg-green-700 ' ) + 'hover:bg-blue-dark text-white text-xs py-1 px-3 rounded mt-2'" @click="updateLemma(lemma)"><f-icon icon="pen-to-square" /> {{ lemmaUpdateText }}</button>
          <button v-if="!disable" class="bg-red-700 hover:bg-red-dark text-white text-xs py-1 px-3 rounded ml-1" @click="destroyPrompt(lemma)"><f-icon icon="trash-can" /> {{ $t('actions.delete') }}</button>
        </div>
      </div>
      <div v-else class="mb-2 bg-gray-300 p-2 rounded">
        {{ $t('components.lemmatizer.no_lemma') }}
      </div>
      <div v-if="!isLoading && newLemma" class="bg-gray-300 p-2 rounded">
        <p class="pl-1">{{ addNewText || $t('components.lemmatizer.new') }}</p>
        <VSelect
          v-model="newLemma.name"
          :model="lemmaModel === 'relateds' ? 'cards' : lemmaModel"
          :search-field="lemmaField"
          :label="lemmaField"
          value-field="id"
          :taggable="taggable"
          :disable="disable"
          @input="selectLemma"
          class="mt-1 block w-full p-1 my-2 border text-xs border-gray-300 bg-white rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        <div v-for="(pivot, index) in lemmaPivots" :key="'pivot-'+index">
          <label v-if="labeled" :for="pivot.field" class="text-sm font-medium text-gray-700 flex gap-1">
            <div class="pivot-label">{{ pivot.name }}</div>
            <div v-if="pivot.tip" class="has-tooltip text-xs text-gray-400">
              <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mt-8">{{ pivot.tip }}</span>
              <f-icon icon="circle-question" />
            </div>
          </label>
          <!-- Input Type TEXTEDITOR -->
          <TextEditor
            v-if="pivot.type === 'text-editor'"
            v-model="newLemma.pivot[pivot.field]"
            :disable="disable"
            classes-text="bg-white" />
          <!-- Input Type TEXT -->
          <CrudInput
            v-if="pivot.type === 'text'"
            v-model="newLemma.pivot[pivot.field]"
            :field="pivot"
            :type="pivot.type"
            :placeholder="pivot.name"
            :disable="disable"
            classes="outline-none w-full h-8 px-2 mb-1 text-xs text-gray-800 placeholder-gray-600 border rounded focus:shadow-outline" />
          <!-- Input Type STATIC SELECT -->
          <select v-if="pivot.type === 'static-select'" v-model="newLemma.pivot[pivot.field]" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" :disable="disable">
            <option disabled value="">{{ $t('global.select') }}</option>
            <option v-for="(option, index) in pivot.options" :key="`static-option-${index}`" :value="option.value">
              {{ option.name }}
            </option>
          </select>
          <VSelect v-if="pivot.type === 'select'"
            v-model="newLemma.pivot[pivot.field]"
            :model="pivot.model"
            :search-field="pivot.label"
            :label="pivot.label"
            :labels="pivot.labels"
            :default-options="pivot.options"
            :value-field="pivot.valueField || 'id'"
            :disable="disable"
            class="block w-full p-1 my-2 border text-xs border-gray-300 bg-white rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <button v-if="!disable" class="bg-green-700 hover:bg-blue-dark text-white text-xs py-1 px-3 rounded mt-2" @click="associateLemma(newLemma)"><f-icon icon="pen-to-square" /> {{ lemmaAssociateText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import CrudInput from '@/components/CrudInput'
import TextEditor from '@/components/TextEditor'
import ModalDelete from '@/components/ModalDelete'

export default {
  name: 'Lemmatizer',
  components: {
    LoadingSpinner, CrudInput, TextEditor
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    lemmaAssociateText: {
      type: String,
      default: 'Associate'
    },
    lemmaUpdateText: {
      type: String,
      default: 'Associate'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String,
      required: true
    },
    relatedModel: {
      type: String,
      required: false
    },
    id: {
      type: [String, Number],
      required: true
    },
    lemmaModel: {
      type: String,
      required: true
    },
    lemmaField: {
      type: String,
      required: true
    },
    lemmaPivots: {
      type: Array,
      required: true
    },
    entities: {
      type: Array,
      default: null
    },
    addNewText: {
      type: String,
      default: null
    },
    labeled: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String
    },
    disable: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    update: {},
  },
  data: function(){
    return {
      showError: false,
      error: [],
      lemmas: [],
      newLemma: {},
      isLoading: false,
      canLoad: false
    }
  },
  watch: { 
    entities: function(newVal, oldVal) {
      console.log('entities changed: ', newVal, ' | was: ', oldVal)
      this.loadLemmas()
    }
  },
  mounted() {
    this.loadLemmas()
  },
  methods: {
    setupNewLemma(){
      // setup inizial new lemma fields empty
      this.canLoad = false
      this.newLemma = {
        id: null,
        name: null,
        pivot: {}
      }
      for(var i = 0; i < this.lemmaPivots.length; i++) {
        this.$set(this.newLemma.pivot, this.lemmaPivots[i].field, null)
      }
      this.canLoad = true
    },
    async loadLemmas(force = false) {
      const vm = this
      vm.isLoading = true
      vm.lemmas = []
      if(vm.entities && force === false){
        // remap lemmas for lemmatizer
        vm.entities.forEach(lemma => { vm.lemmas.push({id: lemma.id, pivot: lemma.pivot}) })
        this.setupNewLemma() // reset new lemma
        vm.isLoading = false
      }else{
        vm.lemmas = []
        await api.fetch(`${vm.relatedModel || vm.model}/${vm.id}/${vm.lemmaModel}`).then(response => {
          vm.lemmas = response.data // remap lemmas for lemmatizer
          this.setupNewLemma() // reset new lemma
          vm.isLoading = false
        })
      }
      vm.lemmas.forEach(lemma => {
        lemma.changed = false
      })
    },
    async associateLemma(lemma){
      const vm = this
      vm.isLoading = true
      try{
        await api.create(`${vm.relatedModel || vm.model}/${vm.id}/${vm.lemmaModel}`, {lemma: lemma}).then(response => {
          if(response.success){
            vm.loadLemmas(true)
            vm.setupNewLemma()
            vm.isLoading = false
          }else{ // errors
            vm.isLoading = false
            for(let error in response.errors){
              vm.$toasted.error(response.errors[error])
            }
          }
        })
      }catch(e){
        vm.isLoading = false
        vm.$toasted.error(vm.$i18n.t('modals.error.lemma_attach')) 
      }
    },
    selectLemma(lemma){
      this.newLemma.id = lemma
    },
    async destroyPrompt(lemma){
      const vm = this
      vm.$modal.show(ModalDelete,
        {
          title: vm.$i18n.t('modals.delete.title'),
          text: vm.$i18n.t('modals.delete.content'),
          deleteText: vm.$i18n.t('actions.delete'),
          entity: lemma,
          model: `${vm.relatedModel || vm.model}/${vm.id}/${vm.lemmaModel}`,
          update: () => {
            vm.loadLemmas(true)
          }
        },{
          height: 'auto'
        }
      )
    },
    async updateLemma(lemma){
      const vm = this
      vm.isLoading = true
      try{
        await api.update(vm.relatedModel || vm.model, vm.id, {lemma: lemma}, vm.lemmaModel, lemma.id).then(response => {
          if(response.success){
            vm.loadLemmas(true)
            vm.isLoading = false
          }else{ // errors
            vm.isLoading = false
            for(let error in response.errors){
              vm.$toasted.error(response.errors[error])
            }
          }
        })
      }catch(e){
        vm.isLoading = false
        vm.$toasted.error(vm.$i18n.t('modals.error.lemma_update')) 
      }
    },
    change(lemma){
      this.lemmas[this.lemmas.indexOf(lemma)].changed = true
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
