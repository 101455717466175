<template>
  <div class="stratigraphies">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.stratigraphies.single') }}
      </template>
      <template v-slot:main>
        <CrudEntity v-if="canLoad"
          :model="model" :id="id" :mode="crud_mode" :entity="entity" :is-extended-card="true" extends="card" :has-location="true"
          :fields="[

            // Base Card fields
            // Base Card fields
            {text: $t('views.cards.fields.card_separator'), type: 'separator'},
            {name: $t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
            {name: $t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
            {name: $t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', ttip: $t('plugins.rometransformed.views.stratigraphies.tips.is_visible')},
            {name: $t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: $t('plugins.rometransformed.views.stratigraphies.tips.is_published')},
            {name: $t('plugins.rometransformed.views.stratigraphies.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
            {name: $t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', labels: ['name', 'surname'], select: select_user },
            {name: $t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},

            // Main fields
            {text: '', type: 'separator'},
            {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'), 
              field: 'description', type: 'text-editor', span: '2'},
            {name: $t('plugins.rometransformed.views.stratigraphies.fields.bibliography'), 
              field: 'bibliography', type: 'text-editor', span: '2'},
      
            // setup Lemma + pivot
            {text: $t('views.cards.fields.lemma_separator'), type: 'separator'},
            { // Zones
              field: 'zone_id', type: 'lemma', model: 'zones', label: 'name', span: '2',
              pivots: [ 
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', type: 'text'}
              ],
              name: $t('plugins.rometransformed.views.stratigraphies.fields.zone'), 
              title: $t('plugins.rometransformed.views.stratigraphies.zone.title')
            },
            { // Methodologies
              field: 'methodology_id', type: 'lemma', model: 'methodologies', label: 'name', span: '2',
              pivots: [ 
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', type: 'text'}
              ],
              name: $t('plugins.rometransformed.views.stratigraphies.fields.methodology'), 
              title: $t('plugins.rometransformed.views.stratigraphies.methodology.title')
            },
            { // Types
              field: 'type_id', type: 'lemma', model: 'types', label: 'name', span: '2',
              pivots: [ 
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'),     field: 'description', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.function'),        field: 'function', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.interpretation'),  field: 'interpretation', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.start_date'),      field: 'start_date', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.end_date'),        field: 'end_date', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.source'),          field: 'source', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.bibliography'),    field: 'bibliography', type: 'text'},
              ],
              name: $t('plugins.rometransformed.views.stratigraphies.fields.type'), 
              title: $t('plugins.rometransformed.views.stratigraphies.type.title')
            },
            { // Interpretations
              field: 'interpretation_id', type: 'lemma', model: 'interpretations', label: 'name', span: '2',
              pivots: [ 
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'),     field: 'description', type: 'text'},
              ],
              name: $t('plugins.rometransformed.views.stratigraphies.fields.interpretation'), 
              title: $t('plugins.rometransformed.views.stratigraphies.interpretation.title')
            },

            {name: $t('plugins.rometransformed.views.stratigraphies.fields.observation'), field: 'observation_id', type: 'select', model: 'observations', label: 'name' },

            // Location fields
            {text: $t('plugins.rometransformed.views.stratigraphies.fields.location_separator'), type: 'separator'},
            {name: $t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
              fields: [ 
                {name: $t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
                {name: $t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
                {name: $t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.x'), field: 'x', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.y'), field: 'y', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.top'), field: 'top', type: 'text'},
                {name: $t('plugins.rometransformed.views.stratigraphies.fields.bottom'), field: 'bottom', type: 'text'},
                {name: $t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry',      geometry: 'pointz', span: '4'},
                {name: $t('views.locations.fields.multipointz'),  related: 'card', subrelated: 'location', field: 'multipointz', type: 'geometry', geometry: 'multipointz', span: '4'}
              ],
              pivots: [ 
                {name: $t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
                {name: $t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
              ],
              pivotTitle: $t('plugins.rometransformed.views.observations.locations.pivot')
            },
          ]"
        />
        <div v-if="id" class="mt-2">
          <FileManager v-if="canLoad"
            :mode="crud_mode"
            :model-id="parseInt(id)"
            :model="model"
            :entity-data="entity"
            :related="true"
            :uploaderText="$t('plugins.rometransformed.views.stratigraphies.uploader_text')"
            :file-types-allowed="[
              {name: 'PNG', type: 'image/png'},
              {name: 'JPG', type: 'image/jpeg'},
              {name: 'TIFF', type: 'image/tiff'},
              {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
              {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
              {name: 'OBJ', type: 'text/plain'},
            ]"
            :file-model-types="[
              {type: 'document', name: $t('views.cards.files.types.document'),  fields: ['description']},
              {type: 'raster',   name: $t('views.cards.files.types.raster'),    fields: ['description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
              {type: 'image', name: $t('views.cards.files.types.image'),  fields: ['description']},
              {type: 'photo', name: $t('views.cards.files.types.photo'),  fields: ['description']},
              {type: 'threed_model', name: $t('views.cards.files.types.threed_model'), fields: ['description', 'file_id']},
              {type: 'threed_texture', name: $t('views.cards.files.types.threed_texture'), fields: ['description', 'file_id']},
              {type: 'spheric_picture', name: $t('views.cards.files.types.spheric_picture'), fields: ['description']},
            ]"
            :file-model-fields="[
              {name: $t('views.cards.files.fields.description'), field: 'description', type: 'text'},
              {name: $t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
              {name: $t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
              {name: $t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
              {name: $t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
              {name: $t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
              {name: $t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: $t('views.cards.files.fields.file_select')},
              {name: $t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
            ]"
            />
        </div>
        <!-- <div v-if="id" class="mt-2">
          <Map v-if="canLoad" 
            :model="model" 
            :id="parseInt(id)" 
            :related="true"
          />
        </div> -->
        <!--<div v-if="id">
          <GalleryManager
            :model-id="parseInt(id)"
            model="stratigraphies"
            :related="true"
            :uploaderText="'Carica i file da associare alla stratigrafia'"
            :file-types-allowed="[
              {name: 'PNG', type: 'image/png'},
              {name: 'JPG', type: 'image/jpeg'},
              {name: 'TIFF', type: 'image/tiff'},
              {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
              {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
              {name: 'OBJ', type: 'application/octet-stream'}
            ]"
            />
        </div>-->
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudLoad from '@/components/CrudLoad'
import CrudEntity from '@/components/CrudEntity'
import FileManager from '@/components/FileManager'
// import Map from '@/components/Map'

export default {
  name: 'Stratigraphy',
  mixins: [
    CrudLoad
  ],
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      model: 'stratigraphies',
    }
  },
  components: {
    BaseLayout, CrudEntity, FileManager // Map, 
  },
  async beforeMount(){
    this.load(this.mode, this.$route.params.id)
  }
}
</script>