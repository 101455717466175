var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"methodologies"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.rometransformed.views.methodologies.subtitle'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('CrudTable',{attrs:{"title":_vm.$t('plugins.rometransformed.views.methodologies.title'),"model":"methodologies","model-name-field":"code","columns":[
          {name: _vm.$t('plugins.rometransformed.views.methodologies.fields.code'), field: 'code'},
          {name: _vm.$t('plugins.rometransformed.views.methodologies.fields.name'), field: 'name'},
          {name: _vm.$t('plugins.rometransformed.views.methodologies.fields.color'), field: 'color', view: 'color'},
          {field: 'actions'},
        ],"search-filters":[
          {field: 'code', type: 'text', label: _vm.$t('plugins.rometransformed.views.methodologies.fields.code')},
          {field: 'name', type: 'text', label: _vm.$t('plugins.rometransformed.views.methodologies.fields.name')},
        ]}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }