<template>
  <div :id="'relatizer-'+relatedModel" class="border border-slate-300 rounded-md">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-2" v-if="canLoad">
      <h3 class="text-md">{{ title }}</h3>
      <p class="my-3" v-html="text" />
      <div v-if="relateds.length > 0">
        <div v-for="(related, index) in relateds" :key="'related-'+index" class="mb-2 bg-slate-200 p-2 rounded-md">
          <div class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
            <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in relatedFields" :key="'field-'+index">
              <label :for="field.field" class="text-sm font-medium text-slate-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-slate-400">
                  <span class="tooltip rounded shadow-lg p-1 bg-slate-100 text-slate-500 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
              <!-- Input Type TEXTEDITOR -->
              <TextEditor
                v-if="field.type === 'text-editor'"
                v-model="related[field.field]"
                @input="change(related)"
                :disable="disable"
                classes-text="bg-white" />
              <!-- Input Type TEXT -->
              <CrudInput
              v-if="field.type === 'text'"
                v-model="related[field.field]"
                :field="field"
                :type="field.type"
                :disable="disable"
                @input="change(related)"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-slate-800 placeholder-slate-600 border rounded-md focus:shadow-outline"  />
              <!-- Input Type STATIC SELECT -->
              <select v-if="field.type === 'static-select'" v-model="related[field.field]" @change="change(related)" class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option disabled value="">{{ $t('global.select') }}</option>
                <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <!-- Input Type MULTI SELECT -->
              <VSelectMulti v-if="field.type === 'multi-select'" 
                v-model="related[field.field]" 
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                :labels="field.labels"
                value-field="id"
                :placeholder="$t('global.select')"
                class="mt-1 block w-full border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable" />
              <!-- Input Type SELECT (API) -->
              <VSelect v-if="field.type === 'select'"
                v-model="related[field.field]"
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                value-field="id"
                :placeholder="$t('global.select')"
                class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable"></VSelect>
              <!-- Input Type MAP -->
              <div v-if="field.type === 'location'">
                <div v-if="!related[field.field]">
                  <div>{{ $t('components.map.want_add_location') }}</div>
                  <button @click="addLocation(field, related)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.add_location') }}</button>
                </div>
                <div v-else style="height: 400px; width: 100%" class=" mb-16">
                  <div class="grid grid-cols-2 gap-6">
                    <input v-model="related[field.field].coordinates[0]" class="outline-none h-10 px-3 mb-2 text-base text-slate-400 placeholder-slate-300 border rounded-lg focus:shadow-outline" type="text" />
                    <input v-model="related[field.field].coordinates[1]" class="outline-none h-10 px-3 mb-2 text-base text-slate-400 placeholder-slate-300 border rounded-lg focus:shadow-outline" type="text" />
                  </div>
                  <div class="bg-slate-200 px-2 py-1 mb-1 text-xs text-slate-300">{{ $t('components.map.tip_double_click') }}</div>
                  <l-map
                    ref="map"
                    v-if="showMap"
                    :zoom="zoom"
                    :center="coords(related[field.field].coordinates)"
                    :options="mapOptions"
                    style="height: 80%"
                    @update:center="centerUpdate"
                    @update:zoom="zoomUpdate"
                    @dblclick="updateCoords($event, field, related)"
                  >
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-geosearch :options="geosearchOptions" />
                    <l-marker :lat-lng="coords(related[field.field].coordinates)" :draggable="true" @move="updateCoords($event, field, related)">
                      <l-popup>
                        <div @click="innerClick">
                          {{ $t('components.map.place_coordinates') + ' ' + coords(related[field.field].coordinates) }}
                          <p v-show="showParagraph">{{ $t('components.map.popup_default') }}</p>
                        </div>
                      </l-popup>
                    </l-marker>
                  </l-map>
                  <button @click="removeLocation(field, related)" class="bg-red-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.remove_location') }}</button>
                </div>
              </div>
              <!-- Input Type GEOMETRY -->
              <div v-if="field.type === 'geometry'">
                <InputGeometry v-model="related[field.field]" :field="field" :type="field.geometry" :mode="field.mode" :digitize-type="field.digitizeType" @updateGeometry="updateGeometry($event, related)" :disable="disable" />
              </div>
            </div>
          </div>
          <button :class="(related.changed ? 'bg-yellow-600 ' : 'bg-green-700 ') + 'hover:bg-blue-dark text-white font-bold py-1 px-3 rounded'" @click="updateRelated(related)"><f-icon icon="pen-to-square" /> {{ relatedUpdateText }}</button>
          <button class="bg-red-700 hover:bg-red-dark text-white font-bold py-1 px-3 rounded ml-2" @click="destroyPrompt(related)"><f-icon icon="trash-can" /> {{ $t('actions.delete') }}</button>
        </div>
      </div>
      <div v-else class="mb-2 bg-slate-300 p-2 rounded-md">
        {{ $t('components.relatizer.no_related') }}
      </div>
      <div class="bg-slate-200 p-2 rounded-md">
        <h3 class="text-lg pl-0 pb-3 font-bold">{{ relatedAddTitle || $t('components.relatizer.new') }}</h3>
        <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
          <!--<div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4`"></div>-->
          <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in relatedFields" :key="'pivot-'+index">
            <label :for="field.field" class="text-sm font-medium text-slate-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-slate-400">
                  <span class="tooltip rounded shadow-lg p-1 bg-slate-100 text-slate-500 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
            <!-- Input Type TEXTEDITOR -->
            <TextEditor
              v-if="field.type === 'text-editor'"
              v-model="newRelated[field.field]"
              classes-text="bg-white" />
            <CrudInput
              v-if="field.type === 'text'"
              v-model="newRelated[field.field]"
              :field="field"
              :type="field.type"
              classes="outline-none w-full h-10 px-3 mb-2 text-base text-slate-800 placeholder-slate-600 border rounded-md focus:shadow-outline" />
            <!-- Input Type STATIC SELECT -->
            <select v-if="field.type === 'static-select'" v-model="newRelated[field.field]" class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
              <option disabled value="">{{ $t('global.select') }}</option>
              <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                {{ option.name }}
              </option>
            </select>
            <!-- Input Type MULTI SELECT -->
            <VSelectMulti v-if="field.type === 'multi-select'" 
              v-model="newRelated[field.field]" 
              :model="field.model"
              :search-field="field.label"
              :label="field.label"
              :labels="field.labels"
              value-field="id"
              :placeholder="$t('global.select')"
              class="mt-1 block w-full border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            <!-- Input Type SELECT (API) -->
            <VSelect v-if="field.type === 'select'"
              v-model="newRelated[field.field]"
              :model="field.model"
              :search-field="field.label"
              :label="field.label"
              value-field="id"
              :placeholder="$t('global.select')"
              class="mt-1 block w-full py-2 px-3 border border-slate-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
            <!-- Input Type MAP -->
            <div v-if="field.type === 'location'">
              <div v-if="!newRelated[field.field]">
                <div>{{ $t('components.map.want_add_location') }}</div>
                <button @click="addLocation(field)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.add_location') }}</button>
              </div>
              <div v-else style="height: 400px; width: 100%" class=" mb-16">
                <div class="grid grid-cols-2 gap-6">
                  <input v-model="newRelated[field.field].coordinates[0]" class="outline-none h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" type="text" />
                  <input v-model="newRelated[field.field].coordinates[1]" class="outline-none h-10 px-3 mb-2 text-base text-slate-700 placeholder-slate-600 border rounded-lg focus:shadow-outline" type="text" />
                </div>
                <div class="bg-slate-200 px-2 py-1 mb-1 text-xs text-slate-600">{{ $t('components.map.tip_double_click') }}</div>
                <l-map
                  ref="map"
                  v-if="showMap"
                  :zoom="zoom"
                  :center="coords(newRelated[field.field].coordinates)"
                  :options="mapOptions"
                  style="height: 80%"
                  @update:center="centerUpdate"
                  @update:zoom="zoomUpdate"
                  @dblclick="updateCoords($event, field)"
                >
                  <l-tile-layer :url="url" :attribution="attribution" />
                  <l-geosearch :options="geosearchOptions" />
                  <l-marker :lat-lng="coords(newRelated[field.field].coordinates)" :draggable="true" @move="updateCoords($event, field)">
                    <l-popup>
                      <div @click="innerClick">
                        {{ $t('components.map.place_coordinates') + ' ' + coords(newRelated[field.field].coordinates) }}
                        <p v-show="showParagraph">{{ $t('components.map.popup_default') }}</p>
                      </div>
                    </l-popup>
                  </l-marker>
                </l-map>
                <button @click="removeLocation(field)" class="bg-red-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.remove_location') }}</button>
              </div>
            </div>
            <!-- Input Type GEOMETRY -->
            <div v-if="field.type === 'geometry'">
              <InputGeometry v-model="newRelated[field.field]" :field="field" :type="field.geometry" :mode="field.mode" :digitize-type="field.digitizeType" @updateGeometry="updateGeometry" />
            </div>
          </div>
        </div>
        <button class="bg-green-700 hover:bg-blue-dark text-white font-bold py-1 px-3 rounded" @click="addRelated(newRelated)"><f-icon icon="pen-to-square" /> {{ relatedAddText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import { latLng } from "leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import LoadingSpinner from '@/components/LoadingSpinner'
import CrudInput from '@/components/CrudInput'
import InputGeometry from '@/components/InputGeometry'
import TextEditor from '@/components/TextEditor'
import ModalDelete from '@/components/ModalDelete'

export default {
  name: 'Relatizer',
  components: {
    LoadingSpinner, CrudInput, TextEditor, InputGeometry
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    relatedAddTitle: {
      type: String,
      default: null
    },
    relatedAddText: {
      type: String,
      default: 'Add'
    },
    relatedUpdateText: {
      type: String,
      default: 'Add'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String,
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    },
    relatedModel: {
      type: String,
      required: true
    },
    relatedField: {
      type: String,
      required: true
    },
    relatedFields: {
      type: Array,
      required: true
    },
    entities: {
      type: Array,
      default: null
    },
    disable: {
      default: false
    },
    update: {},
  },
  data: function(){
    return {
      showError: false,
      error: [],
      relateds: [],
      newRelated: {},
      isLoading: false,
      canLoad: false,
      zoom: 15,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false
      },
      tileOptions: {
        maxZoom: 18, // maxZoom: testCRS.options.resolutions.length,
        maxNativeZoom: 18,
        minZoom: 0,
        continuousWorld: true,
        worldCopyJump: false
      },
      showMap: true,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        searchLabel: 'Search...',
        autoClose: true
      },
    }
  },
  watch: { 
    entities: function() {
      this.loadRelateds()
    }
  },
  created(){
    this.url = process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    this.attribution = process.env.VUE_APP_MAP_ATTRIBUTION || '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    this.tileOptions.minZoom = process.env.VUE_APP_MAP_MIN_ZOOM || 1
    this.tileOptions.maxZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    this.tileOptions.maxNativeZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
  },
  mounted() {
    this.loadRelateds()
    this.changed = false
  },
  methods: {
    setupNewRelated(){
      // setup inizial new related fields empty
      this.newRelated = {
        id: null
      }
      for(var i = 0; i < this.relatedFields.length; i++) {
        this.$set(this.newRelated, this.relatedFields[i].field, null)
      }
      this.canLoad = true
    },
    async loadRelateds(force = false) {
      // load existing related addd
      const vm = this
      vm.isLoading = true
      vm.relateds = []
      if(vm.entities && force === false){
        // remap relateds for relatizer
        vm.entities.forEach(related => { vm.relateds.push(related) })
        this.setupNewRelated() // reset new related
        vm.isLoading = false
      }else{
        vm.relateds = []
        await api.fetch(`${vm.model}/${vm.id}/${vm.relatedModel}`).then(response => {
          // remap relateds for relatizer
          vm.relateds = response.data
        }).catch(e => {
          vm.error = e.data || e
        }).then(() => {
          this.setupNewRelated() // reset new related
          vm.isLoading = false
        })
      }
      vm.relateds.forEach(related => {
        related.changed = false
      })
    },
    async addRelated(related){
      const vm = this
      vm.isLoading = true
      try{
        await api.create(`${vm.model}/${vm.id}/${vm.relatedModel}`, related).then(()=>{
          vm.loadRelateds(true)
          vm.setupNewRelated()
          vm.isLoading = false
        })
      }catch(e){
        vm.isLoading = false
        console.log(e)
        vm.$toasted.error(vm.$i18n.t('modals.error.related')) 
      }
    },
    selectRelated(related){
      this.newRelated.id = related
    },
    async destroyPrompt(entity){
      // TODO: fix per lo splice del related eliminato, se è primo di tutti gli altri prendono il valore della select di quello eliminato
      const vm = this
      vm.$modal.show(ModalDelete,
        {
          title: vm.$i18n.t('modals.delete.title'),
          text: vm.$i18n.t('modals.delete.content'),
          deleteText: vm.$i18n.t('actions.delete'),
          entity: entity,
          model: `${vm.model}/${vm.id}/${vm.relatedModel}`,
          update: () => {
            vm.relateds.splice(vm.relateds.indexOf(entity), 1)      
          }
        },{
          height: 'auto'
        }
      )
    },
    async updateRelated(related){
      const vm = this
      vm.isLoading = true
      try{
        await api.update(vm.model, vm.id, related, vm.relatedModel, related.id).then(() => {
          vm.loadRelateds(true)
          vm.isLoading = false
        })
      }catch(e){
        vm.isLoading = false
        vm.$toasted.error(vm.$i18n.t('modals.error.related'))
      }
    },
    change(related){
      this.relateds[this.relateds.indexOf(related)].changed = true
    },
    innerClick() {
      alert("Click!");
    },
    coords(coordinates){
      return latLng(coordinates[1], coordinates[0])
    },
    updateCoords(event, field, related = null){
      console.log(event, field, related)
      if(field){
        this.$set(related ? related[field.field] : this.newRelated[field.field], 'coordinates', [event.latlng.lng, event.latlng.lat])
      }
    },
    defaultCoords(){
      return (process.env.VUE_APP_BASE_LAT && process.env.VUE_APP_BASE_LNG) 
        ? [parseFloat(process.env.VUE_APP_BASE_LAT), parseFloat(process.env.VUE_APP_BASE_LNG)]
        : [parseFloat(12.1234), parseFloat(44.1234)]
    },
    onSearch(value){
      // geosearch/showlocation
      console.log('onSearch', value)
    },
    updateGeometry(data, related = null){
      const vm = this
      var field_ref = related ? related : vm.newRelated
      if(data){
        // to setup empty geometry
        if(data.action === 'create'){
          let coords_data = null
          switch(data.type){
            // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
            case 'polygon':
            case 'polygonz':
            case 'linestringz':
              coords_data = {coordinates: [data.coordinates], type: data.type}
              break
            case 'point':
            case 'pointz':
            case 'multipointz':
              coords_data = {coordinates: data.coordinates, type: data.type}
              break
          }
          vm.$set(field_ref, data.geometry, coords_data)
        }
        // to remove a geometry
        if(data.action === 'remove'){
          //console.log('[remove geometry]', data.index, field_ref[data.geometry])
          if(data.index) { // to remove just one PointZ from MultiPointZ
            switch(data.type){
              // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
              case 'polygon':
              case 'polygonz':
                vm.$delete(field_ref[data.geometry]['coordinates'][0], data.index)
                break
              case 'multipointz':
              case 'linestringz':
              default:
                vm.$delete(field_ref[data.geometry]['coordinates'], data.index)
                break
            }
          }else{ // for others geometries
            vm.$set(field_ref, data.geometry, null)
          }
        }
        // to add a point to a geometry (e.g.: MultiPointZ)
        if(data.action === 'add'){
          //console.log('[add geometry]', data.index, field_ref[data.geometry]['coordinates'])
          switch(data.type){
            // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
            case 'polygon':
            case 'polygonz':
            case 'multipointz':
              vm.$set(field_ref[data.geometry]['coordinates'][0], data.index, data.coordinates)
              break
            case 'linestringz':
            default:
              vm.$set(field_ref[data.geometry]['coordinates'], data.index, data.coordinates)
              break;
          }
        }
      }
    },
    removeLocation(field, related = null){
      this.$set(related ? related : this.newRelated, field.field, null)
    },
    addLocation(field, related = null){
      this.$set(related ? related : this.newRelated, field.field, {coordinates: this.defaultCoords(), type:'Point'})
      // this.$nextTick(()=>{
      //     this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
      // })
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
