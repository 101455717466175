<template>
  <nav :class="classes + ' flex flex-row justify-between ' + (topBarBackground || 'bg-primary')">
    <div class="p-2">
      <img v-if="checkLogo()" :src="getLogo()" alt="Topbar Logo" class=" h-5" />
    </div>
    <ul class="flex flex-row items-center text-xs py-2 px-2" v-if="showTopBar">
      <li v-for="(menuItem, index) in menuItems" :key="'topbar-menu-item-'+index">
        <div v-if="menuItem.external" class="mx-2">
          <a :href="menuItem.route" target="_blank" class="text-white hover:text-gray-300 focus:text-gray-300">{{ menuItem.label }}</a>
        </div>
        <router-link v-else :to="menuItem.route" class="mx-2 text-white hover:text-gray-300 focus:text-gray-300">
          <span>{{ menuItem.label }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script type="text/javascript">

import { mapGetters } from 'vuex'
import config from '@/config'

export default {
  name: 'TopBar',
  props: {
    classes: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
      locale: 'app/locale',
      showUserMenu: 'app/showUserMenu'
    }),
    appName() {
      return process.env.VUE_APP_NAME || 'ReSole'
    },
  },
  data: () => ({
    showTopBar: true,
    menuItems: [],
    topBarBackground: null
  }),
  created(){
    // add default config menu items and from plugins
    this.menuItems = config.topBarMenuItems
    this.topBarBackground = config.style.topBarBackground
  },
  mounted(){
  },
  methods: {
    checkLogo(){
      return process.env.VUE_APP_TOPBAR_LOGO
    },
    getLogo(){
      return require(process.env.VUE_APP_TOPBAR_LOGO)
    }
  }
}
</script>
<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
a:hover {
  cursor: pointer;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>