<template>
  <div class="modal">
    <div class="p-2">
      <h1 class="text break-words">{{ title }}</h1>
      <p class="my-2">{{ text }}</p>
      <img v-if="url" :src="url" />
      <div class="actions mt-2 flex justify-between">
          <button class="bg-gray-500 hover:bg-blue-dark text-white py-1 px-2 rounded-sm" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalView',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    url: {
      type: String,
    },
    cancelText: {
      type: String,
      default: 'CLOSE'
    },
    update: {}
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
