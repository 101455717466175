import { render, staticRenderFns } from "./ModalMediaImporter.vue?vue&type=template&id=c5d5fd7e&scoped=true&"
import script from "./ModalMediaImporter.vue?vue&type=script&lang=js&"
export * from "./ModalMediaImporter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5d5fd7e",
  null
  
)

export default component.exports