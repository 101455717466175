var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.webgis.title'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('Map',{attrs:{"model":"gis/all","height":"800px","wmsLayers":[
        {
          name: 'Carta Geometrica della Toscana (G. Inghirami - 1830)',
          visible: true,
          format: 'image/png',
          url: 'http://www502.regione.toscana.it/wmsraster/com.rt.wms.RTmap/wms?map=wmscastore&map_resolution=91&language=ita',
          layer: 'r_toscan_cst_inghirami',
          transparent: true,
          attribution: 'WMS Castore, Regione Toscana'
        },
        {
          name: 'Catasto Storico Toscana - Continuo territoriale dei Fogli',
          visible: true,
          format: 'image/png',
          url: 'http://www502.regione.toscana.it/wmsraster/com.rt.wms.RTmap/wms?map=wmscastore&map_resolution=91&language=ita',
          layer: 'r_toscan_cst_fogli',
          transparent: true,
          attribution: 'WMS Castore, Regione Toscana'
        }
      ]}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }