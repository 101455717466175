import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import plugins from '@/plugins'

Vue.use(Router)

const defaultRoutes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Home.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('@/views/Unauthorized.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/verify-email',
    name: 'verifyemail',
    component: () => import('@/views/VerifyEmail.vue')
  },
  {
    path: '/reset-password',
    name: 'resetpassword',
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  // projects routes
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_projects')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/projects/edit/:id',
    name: 'project.edit',
    component: () => import('@/views/Project.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_projects')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/projects/new',
    name: 'project.new',
    component: () => import('@/views/Project.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_projects')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // users routes
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/users/edit/:id',
    name: 'user.edit',
    component: () => import('@/views/User.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/users/new',
    name: 'user.new',
    component: () => import('@/views/User.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // cards routes
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/Cards.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/cards/show/:id',
    name: 'card.show',
    component: () => import('@/views/Card.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/cards/edit/:id',
    name: 'card.edit',
    component: () => import('@/views/Card.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/cards/new',
    name: 'card.new',
    component: () => import('@/views/Card.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // archives routes
  {
    path: '/archives',
    name: 'archives',
    component: () => import('@/views/Archives.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/archives/edit/:id',
    name: 'archive.edit',
    component: () => import('@/views/Archive.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/archives/new',
    name: 'archive.new',
    component: () => import('@/views/Archive.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // tenants routes
  {
    path: '/tenants',
    name: 'tenants',
    component: () => import('@/views/Tenants.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_tenants')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/tenants/edit/:id',
    name: 'tenant.edit',
    component: () => import('@/views/Tenant.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_tenants')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/tenants/new',
    name: 'tenant.new',
    component: () => import('@/views/Tenant.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_tenants')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Locations routes
  {
    path: '/locations',
    name: 'locations',
    component: () => import('@/views/Locations.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/locations/show/:id',
    name: 'location.show',
    component: () => import('@/views/Location.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/locations/edit/:id',
    name: 'location.edit',
    component: () => import('@/views/Location.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/locations/new',
    name: 'location.new',
    component: () => import('@/views/Location.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Classifications routes
  {
    path: '/classifications',
    name: 'classifications',
    component: () => import('@/views/Classifications.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/classifications/show/:id',
    name: 'classification.show',
    component: () => import('@/views/Classification.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/classifications/edit/:id',
    name: 'classification.edit',
    component: () => import('@/views/Classification.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/classifications/new',
    name: 'classification.new',
    component: () => import('@/views/Classification.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Tags routes
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/Tags.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/tags/show/:id',
    name: 'tag.show',
    component: () => import('@/views/Tag.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/tags/edit/:id',
    name: 'tag.edit',
    component: () => import('@/views/Tag.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/tags/new',
    name: 'tag.new',
    component: () => import('@/views/Tag.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_archives') || user.permissions.includes('manage_cards')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Medias routes
  {
    path: '/files',
    name: 'files',
    component: () => import('@/views/Medias.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/files/show/:id',
    name: 'files.show',
    component: () => import('@/views/Media.vue'),
    props: { mode: 'show', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Map viewer
  // {
  //   path: '/mapviewer',
  //   name: 'mapviewer',
  //   component: () => import('@/views/MapViewer.vue'),
  //   props: { mode: 'edit', }
  // },

  // Logs routes
  {
    path: '/logs',
    name: 'logs',
    component: () => import('@/views/Logs.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Persons
  {
    path: '/persons',
    name: 'persons',
    component: () => import('@/views/Persons.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_registries') || user.permissions.includes('manage_persons')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/persons/edit/:id',
    name: 'person.edit',
    component: () => import('@/views/Person.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_registries') || user.permissions.includes('manage_persons')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    },
    props: { mode: 'edit', }
  },
  {
    path: '/persons/new',
    name: 'person.new',
    component: () => import('@/views/Person.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_registries') || user.permissions.includes('manage_persons')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    },
    props: { mode: 'new', }
  },
  // PersonTypes
  {
    path: '/person_types',
    name: 'person_types',
    component: () => import('@/views/PersonTypes.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/person_types/edit/:id',
    name: 'person_type.edit',
    component: () => import('@/views/PersonType.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/person_types/new',
    name: 'person_type.new',
    component: () => import('@/views/PersonType.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  // Qualifications
  {
    path: '/qualifications',
    name: 'qualifications',
    component: () => import('@/views/Qualifications.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/qualifications/edit/:id',
    name: 'qualification.edit',
    component: () => import('@/views/Qualification.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/qualifications/new',
    name: 'qualification.new',
    component: () => import('@/views/Qualification.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  // LocationPersonTypes
  {
    path: '/location_person_types',
    name: 'location_person_types',
    component: () => import('@/views/LocationPersonTypes.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/location_person_types/edit/:id',
    name: 'location_person_type.edit',
    component: () => import('@/views/LocationPersonType.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/location_person_types/new',
    name: 'location_person_type.new',
    component: () => import('@/views/LocationPersonType.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  // CardPersonTypes
  {
    path: '/card_person_types',
    name: 'card_person_types',
    component: () => import('@/views/CardPersonTypes.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/card_person_types/edit/:id',
    name: 'card_person_type.edit',
    component: () => import('@/views/CardPersonType.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/card_person_types/new',
    name: 'card_person_type.new',
    component: () => import('@/views/CardPersonType.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  // Registries routes
  {
    path: '/registries',
    name: 'registries',
    component: () => import('@/views/Registries.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/registries/edit/:id',
    name: 'registry.edit',
    component: () => import('@/views/Registry.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/registries/new',
    name: 'registry.new',
    component: () => import('@/views/Registry.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  // CardContainers
  {
    path: '/card_containers',
    name: 'card_containers',
    component: () => import('@/views/CardContainers.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_card_containers')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/card_containers/edit/:id',
    name: 'card_container.edit',
    component: () => import('@/views/CardContainer.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_card_containers')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/card_containers/new',
    name: 'card_container.new',
    component: () => import('@/views/CardContainer.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_card_containers')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  // CardContainerTypes
  {
    path: '/card_container_types',
    name: 'card_container_types',
    component: () => import('@/views/CardContainerTypes.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/card_container_types/edit/:id',
    name: 'card_container_type.edit',
    component: () => import('@/views/CardContainerType.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },
  {
    path: '/card_container_types/new',
    name: 'card_container_type.new',
    component: () => import('@/views/CardContainerType.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      if(user.permissions.includes('manage_users')){
        next()
      }else{
        next({name: 'unauthorized'})
      }
    }
  },

  // Bibliographic References
  {
    path: '/references',
    name: 'references',
    component: () => import('@/views/References.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/references/edit/:id',
    name: 'reference.edit',
    component: () => import('@/views/Reference.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/references/new',
    name: 'reference.new',
    component: () => import('@/views/Reference.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  
  // Bibliographic Reference Sources
  {
    path: '/reference_sources',
    name: 'reference_sources',
    component: () => import('@/views/ReferenceSources.vue'),
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/reference_sources/edit/:id',
    name: 'reference_source.edit',
    component: () => import('@/views/ReferenceSource.vue'),
    props: { mode: 'edit', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/reference_sources/new',
    name: 'reference_source.new',
    component: () => import('@/views/ReferenceSource.vue'),
    props: { mode: 'new', },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
  {
    path: '/communications',
    name: 'communications',
    component: () => import('@/views/Communications.vue'),
    props: { mode: 'new' },
    beforeEnter: (to, from, next) => {
      let user = store.getters['auth/user']
      if(!user.id) next({name: 'login'})
      next()
    }
  },
]

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH || '/',
  linkActiveClass: "bg-gray-200",
  routes: [...defaultRoutes, ...plugins.routes],
  beforeEach: ((to, from, next) => {
    const user = store.getters['auth/user']
    //const isAuth = to.matched.some((record) => record.meta.requiresAuth)
    //const isHide = to.matched.some((record) => record.meta.hideForAuth)
    if (user === null) { // isAuth && 
      return next() //{ path: "/login" })
    } 
    // else if (isHide && user !== null) {
    //   return next({ path: "/" })
    // }
    next()
  })
})
