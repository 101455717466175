<template>
  <div class="placename_types">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.placename_types.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.ereditaculturali.views.placename_types.title')" 
          model="placename_types"
          model-name-field="code"
          :check-owner="false"
          :columns="[
            {name: $t('plugins.ereditaculturali.views.placename_types.fields.code'), field: 'code'},
            {name: $t('plugins.ereditaculturali.views.placename_types.fields.name'), field: 'name'},
            {name: $t('plugins.ereditaculturali.views.placename_types.fields.references'), field: 'references_count'},
            {field: 'actions'},
          ]"
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'PlacenameTypes',
  components: {
    BaseLayout, CrudTable
  }
}
</script>