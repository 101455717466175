<template>
  <div class="modal modal-entity">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-8">
      <h1 class="text-xl">{{ title }}</h1>
      <p class="my-5" v-html="text" />
      <div v-if="entityFields && canLoad">
        <div v-if="entity" class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
            <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in entityFields" :key="'field-'+index">
              <label :for="field.field" class="text-sm font-medium text-gray-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-gray-400">
                  <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
              <!-- Input Type TEXTEDITOR -->
              <TextEditor
                v-if="field.type === 'text-editor'"
                v-model="entity[field.field]"
                @input="change"
                :disable="disable"
                classes-text="bg-white" />
              <!-- Input Type TEXT -->
              <CrudInput
              v-if="field.type === 'text'"
                v-model="entity[field.field]"
                :field="field"
                :type="field.type"
                :disable="disable"
                @input="change"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline"  />
              <!-- Input Type STATIC SELECT -->
              <select v-if="field.type === 'static-select'" v-model="entity[field.field]" @change="change" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option disabled value="">{{ $t('global.select') }}</option>
                <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <!-- Input Type MULTI SELECT -->
              <VSelectMulti v-if="field.type === 'multi-select'" 
                v-model="entity[field.field]" 
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                :labels="field.labels"
                value-field="id"
                @select="change"
                :placeholder="$t('global.select')"
                class="mt-1 block w-full border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable" />
              <!-- Input Type SELECT (API) -->
              <VSelect v-if="field.type === 'select'"
                v-model="entity[field.field]"
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                value-field="id"
                @select="change"
                :placeholder="$t('global.select')"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable"></VSelect>
              <!-- Input Type MAP -->
              <div v-if="field.type === 'location'">
                <div v-if="!entity[field.field]">
                  <div>{{ $t('components.map.want_add_location') }}</div>
                  <button @click="addLocation(field, entity)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.add_location') }}</button>
                </div>
                <div v-else style="height: 400px; width: 100%" class=" mb-16">
                  <div class="grid grid-cols-2 gap-6">
                    <input v-model="entity[field.field].coordinates[0]" class="outline-none h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
                    <input v-model="entity[field.field].coordinates[1]" class="outline-none h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
                  </div>
                  <div class="bg-gray-200 px-2 py-1 mb-1 text-xs text-gray-600">{{ $t('components.map.tip_double_click') }}</div>
                  <l-map
                    ref="map"
                    v-if="showMap"
                    :zoom="zoom"
                    :center="coords(entity[field.field].coordinates)"
                    :options="mapOptions"
                    style="height: 80%"
                    @update:center="centerUpdate"
                    @update:zoom="zoomUpdate"
                    @dblclick="updateCoords($event, field, entity)"
                  >
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-control-layers :options="controlLayersOptions"/>
                    <l-wms-tile-layer
                      v-for="(layer, index) in wmsLayers"
                        :key="'wms-layer-'+index"
                        :base-url="layer.url"
                        :layers="layer.layer"
                        :visible="layer.visible"
                        :name="layer.name"
                        layer-type="base"
                        :format="layer.format"
                        :transparent="layer.transparent"
                        :attribution="layer.attribution"
                    />
                    <l-geosearch :options="geosearchOptions" />
                    <l-marker :lat-lng="coords(entity[field.field].coordinates)" :draggable="true" @move="updateCoords($event, field, entity)">
                      <l-popup>
                        <div>
                          {{ $t('components.map.place_coordinates') + ' ' + coords(entity[field.field].coordinates) }}
                          <p v-show="showParagraph">{{ $t('components.map.popup_default') }}</p>
                        </div>
                      </l-popup>
                    </l-marker>
                  </l-map>
                  <button @click="removeLocation(field, entity)" class="bg-red-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.remove_location') }}</button>
                </div>
              </div>
              <!-- Input Type GEOMETRY -->
              <div v-if="field.type === 'geometry'">
                <InputGeometry v-model="entity[field.field]" :field="field" :type="field.geometry" :mode="field.mode" :digitize-type="field.digitizeType" @updateGeometry="updateGeometry($event, entity)" :disable="disable" :controlLayersOptions="controlLayersOptions" :wmsLayers="wmsLayers" />
              </div>
            </div>
        </div>
        <div v-else class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
          <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in entityFields" :key="'field-'+index">
              <label :for="field.field" class="text-sm font-medium text-gray-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-gray-400">
                  <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
              <!-- Input Type TEXTEDITOR -->
              <TextEditor
                v-if="field.type === 'text-editor'"
                v-model="newEntity[field.field]"
                @input="change"
                :disable="disable"
                classes-text="bg-white" />
              <!-- Input Type TEXT -->
              <CrudInput
              v-if="field.type === 'text'"
                v-model="newEntity[field.field]"
                :field="field"
                :type="field.type"
                :disable="disable"
                @input="change"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline"  />
              <!-- Input Type STATIC SELECT -->
              <select v-if="field.type === 'static-select'" v-model="newEntity[field.field]" @change="change" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option disabled value="">{{ $t('global.select') }}</option>
                <option v-for="(option, index) in field.options" :key="`static-option-${index}`" :value="option.value">
                  {{ option.name }}
                </option>
              </select>
              <!-- Input Type MULTI SELECT -->
              <VSelectMulti v-if="field.type === 'multi-select'" 
                v-model="newEntity[field.field]" 
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                :labels="field.labels"
                value-field="id"
                :placeholder="$t('global.select')"
                class="mt-1 block w-full border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable" />
              <!-- Input Type SELECT (API) -->
              <VSelect v-if="field.type === 'select'"
                v-model="newEntity[field.field]"
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                :labels="field.labels"
                :value-field="field.valueField || 'id'"
                :placeholder="$t('global.select')"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable"></VSelect>
              <!-- Input Type MAP -->
              <div v-if="field.type === 'location'">
                <div v-if="!newEntity[field.field]">
                  <div>{{ $t('components.map.want_add_location') }}</div>
                  <button @click="addLocation(field, newEntity)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.add_location') }}</button>
                </div>
                <div v-else style="height: 400px; width: 100%" class=" mb-16">
                  <div class="grid grid-cols-2 gap-6">
                    <input v-model="newEntity[field.field].coordinates[0]" class="outline-none h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
                    <input v-model="newEntity[field.field].coordinates[1]" class="outline-none h-10 px-3 mb-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" type="text" />
                  </div>
                  <div class="bg-gray-200 px-2 py-1 mb-1 text-xs text-gray-600">{{ $t('components.map.tip_double_click') }}</div>
                  <l-map
                    ref="map"
                    v-if="showMap"
                    :zoom="zoom"
                    :center="coords(newEntity[field.field].coordinates)"
                    :options="mapOptions"
                    style="height: 80%"
                    @update:center="centerUpdate"
                    @update:zoom="zoomUpdate"
                    @dblclick="updateCoords($event, field, newEntity)"
                  >
                    <l-tile-layer :url="url" :attribution="attribution" />
                    <l-control-layers :options="controlLayersOptions"/>
                    <l-wms-tile-layer
                      v-for="(layer, index) in wmsLayers"
                        :key="'wms-layer-'+index"
                        :base-url="layer.url"
                        :layers="layer.layer"
                        :visible="layer.visible"
                        :name="layer.name"
                        layer-type="base"
                        :format="layer.format"
                        :transparent="layer.transparent"
                        :attribution="layer.attribution"
                    />
                    <l-geosearch :options="geosearchOptions" />
                    <l-marker :lat-lng="coords(newEntity[field.field].coordinates)" :draggable="true" @move="updateCoords($event, field, newEntity)">
                      <l-popup>
                        <div>
                          {{ $t('components.map.place_coordinates') + ' ' + coords(newEntity[field.field].coordinates) }}
                          <p v-show="showParagraph">{{ $t('components.map.popup_default') }}</p>
                        </div>
                      </l-popup>
                    </l-marker>
                  </l-map>
                  <button @click="removeLocation(field, newEntity)" class="bg-red-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.map.remove_location') }}</button>
                </div>
              </div>
              <!-- Input Type GEOMETRY -->
              <div v-if="field.type === 'geometry'">
                <InputGeometry v-model="newEntity[field.field]" :field="field" :type="field.geometry" :mode="field.mode" :digitize-type="field.digitizeType" @updateGeometry="updateGeometry($event, newEntity)" :disable="disable" :controlLayersOptions="controlLayersOptions" :wmsLayers="wmsLayers" />
              </div>
            </div>
        </div>
      </div>
      <div v-if="entityPivots && canLoad" class="bg-slate-200 rounded-md p-4">
        <div class="text-lg mb-4">{{ pivotTitle }}</div>
        <div v-if="entity" class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
            <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in entityPivots" :key="'field-'+index">
              <label :for="field.field" class="text-sm font-medium text-gray-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-gray-400">
                  <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
              <!-- Input Type TEXTEDITOR -->
              <TextEditor
                v-if="field.type === 'text-editor'"
                v-model="pivot[field.field]"
                @input="change"
                :disable="disable"
                classes-text="bg-white" />
              <!-- Input Type TEXT -->
              <CrudInput
              v-if="field.type === 'text'"
                v-model="pivot[field.field]"
                :field="field"
                :type="field.type"
                :disable="disable"
                @input="change"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline"  />
              <!-- Input Type SELECT (API) -->
              <VSelect v-if="field.type === 'select'"
                v-model="pivot[field.field]"
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                value-field="id"
                :placeholder="$t('global.select')"
                @select="change"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable"></VSelect>
              <!-- Input Type DATEPICKER -->
              <div v-if="field.type === 'date'" class="mt-1 block w-full py-2 px-3 border text-sm text-back border-gray-300 bg-white rounded-md shadow-sm focus:outline-none">
                <vue-date-picker v-model="pivot[field.field]" value-type="YYYY-MM-DD" format="YYYY-MM-DD" input-class="px-2 py-1 outline-none" popup-class="" />
              </div>
            </div>
        </div>
        <div v-else class="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
            <div :class="`col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-${field.span || '1'} xl:col-span-${field.span || '1'}`" v-for="(field, index) in entityPivots" :key="'field-'+index">
              <label :for="field.field" class="text-sm font-medium text-gray-700 flex gap-1">
                <div class="field-label">{{ field.name }}</div>
                <div v-if="field.tip" class="has-tooltip text-xs text-gray-400">
                  <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 -mt-8">{{ field.tip }}</span>
                  <f-icon icon="circle-question" />
                </div>
              </label>
              <!-- Input Type TEXTEDITOR -->
              <TextEditor
                v-if="field.type === 'text-editor'"
                v-model="newPivot[field.field]"
                @input="change"
                :disable="disable"
                classes-text="bg-white" />
              <!-- Input Type TEXT -->
              <CrudInput
              v-if="field.type === 'text'"
                v-model="newPivot[field.field]"
                :field="field"
                :type="field.type"
                :disable="disable"
                @input="change"
                classes="outline-none w-full h-10 px-3 mb-2 text-base text-gray-800 placeholder-gray-600 border rounded-md focus:shadow-outline"  />
              <!-- Input Type SELECT (API) -->
              <VSelect v-if="field.type === 'select'"
                v-model="newPivot[field.field]"
                :model="field.model"
                :search-field="field.label"
                :label="field.label"
                value-field="id"
                :placeholder="$t('global.select')"
                @select="change"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :disable="disable"></VSelect>
              <!-- Input Type DATEPICKER -->
              <div v-if="field.type === 'date'" class="mt-1 block w-full py-2 px-3 border text-sm text-back border-gray-300 bg-white rounded-md shadow-sm focus:outline-none">
                <vue-date-picker v-model="newPivot[field.field]" value-type="YYYY-MM-DD" format="YYYY-MM-DD" input-class="px-2 py-1 outline-none" popup-class="" />
              </div>
            </div>
        </div>
      </div>
      <div class="actions mt-5 flex flex-row justify-between">
        <div v-if="entity">
          <button :class="(changed ? 'bg-yellow-600 ' : 'bg-green-700 ') + 'hover:bg-blue-dark text-white font-bold py-2 px-4 rounded  mr-2'" @click="updateEntity"><f-icon icon="pen-to-square" /> {{ entityUpdateText }}</button>
          <button v-if="canDelete" class="bg-red-700 hover:bg-red-dark text-white font-bold py-2 px-4 rounded" @click="destroyPrompt"><f-icon icon="trash-can" /> {{ $t('actions.delete') }}</button>
        </div>
        <button v-else :class="(changed ? 'bg-yellow-600 ' : 'bg-blue-600 ') + 'hover:bg-blue-dark text-white font-bold py-2 px-4 rounded  mr-2'" @click="addEntity"><f-icon icon="floppy-disk" /> {{ entityAddText }}</button>  
        <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded mr-2" @click="closeModal">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import { latLng } from "leaflet";
import { OpenStreetMapProvider } from 'leaflet-geosearch';

import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import CrudInput from '@/components/CrudInput'
import InputGeometry from '@/components/InputGeometry'
import TextEditor from '@/components/TextEditor'
import ModalDelete from '@/components/ModalDelete'

export default {
  name: 'ModalEntity',
  components: {
    LoadingSpinner, CrudInput, TextEditor, InputGeometry
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    importText: {
      type: String,
      default: 'Import'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String
    },
    fileTypesAllowed: {
      type: Array
    },
    update: {},
    archiveText: {
      type: String
    },
    id: {
      type: Number,
      default: null
    },
    entityFields: {
      type: Array,
      default: () => [] 
    },
    entityPivots: {
      type: Array,
      default: () => [] 
    },
    pivotTitle: {
      type: String,
      default: 'Pivot fields'
    },
    disable: {
      type: Boolean,
      default: false
    },
    entityUpdateText: {
      type: String,
      default: 'Update'
    },
    entityAddText: {
      type: String,
      default: 'Save'
    },
    relatedId: {
      type: [String, Number],
      default: null
    },
    relatedModel: {
      type: String,
      default: null
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    controlLayersOptions: {
      type: Object
    },
    wmsLayers: {
      type: Array
    },
  },
  data: function(){
    return {
      showError: false,
      errors: [],
      entity: null,
      pivot: null,
      newEntity: {},
      newPivot: {},
      changed: false,
      archive: null,
      isLoading: false,
      canLoad: false,
      zoom: 15,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false
      },
      tileOptions: {
        maxZoom: 18, // maxZoom: testCRS.options.resolutions.length,
        maxNativeZoom: 18,
        minZoom: 0,
        continuousWorld: true,
        worldCopyJump: false
      },
      showMap: true,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        searchLabel: 'Search...',
        autoClose: true
      },
    }
  },
  created(){
    this.url = process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    this.attribution = process.env.VUE_APP_MAP_ATTRIBUTION || '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    this.tileOptions.minZoom = process.env.VUE_APP_MAP_MIN_ZOOM || 1
    this.tileOptions.maxZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    this.tileOptions.maxNativeZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
  },
  mounted(){
    const vm = this
    vm.isLoading = true
    vm.changed = false
    if(vm.id){
      vm.fetchEntity()
    }else{
      // setup inizial new related fields empty
      vm.newEntity = {
        id: null
      }
      for(var i = 0; i < vm.entityFields.length; i++) {
        vm.$set(vm.newEntity, vm.entityFields[i].field, null)
      }
      if(vm.entityPivots) {
        vm.newPivot = {}
        for(var j = 0; j < vm.entityPivots.length; j++) {
          vm.$set(vm.newPivot, vm.entityPivots[j].field, null)
        }
      }
      vm.canLoad = true
      vm.isLoading = false
    }
  },
  methods: {
    async fetchEntity(){
      const vm = this
      vm.isLoading = true
      return await api.fetch(`${vm.model}/${vm.id}`).then(response => {
        vm.entity = response
        if(vm.entityPivots) {
          let relateds = response[vm.relatedModel].filter(el => {
            return el.id === parseInt(vm.relatedId)
          })
          vm.pivot = {}
          for(var j = 0; j < vm.entityPivots.length; j++) {
            vm.$set(vm.pivot, vm.entityPivots[j].field, relateds.length > 0 ? relateds[0].pivot[vm.entityPivots[j].field] : null)
          }
        }
        vm.canLoad = true
        vm.isLoading = false 
      })
    },
    async addEntity(){
      const vm = this
      vm.isLoading = true
      vm.newEntity.pivot = vm.newPivot
      await api.create(`${vm.relatedModel}/${vm.relatedId}/${vm.model}`, vm.newEntity).then((response)=>{
        vm.isLoading = false
        if(response.success){
          vm.update('reload')
          vm.closeModal()
        }else{
          for(let error in response.errors){
            vm.errors[error] = response.errors[error]
            vm.$toasted.error(response.errors[error])
          }
        }
      })
    },
    async updateEntity(){
      const vm = this
      vm.isLoading = true
      vm.entity.pivot = vm.pivot
      await api.update(vm.relatedModel, vm.relatedId, vm.entity, vm.model, vm.entity.id).then((response) => {
        vm.isLoading = false
        if(response.success){
          vm.update('reload')
          vm.closeModal()
        }else{
          for(let error in response.errors){
            vm.errors[error] = response.errors[error]
            vm.$toasted.error(response.errors[error])
          }
        }
      })
    },
    async destroyPrompt(){
      // TODO: fix per lo splice del related eliminato, se è primo di tutti gli altri prendono il valore della select di quello eliminato
      const vm = this
      vm.$modal.show(ModalDelete,
        {
          title: vm.$i18n.t('modals.delete.title'),
          text: vm.$i18n.t('modals.delete.content'),
          deleteText: vm.$i18n.t('actions.delete'),
          entity: vm.entity,
          model: vm.model,
          update: () => {
            vm.update('reload')
            vm.closeModal()
          }
        },{
          height: 'auto'
        }
      )
    },
    closeModal(){
      this.$emit('close')
    },
    coords(coordinates){
      return latLng(coordinates[1], coordinates[0])
    },
    updateCoords(event, field, entity = null){
      if(field){
        this.$set(entity ? entity[field.field] : this.newentity[field.field], 'coordinates', [event.latlng.lng, event.latlng.lat])
      }
    },
    defaultCoords(){
      return (process.env.VUE_APP_BASE_LAT && process.env.VUE_APP_BASE_LNG) 
        ? [parseFloat(process.env.VUE_APP_BASE_LAT), parseFloat(process.env.VUE_APP_BASE_LNG)]
        : [parseFloat(12.1234), parseFloat(44.1234)]
    },
    onSearch(value){
      // geosearch/showlocation
      console.log('onSearch', value)
    },
    updateGeometry(data, entity = null){
      const vm = this
      var field_ref = entity ? entity : vm.newentity
      if(data){
        // to setup empty geometry
        if(data.action === 'create'){
          let coords_data = null
          switch(data.type){
            // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
            case 'polygon':
            case 'polygonz':
            case 'linestringz':
              coords_data = {coordinates: [data.coordinates], type: data.type}
              break
            case 'point':
            case 'pointz':
            case 'multipointz':
              coords_data = {coordinates: data.coordinates, type: data.type}
              break
          }
          vm.$set(field_ref, data.geometry, coords_data)
        }
        // to remove a geometry
        if(data.action === 'remove'){
          //console.log('[remove geometry]', data.index, field_ref[data.geometry])
          if(data.index) { // to remove just one PointZ from MultiPointZ
            switch(data.type){
              // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
              case 'polygon':
              case 'polygonz':
                vm.$delete(field_ref[data.geometry]['coordinates'][0], data.index)
                break
              case 'multipointz':
              case 'linestringz':
              default:
                vm.$delete(field_ref[data.geometry]['coordinates'], data.index)
                break
            }
          }else{ // for others geometries
            vm.$set(field_ref, data.geometry, null)
          }
        }
        // to add a point to a geometry (e.g.: MultiPointZ)
        if(data.action === 'add'){
          //console.log('[add geometry]', data.index, field_ref[data.geometry]['coordinates'])
          switch(data.type){
            // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
            case 'polygon':
            case 'polygonz':
            case 'multipointz':
              vm.$set(field_ref[data.geometry]['coordinates'][0], data.index, data.coordinates)
              break
            case 'linestringz':
            default:
              vm.$set(field_ref[data.geometry]['coordinates'], data.index, data.coordinates)
              break;
          }
        }
      }
    },
    removeLocation(field, entity = null){
      this.$set(entity ? entity : this.newentity, field.field, null)
    },
    addLocation(field, entity = null){
      this.$set(entity ? entity : this.newentity, field.field, {coordinates: this.defaultCoords(), type:'Point'})
      // this.$nextTick(()=>{
      //     this.$refs.map.mapObject.on("geosearch/showlocation", this.onSearch);
      // })
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    change(){
      this.changed = true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
