<template>
  <div :class="'table-field '+ (column.classes || '')">
    <!-- if entity has multiple related/parents -->
    <div v-if="column.parent && Array.isArray(entity[column.parent])" class="flex flex-wrap">
      <span v-for="(parent, index) in entity[column.parent]" :key="'parent-'+index" class="flex">
        <!-- if related/parent is user role: get translation of role -->
        <span v-if="column.parent === 'roles'" class="bg-green-600 py-1 px-2 text-xs text-white rounded-md m-1 ">
          {{ $t('roles.'+ (parent[column.field]) ) }}
        </span>
        <span v-else-if="column.parent === 'tenants'" class="bg-slate-200 py-1 px-2 text-xs rounded-md m-1 ">
          {{ parent[column.field] }}
        </span>
        <!-- is a normal related/parent -->
        <span v-else class="">
          <router-link v-if="column.model" :to="`/${column.model}/edit/${parent.id}`" :class="'bg-blue-500 py-1 px-2 text-xs text-white rounded-md m-2'">{{ parent[column.field] }}
          </router-link>
          <span v-else>{{ parent[column.field] }}</span>
        </span>
      </span>
    </div>
    <!-- if entity has one related/parent -->
    <span v-else-if="column.parent && !Array.isArray(entity[column.parent])">
      <span v-if="entity[column.parent]" class="">
          <router-link v-if="column.model" :to="`/${column.model}/edit/${entity[column.parent].id}`" :class="'bg-blue-500 py-1 px-2 text-xs text-white rounded-md mr-1'">{{ entity[column.parent][column.field] }}
          </router-link>
          <span v-else>{{ entity[column.parent][column.field] }}</span>
        </span>
    </span>
    <!-- standard field -->
    <span v-else>
      <!-- if is "check" view -->
      <span v-if="column.view && column.view === 'check'">
        <f-icon v-if="entity[column.field]" icon="check" />
      </span>
      <!-- if is "color" view -->
      <span v-else-if="column.view && column.view === 'color'">
        <div class="rounded-full w-5 h-5" :style="'background-color: '+entity[column.field]+';'"></div>
      </span>
      <!-- if is "color" view -->
      <span v-else-if="column.view && column.view === 'multiple'">
        <div v-for="(field, index) in column.field" :key="'multiple-field-'+field+'-'+index">
          <span v-if="entity[field]" class="rounded-md bg-green-600 mr-2 text-xs text-white py-1 px-2 whitespace-no-wrap"><f-icon icon="vector-square" /> {{ field }}</span>
        </div>
      </span>
      <!-- if is "image" view -->
      <span v-else-if="column.view && column.view === 'image'" class="flex flex-row">
        <img v-if="entity.is_image" class="h-150 bg-gray-200 rounded-md" :src="entity.thumb['300'].file" />
        <div v-else class="flex flex-row">
          <div class=" border-r border-gray-400 rounded-l-md bg-gray-200 p-2 px-3 flex-row flex items-center">
            <f-icon class="text-lg" :icon="icon(entity)" />
          </div>
          <div class="bg-gray-200 rounded-r-md p-3 flex flex-row items-center">
            <span class="break-words w-20">{{ entity.file }}</span> <!--<span class="text-xs text-green-800">{{ extension(entity) }}</span>-->
          </div>
        </div>
      </span>
      <!-- if is "link" view -->
      <span v-else-if="column.view && column.view === 'link' && entity.fileable" class="flex flex-row">
        <router-link :to="entity.fileable.cardable ? `/${entity.fileable.cardable.slug}/edit/${entity.fileable.cardable.id}` : `/${entity.fileable.slug}/edit/${entity.fileable.id}`" class="border-4 border-opacity-0 py-1 px-2 font-bold uppercase text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 border-blue-500 bg-blue-500 hover:text-blue-500 flex flex-row item-center gap-2">
          <f-icon class="text-lg" icon="file" /> {{ entity.fileable[column.field] }}
        </router-link>
      </span>
      <!-- if is "image" view -->
      <span v-else-if="column.view && column.view === 'thumb'" class="flex flex-row">
        <img v-if="thumb(entity)" :src="thumb(entity)" alt="entity thumb" class="w-16 rounded-sm">
      </span>
      <!-- else is a normal field -->
      <span v-else v-html="['created_at','updated_at'].includes(column.field) ? datetime(entity[column.field]) : entity[column.field]" />
    </span>
  </div>
</template>
<script>
  export default {
    name: 'CrudTableField',
    props: {
      column: {
        type: Object,
        required: true
      },
      entity: {
        required: true,
      },
      classes: {
        default: null
      },
    },
    methods: {
      extension(file){
        let re = /(?:\.([^.]+))?$/
        return re.exec(file.file)[1]
      },
      icon(file){
        let ext = this.extension(file)
        switch(ext){
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'tif':
            return 'image'
          case 'obj':   
            return 'cube'
          case 'xls':
          case 'xlsx':
          case 'csv':
            return 'file-excel'
          default:      
            return 'file'
        }
      },
      datetime(datetime){
        if(!datetime) return '-'
        let format = new Date(datetime)
        return format.getDate()+'/' + (format.getMonth()+1) + '/'+format.getFullYear()+' - '+ ((format.getHours() < 10 ? '0' : '') + format.getHours()) +':'+ ((format.getMinutes() < 10 ? '0' : '') + format.getMinutes())
      },
      thumb(entity){
        let thumb = null
        if(entity.files && entity.files.length > 0){
          entity.files.forEach(file => {
            if(file.is_image && (thumb === null || file.is_cover)) thumb = file.thumb[300].file
          })
        }
        return thumb
      }
    }
  }
</script>