<template>
  <div class="placenames">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.placenames.single') }}
      </template>
      <template v-slot:main>
        <CrudEntity v-if="canLoad"
          :model="model" :id="id" :mode="crud_mode" :entity="entity" :is-extended-card="true" extends="card" :has-location="true" :check-owner="true"
          :fields="[
            // Base Card fields
            {text: $t('views.cards.fields.card_separator'), type: 'separator'},
            {name: $t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
            {name: $t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
            {name: $t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', tip: $t('plugins.ereditaculturali.tips.is_visible')},
            {name: $t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: $t('plugins.ereditaculturali.tips.is_published')},
            {name: $t('plugins.ereditaculturali.views.placenames.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
            {name: $t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: select_user },
            {name: $t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},
            {name: $t('plugins.ereditaculturali.views.placenames.fields.cards'), field: 'parents', model: 'cards', related: 'card', type: 'multi-select', span: '2', label: 'name'},
            {name: $t('views.cards.fields.references'), field: 'references', related: 'card', model: 'references', type: 'multi-select', label: 'citation', labels:['citation'], taggable: true, span: '2'},
            {name: $t('views.cards.fields.reference_sources'), field: 'reference_sources', related: 'card', model: 'reference_sources', type: 'multi-select', label: 'name', taggable: true, span: '2'},
            {name: $t('plugins.ereditaculturali.views.placenames.fields.type'), field: 'type_id', type: 'select', model: 'placename_types', label: 'name' },
            {name: $t('plugins.ereditaculturali.views.placenames.fields.region'), field: 'region', type: 'text'},
            {name: $t('plugins.ereditaculturali.views.placenames.fields.literal_transcript'), field: 'literal_transcript', type: 'text'},
            {name: $t('plugins.ereditaculturali.views.placenames.fields.latin_transcript'), field: 'latin_transcript', type: 'text'},
            {name: $t('plugins.ereditaculturali.views.placenames.fields.notes'), field: 'notes', type: 'text-editor', span: '2'},
          ]"
        />
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudLoad from '@/components/CrudLoad'
import CrudEntity from '@/components/CrudEntity'

export default {
  name: 'Placename',
  mixins: [
    CrudLoad
  ],
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      model: 'placenames',
    }
  },
  components: {
    BaseLayout, CrudEntity
  },
  async beforeMount(){
    this.load(this.mode, this.$route.params.id)
  }
}
</script>