var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"observations"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.rometransformed.views.observations.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"card","has-location":true,"check-owner":true,"fields":[
          // Main fields
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.code'), 
            field: 'code', type: 'text'},
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.name'), 
            field: 'name', type: 'text'},
          // Base Card fields
          {name: _vm.$t('views.cards.fields.is_visible'),     
            field: 'is_visible', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.rometransformed.views.observations.tips.is_visible')},
          {name: _vm.$t('views.cards.fields.is_published'),   
            field: 'is_published', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.rometransformed.views.observations.tips.is_published')},
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.archive'), 
            field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('views.cards.fields.user_id'), 
            field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: _vm.select_user },
          {name: _vm.$t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.description'), field: 'description', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.quota'), field: 'quota', type: 'text'},

          // Location fields
          {text: _vm.$t('plugins.rometransformed.views.observations.fields.location_separator'), type: 'separator'},
          {name: _vm.$t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
            fields: [ 
              {name: _vm.$t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
              {name: _vm.$t('plugins.rometransformed.views.observations.fields.x'), field: 'x', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.observations.fields.y'), field: 'y', type: 'text'},
              {name: _vm.$t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry',      geometry: 'pointz', span: '4'},
            ],
            pivots: [ 
              {name: _vm.$t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
              {name: _vm.$t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
            ],
            pivotTitle: _vm.$t('plugins.rometransformed.views.observations.locations.pivot'),
            wmsLayers: []
          },


          // {text: $t('plugins.rometransformed.views.observations.fields.location_separator'), type: 'separator'},
          // {name: $t('plugins.rometransformed.views.observations.fields.x'), 
          //   field: 'x', type: 'text'},
          // {name: $t('plugins.rometransformed.views.observations.fields.y'), 
          //   field: 'y', type: 'text'},
          // {name: $t('plugins.rometransformed.views.observations.fields.quota'), 
          //   field: 'quota', type: 'text'},
          // {name: $t('views.locations.fields.name'),         related: 'card', subrelated: 'location', field: 'name', type: 'text', span: '1'},
          // {name: $t('views.locations.fields.address'),      related: 'card', subrelated: 'location', field: 'address', type: 'text', span: '1'},
          // {name: $t('views.locations.fields.accuracy'),     related: 'card', subrelated: 'location', field: 'accuracy', type: 'text', span: '1'},
          // {name: $t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry',      geometry: 'pointz', span: '4'},
        ]}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }