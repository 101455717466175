<template>
  <div :class="classes">
    <div v-if="value && value.coordinates">
      <!-- PointZ -->
      <div v-if="type === 'pointz'" class="flex flex-row items-center">
        <div v-if="mode === 'map'" class="flex flex-col items-start">
          <div class="has-tooltip text-xs text-gray-400">
            <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mb-10">{{ $t('components.geometry.digitize') }}</span>
            <f-icon icon="circle-question" />
          </div>
          <div style="height: 450px; width: 100%" class="mb-2">
            <l-map
              ref="polygonMap"
              no-blocking-animations
              :zoom="zoom"
              :center="defaultCoords()"
              :options="mapOptions"
              style="height: 100%"
              >
              <l-tile-layer :url="url" :attribution="attribution" :options="tileOptions" />
              <l-control-layers :options="controlLayersOptions"/>
              <l-wms-tile-layer
                v-for="(layer, index) in wmsLayers"
                  :key="'wms-layer-'+index"
                  :base-url="layer.url"
                  :layers="layer.layer"
                  :visible="layer.visible"
                  :name="layer.name"
                  layer-type="base"
                  :format="layer.format"
                  :transparent="layer.transparent"
                  :attribution="layer.attribution"
              />
            </l-map>
          </div>
        <!--</div>
        <div v-else>-->
          <PointZ v-model="value.coordinates" :disable="disable" @input="prepareDrawing($event,true)" />
          <button @click="remove(null)" class="bg-red-600 px-1 text-xs text-white rounded ml-1">{{ $t('components.geometry.remove') }}</button>
        </div>
        <div v-else>
          <PointZ v-model="value.coordinates" :disable="disable" />
          <button @click="remove(null)" class="bg-red-600 px-1 text-xs text-white rounded ml-1">{{ $t('components.geometry.remove') }}</button>
        </div>
      </div>
      <!-- MultiPointZ -->
      <div v-if="type === 'multipointz'">
        <div v-for="(point, index) in value.coordinates" :key="'multipoint-'+index" class="flex flex-row items-center">
          <PointZ v-model="value.coordinates[index]" :disable="disable" />
          <button @click="remove(index)" class="bg-red-600 px-1 text-xs text-white rounded ml-1">{{ $t('components.geometry.remove') }}</button>
        </div>
        <button @click="add(type)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.geometry.add_point') }}</button>
      </div>
      <!-- PolygonZ -->
      <div v-if="type === 'polygonz'">
        <div v-if="mode === 'map'">
          <div class="has-tooltip text-xs text-gray-400">
            <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mb-10">{{ $t('components.geometry.digitize') }}</span>
            <f-icon icon="circle-question" />
          </div>
          <div style="height: 450px; width: 100%">
            <l-map
              ref="polygonMap"
              no-blocking-animations
              :zoom="zoom"
              :center="defaultCoords()"
              :options="mapOptions"
              style="height: 100%"
              >
              <l-tile-layer :url="url" :attribution="attribution" :options="tileOptions" />
              <l-control-layers :options="controlLayersOptions"/>
              <l-wms-tile-layer
                v-for="(layer, index) in wmsLayers"
                  :key="'wms-layer-'+index"
                  :base-url="layer.url"
                  :layers="layer.layer"
                  :visible="layer.visible"
                  :name="layer.name"
                  layer-type="base"
                  :format="layer.format"
                  :transparent="layer.transparent"
                  :attribution="layer.attribution"
              />
            </l-map>
          </div>
          <div v-if="centroid" class="pt-2">{{ $t('components.geometry.centroid') }} <span class="rounded-md bg-gray-300 px-2 py-1">{{ centroid.lng }}, {{ centroid.lat }} </span></div>
        </div>
        <div v-else>
          <div v-for="(point, index) in value.coordinates[0]" :key="'polygonz-'+index" class="flex flex-row items-center">
            <PointZ v-model="value.coordinates[0][index]" :disable="disable" />
            <button @click="remove(index)" class="bg-red-600 px-1 text-xs text-white rounded ml-1">{{ $t('components.geometry.remove') }}</button>
          </div>
          <button @click="add(type)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.geometry.add_point') }}</button>
          <div>{{ $t('components.geometry.last_point') }}</div>
        </div>
      </div>
      <!-- Polygon -->
      <div v-if="type === 'polygon'">
        <div v-if="mode === 'map'">
          <div class="has-tooltip text-xs text-gray-400">
            <span class="tooltip rounded shadow-lg p-1 bg-gray-100 text-gray-500 mb-10">{{ $t('components.geometry.digitize') }}</span>
            <f-icon icon="circle-question" />
          </div>
          <div style="height: 450px; width: 100%">
            <l-map
              ref="polygonMap"
              no-blocking-animations
              :zoom="zoom"
              maxNativeZoom="20"
              :center="defaultCoords()"
              :options="mapOptions"
              style="height: 100%"
              >
              <l-tile-layer :url="url" :attribution="attribution" :options="tileOptions" />
            </l-map>
          </div>
        </div>
        <div v-else>
          <div v-for="(point, index) in value.coordinates[0]" :key="'polygon-'+index" class="flex flex-row items-center">
            <Point v-model="value.coordinates[0][index]" :disable="disable" />
            <button @click="remove(index)" class="bg-red-600 px-1 text-xs text-white rounded ml-1">{{ $t('components.geometry.remove') }}</button>
          </div>
          <button @click="add(type)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.geometry.add_point') }}</button>
          <div>{{ $t('components.geometry.last_point') }}</div>
        </div>
      </div>
      <!-- LineStringZ -->
      <div v-if="type === 'linestringz'">
        <div v-for="(point, index) in value.coordinates" :key="'linestringz-'+index" class="flex flex-row items-center">
          <PointZ v-model="value.coordinates[index]" :disable="disable" />
          <button @click="remove(index)" class="bg-red-600 px-1 text-xs text-white rounded ml-1">{{ $t('components.geometry.remove') }}</button>
        </div>
        <button @click="add(type)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.geometry.add_point') }}</button>
      </div>
    </div>
    <div v-else>
      <div>{{ $t('components.geometry.want_create', {type: type} ) }}</div>
      <button @click="create(type)" class="bg-green-600 px-1 text-xs text-white rounded mt-1">{{ $t('components.geometry.create') }}</button>
    </div>
  </div>
</template>
<script>

import 'leaflet-draw'
//import 'leaflet-toolbar'
import 'leaflet-draw/dist/leaflet.draw.css';

import PointZ from '@/components/PointZ'
import Point from '@/components/Point'

export default {
  name: 'InputGeometry',
  components: {
    PointZ, Point
  },
  props: {
    value: {
      required: true,
    },
    disable: {
      default: false
    },
    classes: {
      default: null
    },
    field: {
      default: null,
      required: true
    },
    type: {
      default: 'pointz',
      required: true
    },
    mode: {
      default: 'field', // 'map'
    },
    digitizeType: {
      default: 'polygon'
    },
    controlLayersOptions: {
      type: Object
    },
    wmsLayers: {
      type: Array
    },
  },
  data() {
    return {
      disabled: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false
      },
      currentZoom: 11.5,
      editableLayers: null,
      drawControl: null,
      editActions: [],
      editMode: false,
      drawing: {},
      map: {},
      centroid: null,
      tileOptions: {
        maxZoom: 18, // maxZoom: testCRS.options.resolutions.length,
        maxNativeZoom: 18,
        minZoom: 0,
        continuousWorld: true,
        worldCopyJump: false
      },
    }
  },
  created(){

    this.editableLayers = new window.L.FeatureGroup()

    this.url = process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    this.attribution = process.env.VUE_APP_MAP_ATTRIBUTION || '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    this.tileOptions.minZoom = process.env.VUE_APP_MAP_MIN_ZOOM || 1
    this.tileOptions.maxZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    this.tileOptions.maxNativeZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18

    let digitizeOptions = {
      position: 'topright',
      draw: {
        polyline: false,
        polygon: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: this.editableLayers,
        remove: true,
        edit: {
          // Set color and fill for edited element
          selectedPathOptions: {
            color: '#EE4400',
            fillColor: '#553300',
            weight: 1
          },
        },
      },
    }

    if(this.digitizeType === 'polygon') {
      digitizeOptions.draw.polygon = {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: 'orange',
          timeout: 2000,
          message: '<strong>Error<strong>',
        },
        showArea: true,
        metric: true, //m2
        repeatMode: false,
        shapeOptions: {
          polylineID: false,
          color: '#2196F3',
          fillColor: '#2196F3',
          weight: 2,
          fillOpacity: 0.4,
          lineCap: 'round',
          lineJoin: 'bevel',
          dashArray: '',
          opacity: 1,
        },
      }
      digitizeOptions.draw.rectangle = {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: 'orange',
          timeout: 2000,
          message: '<strong>Error<strong>',
        },
        showArea: true,
        metric: true, //m2
        repeatMode: false,
        shapeOptions: {
          polylineID: false,
          color: '#5196F3',
          fillColor: '#5196F3',
          weight: 2,
          fillOpacity: 0.4,
          lineCap: 'round',
          lineJoin: 'bevel',
          dashArray: '',
          opacity: 1,
        },
      }

    }
    
    if(this.digitizeType === 'marker') digitizeOptions.draw.marker = {}
      
    // Create leaflet draw control menu
    this.drawControl = new window.L.Control.Draw(digitizeOptions)
  },
  mounted(){
    this.initMap()
  },
  methods: {
    create(type){
      let coords = null
      switch(type){
        // TODO aggiungere le altre geometrie necessarie (sia qui che sul template)
        case 'point':         coords = [0,0]; this.initMap(); break;
        case 'pointz':        coords = [0,0,0]; this.initMap(); break;
        case 'multipointz':   coords = [[0,0,0],[0,0,0]]; break;
        case 'linestringz':   coords = [0,0,0]; break;
        case 'polygonz':      coords = this.mode === 'map' ? null : [[0,0,0],[0,0,0],[0,0,0],[0,0,0]]; this.initMap(); break;
        case 'polygon':       coords = this.mode === 'map' ? null : [[0,0],[0,0],[0,0],[0,0]]; this.initMap(); break;
      }
      this.$emit('updateGeometry',{action: 'create', geometry: this.field.field, coordinates: coords, type: type, field: this.field})
    },
    remove(index = null){
      this.$emit('updateGeometry',{action: 'remove', geometry: this.field.field, index: index, field: this.field})
    },
    add(type){
      let coords = !['polygon','point'].includes(type) ? [0,0,0] : [0,0] 
      this.$emit('updateGeometry',{action: 'add', geometry: this.field.field, coordinates: coords, type: type, index: !['polygon','point'].includes(type) ? this.value.coordinates.length : this.value.coordinates[0].length, field: this.field })
    },
    defaultCoords(){
      return (process.env.VUE_APP_BASE_LAT && process.env.VUE_APP_BASE_LNG) 
        ? [parseFloat(process.env.VUE_APP_BASE_LNG), parseFloat(process.env.VUE_APP_BASE_LAT)]
        : [parseFloat(12.1234), parseFloat(44.1234)]
    },
    initMap(){
      this.$nextTick(() => {
        this.$nextTick(() => {
          const vm = this
          if(vm.$refs.polygonMap){
            vm.map = vm.$refs.polygonMap.mapObject
            vm.editableLayers.addTo(vm.map)
            if(!vm.disable) vm.map.addControl(vm.drawControl)
            
            vm.prepareDrawing() // for existing geometry

            // for new geometry
            vm.map.on('draw:created', function (e) {
              vm.updateDrawing(e.layer)
              vm.editableLayers.eachLayer(function (layer) {
                vm.editableLayers.removeLayer(layer)
              })
              vm.editableLayers.addLayer(vm.drawing)
            })
            vm.map.on('draw:edited', function(e){
              e.layers.eachLayer(function (layer) {
                vm.updateDrawing(layer)
              })
            })
            vm.map.on('draw:deleted', function(){
              vm.remove()
            })
          } 
        })
      })
    },
    prepareDrawing(update = false){
      const vm = this
      if(vm.value.coordinates.length > 0 && vm.value.coordinates[0]){ 
        let coords = []
        let geometry = null
        
        // Settings for geometry types
        if(['polygon'].includes(vm.type)) {
          vm.value.coordinates[0].forEach(element => {
            coords.push([element[1],element[0]])
          })
          geometry = new window.L.Polygon(coords)
        }
        if(['polygonz'].includes(vm.type)) {
          vm.value.coordinates[0].forEach(element => {
            coords.push([element[1],element[0]])
          })
          geometry = new window.L.Polygon(coords)
          vm.centroid = geometry.getBounds().getCenter()
        }
        if(['pointz'].includes(vm.type)) {
          geometry = new window.L.Marker([vm.value.coordinates[1],vm.value.coordinates[0]])
        }
        // TODO aggiungere le altre geometrie necessarie
        if(update) vm.editableLayers.eachLayer(function (layer) {
          vm.editableLayers.removeLayer(layer)
        })
        // vm.map.addLayer(geometry)
        vm.updateDrawing(geometry)
        vm.editableLayers.addLayer(vm.drawing)

        if(!['pointz','marker'].includes(vm.type)){
          vm.map.fitBounds(vm.drawing.getBounds())
        }else{
          vm.map.panTo(vm.drawing.getLatLng())
        }
      }
    },
    updateDrawing(layer = null){
      const vm = this
      let coords = []
      if(layer) vm.drawing = layer
      // Settings for geometry types
      if(['polygon'].includes(vm.type)) {
        vm.drawing.editing.latlngs[0][0].forEach(element => {
          coords.push([element.lng,element.lat])
        });
        coords.push([vm.drawing.editing.latlngs[0][0][0].lng,vm.drawing.editing.latlngs[0][0][0].lat])
      }
      if(['polygonz'].includes(vm.type)) {
        vm.centroid = vm.drawing.getBounds().getCenter()

        // If drawed a polygon
        if(vm.drawing.editing._poly){
          vm.drawing.editing.latlngs[0][0].forEach(element => {
            coords.push([element.lng,element.lat,0])
          });
          coords.push([vm.drawing.editing.latlngs[0][0][0].lng,vm.drawing.editing.latlngs[0][0][0].lat,0])
        
        // If drawed a rectangle
        }else if(vm.drawing.editing._shape){
          vm.drawing.editing._shape._latlngs[0].forEach(element => {
            coords.push([element.lng,element.lat,0])
          });
          coords.push([vm.drawing.editing._shape._latlngs[0][0].lng,vm.drawing.editing._shape._latlngs[0][0].lat,0])
        }

      }
      if(['pointz'].includes(vm.type)) {
        coords = [vm.drawing.editing._marker._latlng.lng,vm.drawing.editing._marker._latlng.lat,0]
      }
      // TODO aggiungere le altre geometrie necessarie
      vm.$emit('updateGeometry',{action: 'create', geometry: vm.field.field, coordinates: coords, type: vm.type, index: coords.length, field: vm.field, centroid: vm.centroid })
    }
  }
}
</script>
<style>
.leaflet-marker-icon.leaflet-div-icon.leaflet-editing-icon.leaflet-touch-icon.leaflet-zoom-animated.leaflet-interactive {
  width: 10px !important;
  height: 10px !important;
  margin-left: -5px !important;
  margin-top: -5px !important;
  border-radius: 10px;
}
</style>