<template>
  <div class="observations">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.observations.single') }}
      </template>
      <template v-slot:main>
        <CrudEntity v-if="canLoad"
          :model="model" :id="id" :mode="crud_mode" :entity="entity" :is-extended-card="true" extends="card" :has-location="true" :check-owner="true"
          :fields="[
            // Main fields
            {name: $t('plugins.rometransformed.views.observations.fields.code'), 
              field: 'code', type: 'text'},
            {name: $t('plugins.rometransformed.views.observations.fields.name'), 
              field: 'name', type: 'text'},
            // Base Card fields
            {name: $t('views.cards.fields.is_visible'),     
              field: 'is_visible', related: 'card', type: 'checkbox', tip: $t('plugins.rometransformed.views.observations.tips.is_visible')},
            {name: $t('views.cards.fields.is_published'),   
              field: 'is_published', related: 'card', type: 'checkbox', tip: $t('plugins.rometransformed.views.observations.tips.is_published')},
            {name: $t('plugins.rometransformed.views.observations.fields.archive'), 
              field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
            {name: $t('views.cards.fields.user_id'), 
              field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: select_user },
            {name: $t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},
            {name: $t('plugins.rometransformed.views.observations.fields.description'), field: 'description', type: 'text-editor', span: '2'},
            {name: $t('plugins.rometransformed.views.observations.fields.quota'), field: 'quota', type: 'text'},

            // Location fields
            {text: $t('plugins.rometransformed.views.observations.fields.location_separator'), type: 'separator'},
            {name: $t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
              fields: [ 
                {name: $t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
                {name: $t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
                {name: $t('plugins.rometransformed.views.observations.fields.x'), field: 'x', type: 'text'},
                {name: $t('plugins.rometransformed.views.observations.fields.y'), field: 'y', type: 'text'},
                {name: $t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
                {name: $t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry',      geometry: 'pointz', span: '4'},
              ],
              pivots: [ 
                {name: $t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
                {name: $t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
              ],
              pivotTitle: $t('plugins.rometransformed.views.observations.locations.pivot'),
              wmsLayers: []
            },


            // {text: $t('plugins.rometransformed.views.observations.fields.location_separator'), type: 'separator'},
            // {name: $t('plugins.rometransformed.views.observations.fields.x'), 
            //   field: 'x', type: 'text'},
            // {name: $t('plugins.rometransformed.views.observations.fields.y'), 
            //   field: 'y', type: 'text'},
            // {name: $t('plugins.rometransformed.views.observations.fields.quota'), 
            //   field: 'quota', type: 'text'},
            // {name: $t('views.locations.fields.name'),         related: 'card', subrelated: 'location', field: 'name', type: 'text', span: '1'},
            // {name: $t('views.locations.fields.address'),      related: 'card', subrelated: 'location', field: 'address', type: 'text', span: '1'},
            // {name: $t('views.locations.fields.accuracy'),     related: 'card', subrelated: 'location', field: 'accuracy', type: 'text', span: '1'},
            // {name: $t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry',      geometry: 'pointz', span: '4'},
          ]"
        />
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudLoad from '@/components/CrudLoad'
import CrudEntity from '@/components/CrudEntity'

export default {
  name: 'Observation',
  mixins: [
    CrudLoad
  ],
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      model: 'observations',
    }
  },
  components: {
    BaseLayout, CrudEntity
  },
  async beforeMount(){
    this.load(this.mode, this.$route.params.id)
  }
}
</script>