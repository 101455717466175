<template>
    <chrome-picker 
      @input="updateValue"
      :value="colors" />
</template>
<script>
  import _ from 'lodash'
  import { Chrome } from 'vue-color'

  export default {
    name: 'ColorPicker',
    components: {
      'chrome-picker': Chrome
    },
    props: {
      label: {
        type: String,
        default: null
      },
      value: {
        type: String
      },
    },
    data() {
      return {
        colors: '#1CB724'
      }
    },
    mounted() {
      const vm = this
      if(this.value) vm.colors = _.clone(vm.value)
    },  
    methods: {
      updateValue: function(val = null) {
        if (!val) {
          this.$emit('input', null)
        } else {
          const newVal = (val[this.valueField]) ? val[this.valueField] : val
          this.$emit('input', newVal.hex)
        }
      }
    }
  }
</script>
<style scoped lang="less">
    
</style>
