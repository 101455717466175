<template>
  <div class="grid grid-cols-2 h-full">
    <div id="stats-area" class="grid grid-cols-4 bg-slate-900 p-2 h-[750px]">
      <div v-if="scene && scene.children && group && group.children" class="bg-slate-600 bg-opacity-25 my-4 rounded-l-md text-white shadow-lg p-4 scrollbar overflow-y-scroll scrollbar-thumb-slate-600 scrollbar-track-slate-800 max-h-screen">
        <h3 class="mb-2 text-sm">{{ $t('components.threed_visualizer.scene_elements') }}</h3>
        <div v-for="(element, index) in group.children" :key="'element-'+index">
          <button v-if="['PointZ','PolygonZ'].includes(element.geometry_type)" class="px-2 py-1 rounded mb-1 w-full focus:outline-none focus:ring-2 focus:ring-green-600 border border-transparent text-left" :style="'background-color: '+getElementColor(element)" @click="targetPoint(element.position, element)"> 
            <span v-if="element.card && element.card.cardable" class="text-xs text-gray-300">{{ getLocalization(element.card.cardable.slug).single }}</span><br/>
            <h3 class="text-sm">{{ element.name }}</h3>
            <span v-if="element.description" class="text-xs italic">{{ element.description }}</span>
          </button>
        </div>
      </div>
      <div class="col-span-3 bg-slate-700 rounded-md shadow-2xl p-4 text-orange-400 h-full scrollbar overflow-y-scroll scrollbar-thumb-slate-600 scrollbar-track-slate-800 ">
        <div v-if="form.INTERSECTED">
          <div class="mb-4">
            <span class="bg-gray-900 px-2 py-1 rounded mr-1">{{ form.INTERSECTED.position.z }}</span>
            <span class="bg-gray-900 px-2 py-1 rounded mr-1">{{ form.INTERSECTED.position.x }}</span>
            <span class="bg-gray-900 px-2 py-1 rounded mr-1">{{ form.INTERSECTED.position.y }}</span>
          </div>
          <div v-for="(field, index) in fields" :key="'field-'+index">
            <div class="grid grid-cols-4 gap-4">
              <div class="col-span-1 text-white mb-2">{{ field.name }}</div>
              <div v-if="field.type === 'lemma'" class="col-span-3 mb-2">
                <Lemmatizer v-if="form.INTERSECTED && (form.INTERSECTED.card || (form.INTERSECTED.card && form.INTERSECTED.card[field.related]))" :key="'lemma-'+form.INTERSECTED.name"
                  v-model="form.INTERSECTED[field.field]"
                  :title="field.title"
                  :text="field.text"
                  :lemmaAssociateText="$t('components.lemmatizer.associate')"
                  :lemmaUpdateText="$t('components.lemmatizer.update')"
                  :cancelText="$t('actions.cancel')"
                  :model="form.INTERSECTED.card[field.related] ? form.INTERSECTED.card[field.related]['slug'] : form.INTERSECTED.card.slug"
                  :id="form.INTERSECTED.card[field.related] ? form.INTERSECTED.card[field.related]['id'].toString() : form.INTERSECTED.card.id.toString()"
                  :lemmaModel="field.model"
                  :lemmaField="field.label"
                  :lemmaPivots="field.pivots"
                  :entities="form.INTERSECTED[field.model]" />
              </div>
              <div v-else class="col-span-3 mb-2">
                <div v-if="field.subrelated && field.related && form.INTERSECTED.card[field.related][field.subrelated]" class="col-span-3 mb-2">
                  <div v-if="form.INTERSECTED.card[field.related][field.subrelated].length > 0">
                    <span v-for="(related, index) in form.INTERSECTED.card[field.related][field.subrelated]" :key="'subrelated-'+index">{{ render(related[field.field], field.type) }}</span>
                  </div>
                  <div v-else>{{ render(form.INTERSECTED.card[field.related][field.subrelated][field.field], field.type) }}</div>
                </div>
                <div v-else-if="field.related && form.INTERSECTED.card[field.related]" class="col-span-3 mb-2">
                  <div v-if="form.INTERSECTED.card[field.related].length > 0">
                    <span v-for="(related, index) in form.INTERSECTED.card[field.related]" :key="'related-'+index">{{ render(related[field.field], field.type) }}</span>
                  </div>
                  <div v-else>{{ render(form.INTERSECTED.card[field.related][field.field], field.type) }}</div>
                </div>
                <div v-else class="col-span-3 mb-2">{{ render(form.INTERSECTED.card[field.field], field.type) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else><div class="flex flex-col items-center justify-items-center text-white text-center">{{ $t('components.threed_visualizer.select_element') }}</div></div>
      </div>
    </div>
    <div id="three-d-visualizer" @click="interact" @mousemove="mouseMove">
      <div v-if="legendList && legendList.length > 0" id="legend" class="col-span-4 p-4 bg-gray-800 text-white text-xs z-50 rounded fixed bottom-0 right-0 mr-4 mb-4 bg-opacity-50">
        <h3 class="text-sm">{{ legendName || $t('global.legend') }}</h3>
        <div class="flex flex-col">
          <div v-for="(element, index) in legendList" :key="'legend-'+index" class="flex flex-row mt-2 items-center">
            <div class="rounded-full w-4 h-4" :style="'background-color: '+ ( element.color || '#cccccc') +';'"></div>
            <div class="ml-1 h-20">{{ element.name }}</div>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
</template>
<script>

/*
Background @props: require(`@/plugins/rometransformed/assets/background.jpg`)
Bounding box .env: VUE_APP_BACKGROUND_BOUNDS
Point position e.g.:
  x:4640507.711161
  y:0
  z:2313982.347521
*/

import * as Three from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

import Api from '@/components/Api'

import Lemmatizer from '@/components/Lemmatizer'

export default {
  name: 'ThreeDVisualizer',
  mixins: [
    Api
  ],
  components: {
    Lemmatizer
  },
  props: {
    model: {
      type: String,
      default: null
    },
    height: {
      type: Number,
      default: 400
    },
    background: {
      default: null
    },
    fields: {
      type: Array,
      default: null
    },
    localizations: {
      type: Array,
      default: () => []
    },
    legend: {
      type: String,
      default: null
    },
    legendName: {
      type: String,
      default: null
    },
    modelFile: {
      default: null,
    },
    modelTexture: {
      default: null,
    },
    modelOptions: {
      default: {
        scale: 1,
        rotation: 0,
        pan: false,
        height: 0,
        center_x: null,
        center_y: null,
        nw: null,
        ne: null,
        sw: null,
        se: null
      }
    },
    archive:  {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      container: null,
      camera: null,
      scene: null,
      top_grid: null,
      bottom_grid: null,
      axesHelper: null,
      geometries: [],
      ground: null,
      renderer: null,
      controls: null,
      raycaster: null,
      pointer: null,
      form: {
        INTERSECTED: null,
      },
      loaded: false,
      objects: [],
      models: [],
      scale: {
        size: 50000000,
        coords: 1,
        sphere_radius: 1,
        geom_radius: 0.5,
        geom_height: 0.1, 
        geom_segments: 6,
        z: 100
      },
      colors: {
        background: 'rgb(60,57,57)',
        grid: 0xffff00,
        gridline: 0xffffff,
        mainlight: 'rgb(255,220,220)',
        backlight: 'rgb(255,220,220)',
      },
      background_image: null,
      group: null,
      invise: null,
      manager: null,
      textureLoader: null,
      legendList: [],
      model3dLoader: null,
      model3dFile: null,
      model3dTexture: null
    }
  },
  computed: {
    bounds() {
      return [this.modelOptions.nw,this.modelOptions.ne,this.modelOptions.sw,this.modelOptions.se]
      //return process.env.VUE_APP_BACKGROUND_BOUNDS.split(',')
    }
  },
  async created() {
    const vm = this
    // Setup event handlers
    window.addEventListener('resize', this.onWindowResize )
    vm.models = vm.model.split(',')
    // console.log('[background]', vm.background)
    if(vm.background) vm.background_image = vm.background
  },
  async mounted() {
    const vm = this
    let count = 0
    for (const model of vm.models) {
      await vm.fetch(`${model}/${vm.archive}`,null,1,99999).then(() => {
        vm.objects = [...vm.objects, ...vm.entities]
        count++
      })
    }
    // setup legend (colors)
    if(vm.legend){
      await vm.get(vm.legend, {archeological_archive: vm.archive}).then(response => {
        vm.legendList = response.data
      })
    }
    // vm.layer_base = await vm.get('layers/base')
    if(count === vm.models.length) {
      //vm.layer_base = vm.layer_base.data || vm.layer_base
      vm.init()
      vm.prepareInteractions()
      vm.animate()  
      vm.loaded = true
    }
    // console.log(vm.bounds)
  },
  beforeUnmount() {
    this.raycaster = null
    this.renderer = null
    this.manager = null
    this.scene = null
    this.camera = null
    this.container = null
    this.textureLoader = null
    console.log(this)
    this.$destroy()
  },
  methods: {
    updateInput(event) {
      this.$emit('input', this.type === 'checkbox' 
        ? event.target.checked 
        : event.target.value
      )
    },
    init: function() {
      const vm = this
      vm.container = document.getElementById('three-d-visualizer')

      // Setup scene
      vm.scene = new Three.Scene()
      vm.scene.background = new Three.Color(vm.colors.background)
   
      // Create Objects Group
      vm.group = new Three.Group();
      vm.invise = new Three.Group();
      
      // Setup center and bounds of scene
      // vm.createPoint([234500.123,234500.123,0], {code: 'TestCard'})
      vm.createPoint([vm.bounds[1],vm.bounds[0],0], {code: 'NW'}, false, null, null, 'invise')
      vm.createPoint([vm.bounds[3],vm.bounds[2],0], {code: 'SE'}, false, null, null, 'invise')
      vm.createPoint([vm.bounds[3],vm.bounds[0],0], {code: 'NE'}, false, null, null, 'invise')
      vm.createPoint([vm.bounds[1],vm.bounds[2],0], {code: 'SW'}, false, null, null, 'invise')
      var line_material = new Three.LineBasicMaterial( { transparent: true, opacity: 0.0 } ) // TODO: colore dinamico 0x0000ff
      var group_points = new Three.BufferGeometry().setFromPoints([
        new Three.Vector3(vm.bounds[0]/vm.scale.coords, 0, vm.bounds[1]/vm.scale.coords),
        new Three.Vector3(vm.bounds[2]/vm.scale.coords, 0, vm.bounds[3]/vm.scale.coords)
      ]);
      var line = new Three.Line( group_points, line_material );
      line.name = 'diagonal'
      vm.invise.add(line)
      var center = vm.getCenterPoint(line)
      //var world_center = vm.createPoint([center.z,0,center.x], {code: 'CENTER OF THE WORLD'})
      var project_center = vm.createPoint([
        vm.modelOptions.center_y, //process.env.VUE_APP_THREED_CENTER_Y,
        vm.modelOptions.center_x, //process.env.VUE_APP_THREED_CENTER_X,
        0
      ], {code: 'center'}, true, 'center', null, 'invise')
      vm.invise.position.set(center.z,0,center.x)
      vm.group.position.set(center.z,0,center.x)
      vm.scene.add(vm.invise)
      vm.scene.add(vm.group)

      // Setup Texture Loading Manager
      vm.manager = new Three.LoadingManager()
      vm.manager.onProgress = function(item, loaded, total) { 
        console.log('[loading on progress...]', item, loaded, total ) 
      }
      // Three.ImageUtils.crossOrigin = ''
      vm.textureLoader = new Three.TextureLoader( vm.manager )
      vm.textureLoader.setCrossOrigin('')

      if(vm.background_image){
        vm.textureLoader.load(vm.background_image, function(texture){
          
          // console.log(texture)
          texture.flipY=false

          const nw = vm.getElement('invise', 'NW')
          const sw = vm.getElement('invise', 'SW')
          const ne = vm.getElement('invise', 'NE')
          const width = vm.distanceVector(nw.position, sw.position)
          const height = vm.distanceVector(nw.position, ne.position)
          // console.log(nw,sw,ne,height, width)
          const plane = new Three.Mesh(
            new Three.PlaneGeometry(width, height), 
            new Three.MeshBasicMaterial({map: texture, side: Three.DoubleSide, opacity: 0.5, transparent: true}) 
          )
          plane.name = 'AOI'
          plane.rotation.x = Math.PI / 2
          plane.rotation.z = Math.PI / 2
          plane.position.set(center.z,0,center.x)
          //vm.geometries.push(plane)
          vm.invise.add(plane)
   
        },
        function onProgress(xhr){
          if(xhr.lengthComputable){
            const percentComplete = xhr.loaded / xhr.total * 100;
            console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
          }
        },
        function onError(xhr) {
          console.log('[error]', xhr)
        }) // end load texture
      
      } // end setup background image


      // Setup 3D model loading
      if(vm.modelFile){
        vm.model3dLoader = new OBJLoader( vm.manager )
        vm.model3dLoader.load( vm.modelFile, function ( obj ) {
          vm.model3dFile = obj;
          vm.model3dFile.name = "3D Model"

          console.log(vm.model3dFile)
          // set scaling and rotation by user options
          vm.model3dFile.scale.setScalar(vm.modelOptions.scale)
          vm.model3dFile.rotation.y = vm.modelOptions.rotation === -90 ? - Math.PI / 2 : 0; // TODO aumentare opzioni rotazioni

          // create texture for 3d model
          if(vm.modelTexture){
            vm.model3dTexture = vm.textureLoader.load( vm.modelTexture )
            // create object for 3d model
            vm.model3dFile.traverse(function(child){
              if(child.isMesh) child.material = new Three.MeshBasicMaterial( { map: vm.model3dTexture, side: Three.DoubleSide, opacity: 0.5, transparent: true } )
            })
          }
          vm.model3dFile.position.set(center.z, vm.modelOptions.height || 0, center.x)
          vm.invise.add(vm.model3dFile)
        },
        function onProgress(xhr){
          if(xhr.lengthComputable){
            const percentComplete = xhr.loaded / xhr.total * 100;
            console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
          }
        },
        function onError(xhr) {
          console.log('[error]', xhr)
        }) // end load 3d model
      }

      // Setup scene grids
      vm.top_grid = new Three.GridHelper( vm.scale.size, 10000, vm.colors.gridline, vm.colors.grid ) // size, divisions, color center line, color grid
      vm.top_grid.position.y = 0
      vm.top_grid.material.opacity = 0.1
      vm.top_grid.material.transparent = true
      vm.top_grid.name = 'top_grid'
      vm.invise.add( vm.top_grid )
      
      // Setup lights
      let dirLight = new Three.DirectionalLight( vm.colors.mainlight, 1 )
      dirLight.position.set( 10, 10, 10 )
      dirLight.castShadow = true
      vm.scene.add( dirLight );
      let backLight = new Three.DirectionalLight( vm.colors.backlight, 0.8 );
      backLight.position.set( 10, 10, -10 );
      vm.scene.add( backLight );

      // Add axis helper in scene center
      // vm.axesHelper = new Three.AxesHelper( 50000000 );
      // vm.scene.add( vm.axesHelper );

      vm.prepareGeometries()

      // Setup renderer
      vm.renderer = new Three.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true }) // {antialias: true})
      vm.renderer.setPixelRatio( window.devicePixelRatio );
      vm.renderer.setSize(vm.container.clientWidth, vm.container.clientHeight)
      vm.container.appendChild(vm.renderer.domElement)

      // Sky background
      // const loader = new Three.TextureLoader()
      // const texture = loader.load(
      //   require('@/assets/threed/digital_background.jpg'),
      //   () => {
      //     // const rt = new Three.WebGLCubeRenderTarget(texture.image.height) // 
      //     // rt.fromEquirectangularTexture(vm.renderer, texture)
      //     // vm.scene.background = rt.texture
      //     const sky = new Three.Mesh(
      //       new Three.SphereGeometry( 3000, 1000, 1000 ),
      //       new Three.MeshBasicMaterial( { map: texture, overdraw: 0.5 } )
      //     )
      //     // sky.geometry_type = 'PointZ'
      //     sky.name = 'Sky'
      //     sky.position.set(center.x,center.z,0)
      //     vm.group.add(sky)
      //   }
      // )
        
      // Setup camera
      vm.camera = new Three.PerspectiveCamera(50, vm.container.clientWidth / vm.container.clientHeight, 0.1, vm.scale.size)
      vm.camera.position.set(vm.scale.size/5,vm.scale.size/2,vm.scale.size/3)
      vm.camera.zoom = 5000
          
      // Setup controls
      vm.controls = new OrbitControls( vm.camera, vm.renderer.domElement )
      vm.controls.enableRotate = true
      vm.controls.enableZoom = true
      vm.controls.enablePan = vm.modelOptions.pan
      vm.controls.rotateSpeed = 0.5 // 0.3
      vm.controls.zoomSpeed = 0.5 // 0.1
      vm.controls.panSpeed = 0.0001 // 0.3
      vm.controls.minDistance = 10
      vm.controls.maxDistance = vm.scale.size / 10
      vm.controls.mouseButtons.LEFT = Three.MOUSE.PAN
      vm.controls.mouseButtons.RIGHT = Three.MOUSE.DOLLY
      vm.controls.screenSpacePanning = false

      // init END
      vm.camera.updateProjectionMatrix()
      vm.targetPoint(project_center.position)
    },
    prepareGeometries: function() {
      const vm = this
      
      vm.objects.forEach(function(entity){

        // to create lines between multipointz stratigraphy
        let group_points = null
        let line_material = null
        let line_points = []
        let line = null

        // Handle geometry types and rasters attached
        if(entity.locations) {

          entity.locations.forEach(location => {

            // 1. MultiPointZ (Boreholes)
            if(location.multipointz && location.multipointz.coordinates.length > 0) {
              let color = '#cccccc'
              if(entity.interpretations && entity.interpretations.length > 0){
                color = entity.interpretations[0].color
              }
              if(entity.cardable && entity.cardable.interpretations && entity.cardable.interpretations.length > 0){
                color = entity.cardable.interpretations[0].color
              }
              let count = 1
              location.multipointz.coordinates.forEach(function(pointz){              
                vm.createPoint(pointz, entity, true, entity.code, 'point nr. '+count)
                line_points.push( new Three.Vector3(pointz[0]/vm.scale.coords, (pointz[2]/vm.scale.z), pointz[1]/vm.scale.coords) )
                count++
              })
              
              // add line between points of the same stratigraphy
              line_material = new Three.LineBasicMaterial( { color: color } );
              group_points = new Three.BufferGeometry().setFromPoints( line_points );
              line = new Three.Line( group_points, line_material );
              line.name = 'line'
              vm.invise.add(line)
              line = line_material = group_points = null // reset for next stratigraphy
              line_points = []
            }
  
            // 2. Single PointZ
            if(location.pointz && location.pointz.type === 'Point') {
              let coords = location.pointz.coordinates
              vm.createPoint(coords, entity)
            }
  
            // 3. Rasters
            if(entity.files){
              let rasters = entity.files.filter(file => { return file.type === 'raster' })
              if(rasters.length > 0){
                // console.log('[rasters found!]', rasters)
                rasters.forEach(function(raster){
                  // console.log(raster.web[Object.keys(raster.web)[0]].file)
                  vm.createTexturedPolygon({
                    ne: {
                      lat: raster.extra_detail.ne_lat, 
                      lng: raster.extra_detail.ne_lng
                    },
                    sw: {
                      lat: raster.extra_detail.sw_lat, 
                      lng: raster.extra_detail.sw_lng 
                    },
                    z: parseFloat(raster.extra_detail.elevation)
                  }, entity, raster.web[Object.keys(raster.web)[0]].file)
                })
              }
            }

          })

        }
      })

    },
    animate: function() {
      const vm = this
      vm.group.position.set(0,0,0)
      vm.invise.position.set(0,0,0)
      requestAnimationFrame( this.animate )
      this.renderer.render(this.scene, this.camera)
    },
    prepareInteractions: function() {
      const vm = this
      vm.raycaster = new Three.Raycaster()
      vm.pointer = new Three.Vector2()
			vm.camera.updateMatrixWorld()
    },
    onWindowResize: function() {
      const vm = this
      vm.camera.aspect = vm.container.clientWidth / vm.container.clientHeight
      vm.camera.updateProjectionMatrix();
      vm.renderer.setSize( vm.container.clientWidth, vm.container.clientHeight )
    },
    mouseMove: function(event) {
      const vm = this
      event.preventDefault()
      if(vm.loaded){
        vm.pointer.x = ( ( event.clientX - vm.renderer.domElement.offsetLeft ) / vm.renderer.domElement.clientWidth ) * 2 - 1;
        vm.pointer.y = - ( ( event.clientY - vm.renderer.domElement.offsetTop ) / vm.renderer.domElement.clientHeight ) * 2 + 1;
      }
    },
    interact: function(event) {
      const vm = this
      event.preventDefault()
      vm.pointer.x = ( ( event.clientX - vm.renderer.domElement.offsetLeft ) / vm.renderer.domElement.clientWidth ) * 2 - 1
      vm.pointer.y = - ( ( event.clientY - vm.renderer.domElement.offsetTop ) / vm.renderer.domElement.clientHeight ) * 2 + 1
      // find intersections
      vm.raycaster.setFromCamera(vm.pointer, vm.camera)
			const intersects = vm.raycaster.intersectObjects(vm.group.children, true)
      console.log('[intersections]', intersects)
      if(intersects.length > 0) {
        intersects.forEach( function(intersect) {
          console.log('intersect: '+intersect.object.name)
          if(intersect.object.geometry_type && ['PointZ','PolygonZ'].includes(intersect.object.geometry_type)) {
            if(vm.form.INTERSECTED) {
              vm.form.INTERSECTED.material.color.setHex(vm.form.INTERSECTED.originalColor)
            }
            // callback on click point
            vm.form.INTERSECTED = intersect.object
            vm.form.INTERSECTED.originalColor = vm.form.INTERSECTED.material.color.getHex()
            vm.form.INTERSECTED.material.color.setHex(0xffff00);
            console.log('[clicked]', vm.form.INTERSECTED)
            
            // to target camera and controls on this point
            vm.targetPoint(vm.form.INTERSECTED.position)
          }
        })
      }
    },
    selectPoint: function(point){
      var selectedObject = this.scene.getObjectByName("punto")
      console.log('[point selected]', selectedObject, point) 
    },
    // Create Point by coords of entity
    createPoint(coords, entity, visibility = true, name = null, description = null, group_name = 'group'){
      const vm = this
      let color = '#cccccc'
      if(entity.interpretations && entity.interpretations.length > 0){
        color = entity.interpretations[0].color
      }
      if(entity.cardable && entity.cardable.interpretations && entity.cardable.interpretations.length > 0){
        color = entity.cardable.interpretations[0].color
      }
      // let geometry = new Three.SphereBufferGeometry(vm.scale.sphere_radius) // vm.scale.sphere_radius
      let geometry = new Three.CylinderGeometry(vm.scale.geom_radius, vm.scale.geom_radius, vm.scale.geom_height, vm.scale.geom_segments )
      let material = new Three.MeshLambertMaterial( {color: color} ) // TODO: colore dinamico in base a methodology e interpretation
      let point = new Three.Mesh(geometry, material)
      point.name = name ? name : entity.code
      point.description = description
      point.card = entity
      if(visibility) {
        point.geometry_type = 'PointZ'
      }else{
        point.material.transparent = true
        point.material.opacity = 0.0
      }
      point.position.set( (coords[0])/vm.scale.coords, (coords[2])/vm.scale.z, (coords[1])/vm.scale.coords) // LAT, LNG, Z => X, Z, Y
      vm.geometries.push(point)
      //vm.scene.add(point)
      vm[group_name].add(point)
      return point
    },
    // Create Polygon to insert in 3D enviornment
    createTexturedPolygon(coords, entity, raster = null){
      const vm = this
      // polygon with texture
      if(raster){
        vm.textureLoader.load(raster, function(texture){
          texture.flipY=false
          vm.createPolygon(coords,entity,texture)
        },
        function onProgress(xhr){
          if(xhr.lengthComputable){
            const percentComplete = xhr.loaded / xhr.total * 100;
            console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
          }
        },
        function onError(xhr) {
          console.log('[error]', xhr)
        }) // end load texture
      }
      // polygon without texture
      vm.createPolygon(coords,entity)
    },
    createPolygon(coords, entity, raster = null){
      const vm = this
      const vne = new Three.Vector3(coords.ne.lng / vm.scale.coords, coords.z, coords.ne.lat / vm.scale.coords)
      const vsw = new Three.Vector3(coords.sw.lng / vm.scale.coords, coords.z, coords.sw.lat / vm.scale.coords)
      const height = vm.distanceVector(
        {x: coords.ne.lng / vm.scale.coords, y: coords.z, z: coords.sw.lat / vm.scale.coords},
        {x: coords.sw.lng / vm.scale.coords, y: coords.z, z: coords.sw.lat / vm.scale.coords},
      )
      const width = vm.distanceVector(
        {x: coords.ne.lng / vm.scale.coords, y: coords.z, z: coords.sw.lat / vm.scale.coords},
        {x: coords.ne.lng / vm.scale.coords, y: coords.z, z: coords.ne.lat / vm.scale.coords},
      )
      const plane = new Three.Mesh(
        new Three.PlaneGeometry(width, height), 
        raster 
          ? new Three.MeshBasicMaterial({map: raster, side: Three.DoubleSide, opacity: 0.5, transparent: true}) 
          : new Three.MeshLambertMaterial({color: '#cccccc'})
      )
      plane.name = entity.card.code
      plane.geometry_type = 'PolygonZ'
      plane.card = entity
      plane.rotation.x = Math.PI / 2
      plane.rotation.z = Math.PI / 2
      let center = vm.getPointInBetweenByPerc(vne, vsw, 0.50)
      plane.position.set(center.z,coords.z,center.x)
      console.log(plane)
      vm.geometries.push(plane)
      vm.group.add(plane)
    },
    // Target camera and controls on a Point
    targetPoint(position = {x:0,y:0,z:0}, element = null){ // }), object = null){
      const vm = this
      if(element) { 
        vm.$set(vm.form, 'INTERSECTED', element)
        if(vm.form.INTERSECTED.geometry_type === 'PointZ'){
          vm.form.INTERSECTED.originalColor = vm.form.INTERSECTED.material.color.getHex()
          vm.form.INTERSECTED.material.color.setHex(0xffff00);
        }
      }
      vm.controls.target = new Three.Vector3(position.x, position.y, position.z)
      vm.controls.update()
    },
    getCenterPoint(mesh) {
      var geometry = mesh.geometry
      geometry.computeBoundingBox()
      var center = new Three.Vector3()
      geometry.boundingBox.getCenter(center)
      mesh.localToWorld(center)
      return center;
    },
    distanceVector(v1,v2){
      var dx = v1.x - v2.x
      var dy = v1.y - v2.y
      var dz = v1.z - v2.z
      return Math.sqrt( dx * dx + dy * dy + dz * dz )
    },
    getPointInBetweenByPerc(pointA, pointB, percentage) {
      var dir = pointB.clone().sub(pointA);
      var len = dir.length();
      dir = dir.normalize().multiplyScalar(len*percentage);
      return pointA.clone().add(dir);
    },
    getElement(group_name, name){
      const vm = this
      let element = vm[group_name].children.filter((element) => { return element.name === name })
      return element[0]
    },
    getElementColor(element){
      let color = '#777'
      if(element.card && element.card.cardable && element.card.cardable.interpretations && element.card.cardable.interpretations.length > 0){
        color = element.card.cardable.interpretations[0].color
      }
      return color
    },
    getLocalization(slug){
      let element = this.localizations.filter((element) => { return element.slug === slug })
      return element[0]
    },
    render(data, type = 'text'){
      switch(type){
        case 'text':
          return data
        case 'number':
          if(data && data.length) return data.length >= 4 ? data.slice(0, data.length-2) + "," + data.slice(data.length-2) : data.length === 3 ? data.slice(0, data.length-1) + "," + data.slice(data.length-1)+"0" : data+',00'
          return "No data"
      }
    }
  }
}
</script>
<style scoped>
#three-d-visualizer {
  height: 750px;
  width: 100%;
}
/*.resole-scroll {
  overflow: scroll;
  scrollbar-color: red orange;
  scrollbar-width: thin;
}*/
/* * {
  scrollbar-width: thin;
  scrollbar-color: rgba(160,160,160,0.6);
} 
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  @apply bg-slate-800;
  border-radius: 15px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(160,160,160,0.6);
  border-radius: 15px;
  border: 2px solid rgb(160,160,160);
}*/
</style>