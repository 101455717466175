var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stratigraphies"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.rometransformed.views.stratigraphies.subtitle'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('CrudTable',{attrs:{"title":_vm.$t('plugins.rometransformed.views.stratigraphies.title'),"model":"stratigraphies","model-name-field":"code","check-owner":true,"check-roles":['stratigraphy_manager'],"columns":[
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.code'), field: 'code', related: 'card'},
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.observation'), field: 'name', parent: 'observation', model: 'observations'},
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
          {name: _vm.$t('plugins.rometransformed.views.interpretations.single'), related: 'interpretations', field: 'name'},
          {field: 'actions'},
        ],"cards-importer":true,"cards-importer-archive-text":_vm.$t('plugins.rometransformed.views.stratigraphies.importer_archive_text'),"cards-file-types-allowed":[
          {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
          {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
          {name: 'CSV', type: 'text/csv'}
        ],"cards-import-model":"rt3d_import_model.xlsx","search-filters":[
          {field: 'code',     type: 'text',   label: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.code')},
          {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.archive'), placeholder: _vm.$t('views.archives.select')}
        ],"shapefile-importer":true}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }