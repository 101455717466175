<template>
  <div class="types">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.types.single') }}
      </template>
      <template v-slot:main>
        <CrudEntity v-if="canLoad"
          :model="model" :id="id" :mode="crud_mode" :entity="entity"
          :fields="[
            {name: $t('plugins.rometransformed.views.types.fields.code'), field: 'code', type: 'text'},
            {name: $t('plugins.rometransformed.views.types.fields.name'), field: 'name', type: 'text'},
            {name: $t('plugins.rometransformed.views.types.fields.description'), field: 'description', type: 'text-editor', span: '2'},
          ]"
        />
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudLoad from '@/components/CrudLoad'
import CrudEntity from '@/components/CrudEntity'

export default {
  name: 'Type',
  mixins: [
    CrudLoad
  ],
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      model: 'types',
    }
  },
  components: {
    BaseLayout, CrudEntity
  },
  async beforeMount(){
    this.load(this.mode, this.$route.params.id)
  }
}
</script>