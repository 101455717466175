<template>
  <div class="file-manager">
    <LoadingSpinner :toggle="isLoading" />
    <div v-if="canLoad" class="file-wrapper flex flex-col p-3">
      <div v-if="!entity.read_only || mode === 'edit'" class="file-uploader w-full">
        <FileUploader v-if="entity && mode === 'edit'"
          classes="w-full rounded-md"
          :is-simple="false"
          :types="fileModelTypes"
          :fields="fileModelFields"
          @inputData="updateFileData"
          :title="uploaderText"
          :model-id="parseInt(entity.id)"
          :model-name="model"
          :model-file-version="null" 
          :file-types-allowed="fileTypesAllowed || null" />
      </div>
      <div class="container grid grid-cols-1 xl:grid-cols-2 gap-4 pr-3 mt-5" v-if="entity && entity.files && entity.files.length > 0">
        <!-- gallery items -->
        <div :class="'file-item box-content w-full flex flex-row bg-white p-1 rounded-sm '+ (file.is_cover ? 'border-2 border-green-700' : 'border')" v-for="file in entity.files" :key="file.id">
          <div class="h-full w-3/12 flex flex-col items-center justify-center border bg-gray-100 rounded-sm">
            <div v-if="file.is_image" class="flex flex-col">
              <img class="file-item-background object-cover h-24 p-1 cursor-pointer" :src="`${ file.thumb[0].file }`" @click="showImage(file)" />
              <div class="text-center break-all px-1 pt-1 pb-1 flex flex-row justify-center content-center items-center">
                <f-icon class="" :icon="icon(file)" />
                <span class="text-xs pl-1">{{ file.id }}</span>
                <span class="text-xs text-green-800 pl-1">{{ extension(file) }}</span>
              </div>
            </div>
            <div v-else class=" bg-green-600 text-white text-center break-all p-3 h-full w-full flex flex-col justify-center items-center">
              <f-icon class="text-xl pb-1" :icon="icon(file)" />
              <span class="text-xs">{{ file.id }}</span> <span class="rounded-md bg-gray-300 mt-1 text-xs text-green-800 py-1 px-2">{{ extension(file) }}</span>
            </div>
          </div>
          <div class="w-7/12 py-2 pl-1">
            <div class="flex">
              <div class="w-1/3 text-xs">{{ $t('global.type') }}</div>
              <select v-model="file.type" disabled class="w-2/3 block px-1 mb-1 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs">
                <option disabled value="">{{ $t('global.select') }}</option>
                <option v-for="(option, index) in fileModelTypes" :key="`static-option-${index}`" :value="option.type">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div v-for="(field, index) in fileModelFields" :key="'file-field-'+index">
              <div v-if="!field.related && file.extra_detail && !['reference', 'license', 'description', 'file_id'].includes(field.field)" class="flex">
                <div class="w-1/3 text-xs">{{field.name}}</div>
                <CrudInput
                  v-model="file.extra_detail[field.field]"
                  type="text"
                  :field="field"
                  @input="highlight(file)"
                  :disabled="mode === 'show'"
                  classes="outline-none w-2/3 h-5 px-1 mb-1 text-xs text-gray-700 placeholder-gray-600 border rounded-sm focus:shadow-outline" />
              </div>
              <div v-if="field.related" class="flex">
                <div class="w-1/3 text-xs">{{field.name}}</div>
                <VSelect v-if="mode === 'edit'"
                  v-model="file[field.field]"
                  :model="field.related"
                  :search-field="field.label"
                  :label="field.label"
                  value-field="id"
                  placeholder="Seleziona..."
                  :icon="true"
                  iconName="file"
                  :init="false"
                  class="block w-2/3 h-7 pl-1 mb-1 text-xs border border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                </VSelect>
                <div v-else class="bg-gray-200 block w-2/3 h-7 pl-1 mb-1 rounded-md ">{{ file[field.field] }}</div>
              </div>
              <div v-if="!field.related && ['reference', 'license', 'description', 'file_id'].includes(field.field)" class="flex">
                <div class="w-1/3 text-xs">{{field.name}}</div>
                <CrudInput 
                  v-model="file[field.field]"
                  type="text"
                  :field="{field: field.field}"
                  @input="highlight(file)"
                  :disabled="mode === 'show'"
                  classes="outline-none w-2/3 h-5 px-1 mb-1 text-xs text-gray-700 placeholder-gray-600 border rounded-sm focus:shadow-outline" />
              </div>
            </div>
          </div>
          <div v-if="file.formats" :class="'file-item-actions w-3/12 py-2 grid gap-4 justify-center items-left text-base rounded-sm ml-2 pl-1 '+(file.changed ? 'bg-yellow-500' : 'bg-gray-200') + (isLoading ? 'disabled opacity-50' : '')">
            <div v-if="file.is_image" class="rounded px-2 py-1 text-xs bg-green-700 text-white">
              <div v-if="file.is_cover" class="align-center"><f-icon class="" :icon="icon(file)" /> {{ $t('components.file_manager.is_cover') }}</div>
              <button v-else @click="setCover(file)" class=" text-left"><f-icon class="" :icon="icon(file)" /> {{ $t('components.file_manager.set_as_cover') }}</button>
            </div>
            <div v-for="(format, index) in file.formats" :key="`file-download-format-${index}`" class="rounded px-2 py-1 text-xs  bg-blue-700 text-white">
              <a href="#" target="_blank" class="button" @click.prevent="download(file, format)">
                <f-icon icon="file-arrow-down" /> {{format.size}}
              </a>
            </div> 
            <div class="rounded px-2 py-1 text-xs bg-green-700 text-white">
              <a v-if="mode === 'edit'" href="#" class="button" @click.prevent="update(file)"><f-icon icon="floppy-disk" /> {{ $t('actions.save') }}</a>
            </div> 
            <div class="rounded px-2 py-1 text-xs bg-red-700 text-white"><a v-if="mode === 'edit'" href="#" class="button" @click.prevent="confirmDestroy(file)"><f-icon icon="trash-can" /> {{ $t('actions.delete') }}</a></div>
          </div>
        </div>
        <!-- /gallery items -->
      </div>
      <div v-else class="empty-gallery flex justify-center">
        <span class="p-8">{{ emptyText || $t('components.file_manager.no_content') }}</span>
      </div>
    </div>
  </div>
</template>
<script>

// TODO light box a tutto schermo per vedere le singole immagini

import moment from 'moment'

import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import FileUploader from '@/components/FileUploader'
import ModalDelete from '@/components/ModalDelete'
import CrudInput from '@/components/CrudInput'
import ModalView from '@/components/ModalView'

export default {
  name: 'FileManager',
  components: {
    LoadingSpinner: LoadingSpinner,
    FileUploader: FileUploader,
    CrudInput: CrudInput
  },
  props: {
    columns: {
      type: Array,
      required: false,
      default: null
    },
    emptyText: {
      type: String,
      default: null
    },
    modelId: {
      type: Number,
      default: null,
      required: true
    },
    model: {
      type: String,
      default: 'Cards',
      required: false
    },
    locale: {
      type: Object,
      default: null,
      required: false
    },
    tableName: {
      type: String,
      default: null,
      required: false
    },
    fileTypesAllowed: {
      type: Array
    },
    uploaderText: {
      type: String,
      default: 'Carica file'
    },
    mode: {
      type: String,
      default: 'edit'
    },
    related: {
      type: Boolean,
      default: false
    },
    fileModelTypes: {
      type: Array  
    },
    fileModelFields: {
      type: Array
    },
    entityData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: null,
      isSingle: false,
      config: {
        'headers': {
          'Accept': 'application/json'
        }
      },
      error: null,
      isLoading: false,
      lastFile: null,
      entity: null,
      canLoad: false,
      media: []
    }
  },
  computed: {
    uploadsPath(){
      return process.env.VUE_APP_UPLOADS_PATH || null
    }
  },
  async created() {
    const vm = this
    vm.entity = vm.entityData
    // if media is of extended related card
    if(vm.related) vm.entity = vm.entity.card
    this.fileFormats()
    vm.canLoad = true
  },
  methods: {
    async updateFileData() {
      const vm = this
      vm.isLoading = true
      vm.entity = await api.fetch(vm.model, vm.modelId)
      if(vm.related) vm.entity = vm.entity.card
      this.fileFormats()
      vm.isLoading = false
      vm.$toasted.show(vm.$i18n.t('modals.importer.confirmed'))
    },
    formatDate(date){
      return moment(date).format('HH:mm DD/MM/YYYY')
    },
    updateFileHistory(m) { // update infos from file history component
      const vm = this
      if(m.message && m.message == 'empty') vm.loadTypes()
    },
    parseTitle(title){
      return title.replace(' ', '_');
    },
    // async destroy(id){
    //   let destroyed = await api.delete('files', id)
    //   return !!destroyed
    // },
    confirmDestroy(image, multiple = false) {
      this.$modal.show(ModalDelete,
        {
          title: this.$i18n.t('modals.delete.title_named',{name: 'File '+image.file}),
          text: this.$i18n.t('modals.delete.content'),
          deleteText: this.$i18n.t('actions.delete'),
          entity: image,
          multiple: multiple,
          model: 'files',
          update: (deleted) => {
            if(deleted === 'reload'){ // TODO: reload data in case of multiple deletion
              // this.fetch(this.model) 
              // this.toggle = false
            } 
            this.$toasted.show(this.$i18n.t('modals.delete.confirmed', { name: 'File '+deleted.file }))
            this.entity.files.splice(this.entity.files.indexOf(deleted), 1);
          }
        },{
          height: 'auto'
        }
      )
    },
    async update(file){
      await api.update('files', file.id, file)
      this.$set(this.entity.files[this.entity.files.indexOf(file)], 'changed', false)
      this.$toasted.show(this.$i18n.t('modals.save.confirmed'))
    },
    extension(file){
      let re = /(?:\.([^.]+))?$/
      return re.exec(file.file)[1]
    },
    icon(file){
      let ext = this.extension(file)
      switch(ext){
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tif':
        case 'tiff':
          return 'image'
        case 'obj':   
          return 'cube'
        case 'xls':
        case 'xlsx':
        case 'csv':
          return 'file-excel'
        default:      
          return 'file'
      }
    },
    highlight(file){
      this.entity.files[this.entity.files.indexOf(file)].changed = true
    },
    download(file, format) {
      const vm = this
      vm.isLoading = true
      vm.error = []
      vm.showError = false
      api.download(`download/${file.id}`, {
        format: format.size !== 'original' ? 'web' : null, 
        size: format.size, filename: `card-${file.fileable_id}-${format.size}-${file.id}.${this.extension(format)}`
      }).then((response) => {
        if(response.success){
          vm.showError = false
          this.$toasted.show(this.$i18n.t('modals.download.confirmed'))
          vm.isLoading = false
        }else{
          vm.showError = true
          vm.error = response.data
          vm.isLoading = false
        }
      })
    },
    showImage(file){
      this.$modal.show(ModalView,
        {
          title: `Preview of attachment ${file.id}`,
          url: file.web[0].file
        },{
          height: 'auto'
        }
      )
    },
    fileFormats(){
      // create contextual menu items to download various file formats
      if(this.entity.files.length > 0) for(var j = 0; j < this.entity.files.length; j++) {
        //let formats = {original: {'size': 'original', file: this.entity.files[j].original}, ...this.entity.files[j].web}
        let formats = {original: {'size': 'original', file: this.entity.files[j].original}}
        if(this.entity.files[j].is_image) formats = {...formats, ...this.entity.files[j].web}
        this.$set(this.entity.files[j], 'formats', formats  )
      }
    },
    async setCover(file){
      const vm = this
      vm.isLoading = true
      await api.update('files/coverize', file.id).then(async response =>  {
        if(response.success){
          vm.showError = false
          vm.entity.files.forEach(file => {
            file.is_cover = false
          })
          vm.entity.files[vm.entity.files.indexOf(file)].is_cover = true
          vm.$toasted.show(vm.$i18n.t('modals.coverize.confirmed'))
          vm.isLoading = false
        }else{
          vm.showError = true
          vm.error = response.data
          vm.isLoading = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>