var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"placenames"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.placenames.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"card","has-location":true,"check-owner":true,"fields":[
          // Base Card fields
          {text: _vm.$t('views.cards.fields.card_separator'), type: 'separator'},
          {name: _vm.$t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_visible')},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.ereditaculturali.tips.is_published')},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', label: 'name', select: _vm.select_user },
          {name: _vm.$t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.cards'), field: 'parents', model: 'cards', related: 'card', type: 'multi-select', span: '2', label: 'name'},
          {name: _vm.$t('views.cards.fields.references'), field: 'references', related: 'card', model: 'references', type: 'multi-select', label: 'citation', labels:['citation'], taggable: true, span: '2'},
          {name: _vm.$t('views.cards.fields.reference_sources'), field: 'reference_sources', related: 'card', model: 'reference_sources', type: 'multi-select', label: 'name', taggable: true, span: '2'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.type'), field: 'type_id', type: 'select', model: 'placename_types', label: 'name' },
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.region'), field: 'region', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.literal_transcript'), field: 'literal_transcript', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.latin_transcript'), field: 'latin_transcript', type: 'text'},
          {name: _vm.$t('plugins.ereditaculturali.views.placenames.fields.notes'), field: 'notes', type: 'text-editor', span: '2'},
        ]}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }