import axios from 'axios'
import appConfig from '@/config'
import store from '@/store'
import router from '@/router'
import auth from '@/services/auth'

const api = axios.create({
  baseURL: appConfig.apiRoot,
  headers: {
    'Content-Type': 'application/json',
    'X-App-From': 'ReSole_Frontend',
    'X-App-Name': process.env.VUE_APP_NAME
  },
})

api.interceptors.request.use(
  (config) => {
    // config.headers['X-Requested-With'] = 'XMLHttpRequest'
    config.headers['X-Localization'] = store.getters['app/locale'] || 'en'
    const token = store.getters['auth/token'] // Retrieve token from storage
    if (token) config.headers['Authorization'] = `Bearer ${token}` // Set token for calls
    return config
  },
  (error) => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const token = store.getters['auth/token']
      if(token) {
        router.push({ name: 'unauthorized' })
        return Promise.resolve({error: 'unauthorized'})
      }else{
        console.log('[api interceptors]', error)
        auth.logout()
        return Promise.resolve({error: 'unlogged'})
      }
    }
    //router.push({ name: 'login' }).catch(e => console.log(e))
    return Promise.reject(error)
  }
)
export default {
  constructor(){},

  // metodo get per recuperare le entità (se aggiungo id recupera la singola entità)
  async fetch(model, id, params){
    let call = id ? `${model}/${id}` : `${model}`
    return await api.get(call, params).then(response => {
      return response.data
    }).catch(function (error) {
      return {success: false, data: error}
    });
  },

  // metodo patch per aggiornare l'entità
  async update(model, id, params, related = null, related_id = null){
    let url = related && related_id ? `${model}/${id}/${related}/${related_id}` : related && !related_id ?  `${model}/${id}/${related} ` : `${model}/${id}`
    return await api.patch(url, params).then(response => {
      return response.data
    }).catch(function (error) {
      return {success: false, data: error}
    });
  },

  // metodo post per aggiungere una nuova entità
  async create(model, params){
    return await api.post(`${model}`, params).then(response => {
      return response.data
    }).catch(function (error) {
      return {success: false, data: error}
    });
  },

  // metodo post per eliminare l'entità
  async delete(model, id){
    return await api.delete(`${model}/${id}`).then(response => {
      return response.data
    }).catch(function (error) {
      return {success: false, data: error}
    });
  },

  // metodo post per elaborare i dati in batch
  async batch(mode, model, params){
    switch(mode){
      case 'delete':// eliminazione degli elementi in batch
        return await api.post(`${model}/batch/delete`, params).then(response => {
          return response.data
        }).catch(function (error) {
          return {success: false, data: error}
        });
    }
  },

  // metodo per scaricare il file
  async download(url, params){
    api.defaults.responseType = 'blob'
    return await api.post(url, params).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', params.filename);
      document.body.appendChild(link);
      link.click();
      api.defaults.responseType = 'json'
      return {success: true, data: params.filename}
    }).catch(function (error) {
      return {success: false, data: error}
    });
  },

  async upload(formData, config){
    return await api.post('/upload', formData, config)
      .then(response => {
          return {success: true, data: response}
          
          // after store model File in archive with filename...
          // formData.append('file', response.data.filename);
          // return api.post('files', formData, config).then(response => {
          //   return {success: true, data: response}
          // })
          // .catch(function (error) {
          //   return {success: false, data: error}
          // });
      })
      .catch(function (error) {
        return {success: false, data: error}
      });
  }
}