<template>
  <div class="archeological_archives">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.rometransformed.views.archeological_archives.subtitle') }}
      </template>
      <template v-slot:main>
        <CrudTable 
          :title="$t('plugins.rometransformed.views.archeological_archives.title')" 
          model="archeological_archives"
          model-name-field="code"
          :columns="[
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.code'), field: 'code'},
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.name'), field: 'name'},
            {name: $t('plugins.rometransformed.views.archeological_archives.fields.archive'), parent: 'archive', field: 'name'},
            {field: 'actions'},
          ]"
          :cards-importer="false"
          :search-filters="[
            {field: 'code',     type: 'text',   label: $t('plugins.rometransformed.views.archeological_archives.fields.code')},
            {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: $t('plugins.rometransformed.views.archeological_archives.fields.archive'), placeholder: $t('views.archives.select')}
          ]"
        />
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import CrudTable from '@/components/CrudTable'

export default {
  name: 'ArcheologicalArchives',
  components: {
    BaseLayout, CrudTable
  }
}
</script>