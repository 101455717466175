<template>
  <div class="map">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.webgis.title') }}
      </template>
      <template v-slot:main>
        <Map model="gis/all" height="800px" 
        :wmsLayers="[
          {
            name: 'Carta Geometrica della Toscana (G. Inghirami - 1830)',
            visible: true,
            format: 'image/png',
            url: 'http://www502.regione.toscana.it/wmsraster/com.rt.wms.RTmap/wms?map=wmscastore&map_resolution=91&language=ita',
            layer: 'r_toscan_cst_inghirami',
            transparent: true,
            attribution: 'WMS Castore, Regione Toscana'
          },
          {
            name: 'Catasto Storico Toscana - Continuo territoriale dei Fogli',
            visible: true,
            format: 'image/png',
            url: 'http://www502.regione.toscana.it/wmsraster/com.rt.wms.RTmap/wms?map=wmscastore&map_resolution=91&language=ita',
            layer: 'r_toscan_cst_fogli',
            transparent: true,
            attribution: 'WMS Castore, Regione Toscana'
          }
        ]"
        /><!--:layer="data" -->
      </template>
    </BaseLayout>   
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'
import Map from '@/components/Map'

export default {
  name: 'ProjectWebGIS',
  props: {
    mode: {
      type: String,
      default: 'edit' // new
    }
  },
  data() {
    return {
      id: null,
      // data: {
      //   url: 'http://rometransformed.test/uploads/layers/ortofoto-aoi.jpg',
      //   crs: '3004',
      //   bounds: [
      //       [2312643.6846, 4640677.6000],
      //       [2314483.3216, 4639912.8000]
      //   ]
      // }
    }
  },
  components: {
    BaseLayout, Map
  },
  async beforeMount(){    
    // if(this.mode === 'edit') this.id = this.$route.params.id
  }
}
</script>