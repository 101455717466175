<template>
  <div class="map">
    <div v-if="showMap" :style="'height: '+height+' width: 100%'">
      <l-map
        ref="map"
        no-blocking-animations
        :zoom="zoom"
        :center="getCenter()"
        :style="'height: '+height"
        :continuousWorld="tileOptions.continuousWorld"
        :worldCopyJump="tileOptions.worldCopyJump"
        :options="mapOptions"
        @ready="initMap()"
        @click="showPopup"
      ><!-- @click="test" :options="mapOptions"-->

        <l-tile-layer :url="url" :attribution="attribution" :options="tileOptions" />
        <l-control-layers :options="controlLayersOptions"/>
        <l-wms-tile-layer
          v-for="(layer, index) in wmsLayerGroup"
            :key="'wms-layer-'+index"
            :base-url="layer.url"
            :layers="layer.layer"
            :visible="layer.visible"
            :format="layer.format"
            :transparent="layer.transparent"
            :name="layer.name"
            :attribution="layer.attribution"
            layer-type="base"
        />
        
        <!--<div v-if="rasters">
          <div v-for="(raster, index) in rasters" :key="'raster-'+index">
            <l-image-overlay  :url="raster.url" :bounds="raster.bounds" :options="overlayOptions" />
          </div>
        </div>-->
        
        <!--<div v-if="markers && markers.length > 0">
          <div v-for="(marker, index) in markers" :key="'marker-'+index">
            <l-marker :lat-lng="coords(marker.coordinates)">
              <l-popup>
                <div>
                  <div class="bg-primary text-white p-3 rounded-t-md text-xs">{{ marker.model }}</div>
                  <div class="p-3 flex flex-row items-center">
                    <div class="text-sm">{{ marker.name }}</div>
                    <router-link :to="marker.url" class="ml-2 py-1 px-2 border-2 border-opacity-0 rounded-md transition-all hover:border-opacity-100 hover:bg-opacity-0 border-green-500 bg-green-500 w-6 h-6 items-center justify-center flex text-xs text-white !important hover:text-green-500"><f-icon icon="pen-to-square" /></router-link>
                  </div>
                </div>
              </l-popup>
            </l-marker>
          </div>
        </div>

        <div v-if="polygons && polygons.length > 0">
          <div v-for="(polygon, index) in polygons" :key="'polygon-'+index">
            <l-polygon :lat-lngs="polygon.coordinates" :color="polygon.color">
              <l-popup>
                <div>
                  <div class="bg-primary text-white p-3 rounded-t-md text-xs">{{ polygon.model }}</div>
                  <div class="p-3 flex flex-row items-center">
                    <div class="text-sm">{{ polygon.name }}</div>
                    <router-link :to="polygon.url" class="ml-2 py-1 px-2 border-2 border-opacity-0 rounded-md transition-all hover:border-opacity-100 hover:bg-opacity-0 border-green-500 bg-green-500 w-6 h-6 items-center justify-center flex text-xs text-white !important hover:text-green-500"><f-icon icon="pen-to-square" /></router-link>
                  </div>
                </div>
              </l-popup>
            </l-polygon>
          </div>
        </div>-->

        <div v-if="popupShow && popup" class="absolute top-[60px] w-full shadow-2xl flex flex-col items-center">
          <div v-for="(element, index) in popup" :key="'popup-'+index" class="bg-white rounded-lg shadow-2xl z-[999] p-3 flex flex-col items-center border-2 border-slate-200">
            <div class="mb-2 text-base">{{ element.name }}</div>
            <div class="grid grid-cols-3 gap-y-1">
              <div class="col-span-1 px-2 py-2 rounded-l bg-slate-200 text-right">{{ $t('components.locations_manager.location_type') }}</div>
              <div class="col-span-2 text-green-800 bg-slate-100 px-2 py-2 rounded-r"><f-icon icon="vector-square" /> {{ element.type }}</div>
              <div class="col-span-1 px-2 py-2 rounded-l bg-slate-200 text-right">{{ $t('components.locations_manager.location_cards') }}</div>
              <div class="px-2 py-2 bg-slate-100 rounded-r col-span-2">
                <div>{{ element.entity_code }} <span class="bg-blue-700 text-white px-2 py-1 rounded-md"> {{ element.entity_model }}</span></div>
              </div>
            </div>
          </div>
        </div>

      </l-map>
    </div>      
    <div v-else class="bg-gray-200 p-5 text-center"> {{ $t('components.map.no_data') }} </div>
  </div>
</template>

<script>

import { latLng, point, Util } from "leaflet";
import proj4 from "proj4";
import * as Proj from "proj4leaflet";

import api from '@/services/api'

  /*
  // Coordinate system is EPSG:28992 / Amersfoort / RD New
  var imageBounds = L.bounds(
    [145323.20011251318, 475418.56045463786],
    [175428.80013969325, 499072.9604685671]
  );
  L.Proj.imageOverlay('http://geo.flevoland.nl/arcgis/rest/services/Groen_Natuur/Agrarische_Natuur/MapServer/export?' +
    'format=png24&transparent=true&f=image&bboxSR=28992&imageSR=28992&layers=show%3A0' +
    '&bbox=145323.20011251318%2C475418.56045463786%2C175428.80013969325%2C499072.9604685671&size=560%2C440',
    imageBounds);
  */

export default {
  name: 'Map',
  props: {
    layer: {
      type: Object,
      default: null
    },
    id: {
      type: Number,
      default: null
    },
    model: {
      type: String,
      default: 'cards',
    },
    related: {
      type: Boolean,
      default: false
    },
    points: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: '500px'
    },
    wmsLayers: {
      type: Array,
    }
  },
  data: function(){
    return {
      entity: {},
      canLoad: false,
      zoom: 8,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', 
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      showMap: false,
      crs: null,
      rasters: [],
      center: null,
      bounds: [],
      layers: null,
      clickedLayers: null,
      markers: [],
      polygons: [],
      overlayOptions: {
        crossOrigin: false,
        zIndex: 1,
        className: 'raster',
        opacity: 0.7
      },
      mapOptions: {
        zoomSnap: 1,
        scrollWheelZoom: false
      },
      tileOptions: {
        maxZoom: 18, // maxZoom: testCRS.options.resolutions.length,
        maxNativeZoom: 18,
        minZoom: 0,
        continuousWorld: true,
        worldCopyJump: false
      },
      mapMetas: {
        proj4: proj4
      },
      popupShow: false,
      popup: [],
      controlLayersOptions: {
        position: 'bottomright'
      },
      wmsLayerGroup: []
    }
  },
  async created(){
    const vm = this

    vm.layers = new window.L.FeatureGroup()
    vm.url = process.env.VUE_APP_MAP_TILES || 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    vm.attribution = process.env.VUE_APP_MAP_ATTRIBUTION || '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    vm.tileOptions.minZoom = process.env.VUE_APP_MAP_MIN_ZOOM || 1
    vm.tileOptions.maxZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18
    vm.tileOptions.maxNativeZoom = process.env.VUE_APP_MAP_MAX_ZOOM || 18

    let baseMap = {
      name: this.$t('components.locations_manager.base_map_name'),
      visible: true,
      format: 'image/png',
      url: vm.url,
      // transparent: true,
      // attribution: ''
    }
    vm.wmsLayerGroup = [baseMap]
    if(vm.wmsLayers && vm.wmsLayers.length > 0) vm.wmsLayerGroup = [...vm.wmsLayers, ...[baseMap]]

    // if raster layer is requested
    // if(vm.layer){
    //   if(vm.layer.crs) vm.setupCrs(vm.layer.crs)
    //   vm.bounds = [
    //     vm.convert(vm.layer.bounds[0]),
    //     vm.convert(vm.layer.bounds[1])
    //   ]
    //   let raster = {
    //    url: vm.layer.url,
    //    crs: vm.layer.crs || process.env.VUE_APP_BASE_SRID,
    //    bounds: [
    //     vm.convert(vm.layer.bounds[0]),
    //     vm.convert(vm.layer.bounds[1])
    //    ]
    //   }
    //   vm.rasters.push(raster)
    //   vm.showMap = true
    // }

    // if points (markers) is requested
    // if(vm.points && vm.points.length > 0){
    //   vm.markers = vm.points
    //   vm.showMap = true
    // }

  },
  async mounted(){
    const vm = this
    vm.layers.eachLayer(function (layer) {
      vm.layers.removeLayer(layer);
    })
    
    // handle rasters of a single entity
    // if(vm.model && vm.id){
    //   vm.entity = await api.fetch(vm.model, vm.id)
    //   if(vm.related) vm.entity = vm.entity.card // if exteded card loaded
    //   if(vm.entity && vm.entity.files){
    //     vm.entity.files.forEach(file => {
    //       if(file.web && file.extra_detail && file.extra_detail.ne_lat){
    //         vm.setupCrs(file.extra_detail.crs || process.env.VUE_APP_BASE_SRID)
    //         let raster = {
    //           url: file.web[0]['file'],
    //           crs: file.extra_detail.crs || process.env.VUE_APP_BASE_SRID,
    //           bounds: [
    //             vm.convert([parseFloat(file.extra_detail.ne_lat), parseFloat(file.extra_detail.ne_lng)]),
    //             vm.convert([parseFloat(file.extra_detail.sw_lat), parseFloat(file.extra_detail.sw_lng)])
    //           ]
    //         }
    //         vm.rasters.push(raster)
    //       }
    //     });
    //     if(vm.rasters.length > 0) vm.showMap = true
    //   }
    // }

    // handle parsered geografic data from all api's
    if(vm.model && !vm.id){
      await api.fetch(vm.model, null, {'params': {'per_page': '999999'}}).then(response => {
        //console.log(response.data)
        response.data.forEach(entity => {
          if(entity.locations && entity.locations.length > 0){
            let coords = []
            entity.locations.forEach(location => {
              location.entity_code = entity.code
              location.entity_model = entity.model_name
              if(!!location.pointz || !!location.location){
                location.geometry = location.pointz || location.location
                location.type = location.geometry.type
                //console.info('[parsing entity]', entity.code, location.geometry.coordinates)
                location.geometry = new window.L.Marker([location.geometry.coordinates[1],location.geometry.coordinates[0]], {
                  icon: vm.getIcon(location)
                }).on('click', function(event){
                  vm.popup = []
                  vm.popupShow = false
                  vm.createPopup(event.latlng, [location])
                })
              }
              if(!!location.polygon || !!location.polygonz){
                coords = []
                location.geometry = location.polygon || location.polygonz
                location.type = location.geometry.type
                location.geometry.coordinates[0].forEach(element => {
                  coords.push([element[1],element[0]])
                })
                location.geometry = new window.L.Polygon(coords)
                location.geometry.setStyle({
                  color: location.owned ? '#14532d' : '#3388ff',
                })
              }
              if(location.geometry){
                location.geometry.id = location.id
                location.geometry.name = location.name
                location.geometry.type = location.type
                vm.layers.addLayer(location.geometry)
              }
            })
          }
        })
      }).then(()=>{
        this.showMap = true
      })
    }
  },
  methods: {
    initMap() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          const vm = this
          vm.map = vm.$refs.map.mapObject
          vm.layers.addTo(vm.map)
          vm.map.fitBounds(vm.getLayersBounds())
        })
      })
    },
    fitMap(){
      const vm = this
      vm.map = vm.$refs.map.mapObject
      vm.map.fitBounds(vm.getLayersBounds())
    },
    getLayersBounds(){
      // Fix wrong getBounds calculations
      const vm = this
      const layersBounds = vm.layers.getBounds()
      const ne_lat = layersBounds.getNorthEast().lat.toString()
      const ne_lng = layersBounds.getNorthEast().lng.toString()
      const sw_lat = layersBounds.getSouthWest().lat.toString()
      const sw_lng = layersBounds.getSouthWest().lng.toString()
      // console.log(layersBounds.getNorthEast(), 
      //   ne_lat, ne_lat.toString().indexOf(".") !== -1, 
      //   ne_lng, ne_lng.toString().indexOf(".") !== -1,
      //   sw_lat, sw_lat.toString().indexOf(".") !== -1, 
      //   sw_lng, sw_lng.toString().indexOf(".") !== -1
      // ) 
      const bounds = [
        [
          ne_lat.indexOf(".") == -1 ? ne_lat.slice(0, 2) + "." + ne_lat.slice(2) : ne_lat,
          ne_lng.indexOf(".") == -1 ? ne_lng.slice(0, 2) + "." + ne_lng.slice(2) : ne_lng
        ],
        [
          sw_lat.indexOf(".") == -1 ? sw_lat.slice(0, 2) + "." + sw_lat.slice(2) : sw_lat,
          sw_lng.indexOf(".") == -1 ? sw_lng.slice(0, 2) + "." + sw_lng.slice(2) : sw_lng
        ],
      ]
      return bounds
    },
    getIcon() {
      return window.L.divIcon({
        className: "my-custom-pin",
        html: `<div class="shadow-2xl rounded-xl w-4 h-4 bg-blue-700 border-2 border-white"></div>`
      })
    },
    showPopup(event) {
      const vm = this
      vm.popup = []
      vm.popupShow = false
      vm.clickedLayers = []
      console.log(event, vm.layers)
      vm.layers.eachLayer(function(layer) {
        if(vm.contains(layer, event.latlng)) { // point.getLatLng()
          vm.clickedLayers.push(layer)
        }
      })
      if(vm.clickedLayers.length > 0) {
        vm.createPopup(event.latlng, vm.clickedLayers)
      }
    },
    createPopup(latlng, data){
      const vm = this
      data.forEach(element => {
        vm.popup.push(element)
      })
      vm.popupShow = true
    },
    getCenter() { 
      if(this.bounds.length > 0) {
        let centroid = [
          (this.bounds[0].lat + this.bounds[1].lat)/2,
          (this.bounds[0].lng + this.bounds[1].lng)/2
        ]
        return latLng(centroid[0],centroid[1])
      }
      else if(this.rasters.length > 0){
        let centroid = [
          (this.rasters[0].bounds[0].lat + this.rasters[0].bounds[1].lat)/2,
          (this.rasters[0].bounds[0].lng + this.rasters[0].bounds[1].lng)/2
        ]
        return latLng(centroid[0],centroid[1])
      } else {
        let defaultCenter = (process.env.VUE_APP_BASE_LAT && process.env.VUE_APP_BASE_LNG) 
        ? [parseFloat(process.env.VUE_APP_BASE_LNG), parseFloat(process.env.VUE_APP_BASE_LAT)]
        : [parseFloat(12.46), parseFloat(40.93)]
        if(this.crs) defaultCenter = this.convert(defaultCenter)
        return latLng(defaultCenter[0],defaultCenter[1])
      }
    },
    convert(coords){
      return this.crs.projection.unproject(new point(coords[1], coords[0]))
    },
    coords(coordinates){
      return latLng(coordinates[1], coordinates[0])
    },
    innerClick(url){
      this.$router.push({path: `/${url}`})
    },
    setupCrs(crs){
      // TODO: prevedere in futuro ulteriori CRS ed esternalizzarli dal componente
      switch(crs){
        case '3004':
        default:
          this.crs = new Proj.CRS(
            'EPSG:3004',
            '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +units=m +no_defs',
            {
              resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625, 0.03125, 0.015625, 0.0078125, 0.00390625],
              origin: [12.46, 40.93]
            }
          )
          break
      }
    },
    test(e){
      console.log(e.latlng, this.crs.projection.project(e.latlng))
      this.markers.push(latLng(e.latlng))
    },
    contains(geom, p) {
      if(geom._latlngs !== undefined){
        var wn
        wn = this.getWindingNumber(geom, p)
        return (wn !== 0)
      }
      return false
    },
    isLeft(geom, p1, p2) {
      return ((p1.lng - geom.lng) * (p2.lat - geom.lat) -
              (p2.lng - geom.lng) * (p1.lat - geom.lat))
    },
    flatten(a) {
      const vm = this
      var flat
      flat = ((Array.isArray ? Array.isArray(a) : Util.isArray(a)) ? a.reduce(function (accumulator, v) { // i, array
          return accumulator.concat(Array.isArray(v) ? vm.flatten(v) : v)
        }, [])
             : a)
      return flat
    },
    getWindingNumber(geom, p) { // Note that L.Polygon extends L.Polyline
      var i,
          isLeftTest,
          n,
          vertices,
          wn // the winding number counter
      vertices = geom.getLatLngs()
      vertices = this.flatten(vertices) // Flatten array of LatLngs since multi-polylines return nested array.
      // Filter out duplicate vertices.
      vertices = vertices.filter(function (v, i, array) { // remove adjacent duplicates
        if (i > 0 && v.lat === array[i-1].lat && v.lng === array[i-1].lng) {
            return false
        } else {
            return true
        }
      })
      n = vertices.length
      // Note that per the algorithm, the vertices (V) must be "a vertex points of a polygon V[n+1] with V[n]=V[0]"
      if (n > 0 && !(vertices[n-1].lat === vertices[0].lat && vertices[n-1].lng === vertices[0].lng)) {
        vertices.push(vertices[0])
      }
      n = vertices.length - 1
      wn = 0
      for (i=0; i < n; i++) {
        isLeftTest = this.isLeft(vertices[i], vertices[i+1], p)
        if(isLeftTest === 0) { // If the point is on a line, we are done.
          wn = 1
          break
        }else{
          if(isLeftTest !== 0) { // If not a vertex or on line (the C++ version does not check for this)
            if(vertices[i].lat <= p.lat) {
              if(vertices[i+1].lat > p.lat) { // An upward crossing
                if(isLeftTest > 0) { // P left of edge
                   wn++ // have a valid up intersect
                }
              }
            }else{
              if(vertices[i+1].lat <= p.lat) {// A downward crossing
                if(isLeftTest < 0) { // P right of edge
                  wn-- // have a valid down intersect
                }
              }
            }
          } else {
            wn++
          }
        }
      }
      return wn
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-control-container {
  display: flex;
  .leaflet-top.leaflet-left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
  }
}
.leaflet-popup-content-wrapper {
  @apply rounded-md bg-gray-200 p-0;
  .leaflet-popup-content {
    margin: 0px;
  }
}
.leaflet-popup-tip-container {
  .leaflet-popup-tip {
    @apply bg-gray-200;
  }
}
.leaflet-container a.leaflet-popup-close-button {
  @apply text-white;
}
</style>
