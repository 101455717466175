<template>
  <nav :class="classes + ' bg-white sticky top-0 z-top rounded shadow-lg ' + (layout === 'horizontal' ? 'flex flex-row justify-between' : '')">

    <!-- Brand -->
    <div :class="layout === 'horizontal' ? 'bg-primary flex flex-col justify-center' : 'grid grid-cols-5 bg-primary'">
      <button v-if="layout !== 'horizontal'" :class="'text-white outline-none py-4 ' + (showNav ? 'col-span-1' : 'col-span-5')" @click="toggleNav()"><f-icon :icon="showNav ? 'bars' : 'bars'" /></button>
      <div v-if="showNav" :class="layout === 'horizontal' ? ' h-16' : 'col-span-4'">
        <img class="px-4 py-1 h-full" v-if="checkLogo()" :src="getLogo()" :alt="appName" />
        <span v-else class="text-2xl leading-tight text-white">{{ showNav ? appName : initials(appName) }}</span>
      </div>
    </div>

    <!-- User profile -->
    <div v-if="layout !== 'horizontal' && isAuthenticated" class="flex flex-col items-center my-3 mb-3">
      <div class="flex flex-row justify-start">
        <div class=" w-full relative" key="userSign" @click="toggleUserMenu()">
          <div class="flex items-center justify-center">
            <div class="h-50 w-50 inline-flex items-center justify-center mr-2 rounded-full uppercase text-sm font-bold leading-none cursor-pointer bg-primary text-white">{{ sign }}</div>
            <span v-if="showNav" class="font-semibold text-gray-800 capitalize tracking-wide cursor-pointer mr-1">
              {{user.name}}
            </span>
            <f-icon class="text-gray-800 text-xs cursor-pointer" :icon="showUserMenu ? 'caret-up' : 'caret-down'" />
          </div>
          <div :class="(showUserMenu ? 'show' : 'hidden') + ' min-w-max absolute left-0 z-50 bg-white rounded-lg shadow-2xl mt-1 border-gray-400 noselect'">
            <div class="text-center px-8 py-2 bg-primary text-white rounded-t-lg">
              <!--<span class="font-semibold capitalize tracking-wide">
                {{user.name}}
              </span>-->
              <span v-if="roles" class="roles flex flex-col items-center space-x-1">
                <f-icon class="text-xs" icon="user-tag" /> 
                <span v-for="(role, index) in roles" :key="'role-'+index" class="text-xs tracking-wide "><span class="">{{ $t('roles.'+role.name) }}</span>
                </span>
              </span>
            </div>
            <div v-if="archives" class="roles flex items-center space-x-1 px-4 pt-4 pb-2 bg-gray-200">
              <div class="flex-col flex">
                  <span v-for="(archive, index) in archives" :key="'role-'+index" class="text-xs tracking-wide pb-2">
                    <f-icon class="text-gray-600 text-xs mr-1" icon="box-archive" /><span class="text-gray-600">{{ archive.name }}</span>
                  </span>
              </div>
            </div>
            <ul :class="' text-base float-left py-2 list-none text-left bg-clip-padding border-none p-2'">
              <li v-if="isAuthenticated">
                <div class="flex flex-row items-center mb-2">
                  <button :class="'py-1 px-2 text-xs hover:bg-gray-300 focus:bg-gray-200 focus:shadow-outline rounded-md mr-2 ' + ($i18n.locale === 'en' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 ')" @click="switchLang('en')">ENG</button>
                  <button :class="'py-1 px-2 text-xs  hover:bg-gray-300 focus:bg-gray-200 focus:shadow-outline rounded-md mr-2 ' + (!$i18n.locale || $i18n.locale === 'it' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 ')" @click="switchLang(null)">ITA</button>
                </div>
              </li>
              <li v-if="isAuthenticated">
                  <router-link to="/profile" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md text-xs hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
                      <span class="text-gray-600"><f-icon icon="user" /></span>
                      <span>{{ $t('menu.profile') }}</span>
                  </router-link>
              </li>
              <li v-if="isAuthenticated">
                <button @click="logout" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md text-xs hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
                  <span class="text-gray-600"><f-icon icon="right-from-bracket" /></span>
                  <span>{{ $t('menu.logout') }}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Menu items for authenticated users -->
    <div class="flex flex-row" v-if="isAuthenticated">
      <Dropdown v-for="(item, index) in menuItems" :key="'menu-item-'+index" :menu-items="item" />
    </div>

    <!-- <ul class="space-y-2 text-sm m-3" v-if="isAuthenticated" @click="hideUserMenu()">
      <li>
        <hr>
      </li>
      <li v-for="(item, index) in menuItems" :key="'menu-'+index">
        <div v-if="item.route === 'separator'">
          <hr />
          <div v-if="item.label && showNav" class="text-gray-500 uppercase text-xs mb-2 pl-2 pt-5" v-text="$t(item.label)" />
        </div>
        <router-link v-else :to="item.route" class="has-tooltip flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline" >
          <div class="text-gray-600 w-4 flex justify-center" :class="showNav ? '' : 'ml-4'"><f-icon :icon="item.icon" /></div>
          <div v-if="showNav" v-text="$t(item.label)" />
          <div v-if="!showNav" class="tooltip pl-6"><span class="rounded shadow-lg p-1 bg-gray-100 text-gray-500">{{ $t(item.label) }}</span></div>
        </router-link>
      </li>
      <li>
        <hr>
      </li>      
    </ul> -->

    <!-- Menu items for unauthenticated users -->
    <ul class="flex flex-row" v-if="!isAuthenticated" @click="hideUserMenu()">
      <Dropdown v-for="(item, index) in unloggedMenuItems" :key="'menu-item-'+index" :menu-items="item" />
      <!-- <li v-if="!isAuthenticated">
          <router-link to="/login" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
              <span class="text-gray-600"><f-icon icon="right-to-bracket" /></span>
              <span v-if="showNav" >{{ $t('menu.login') }}</span>
          </router-link>
      </li>
      <li v-if="!isAuthenticated">
          <router-link to="/register" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
              <span class="text-gray-600"><f-icon icon="plus" /></span>
              <span>{{ $t('menu.register') }}</span>
          </router-link>
      </li>  -->
        <!--<li v-if="isAuthenticated && 1==2">
          <a href="#" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md font-medium hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
              <span class="text-gray-600"><f-icon icon="plus" /></span>
              <span>Notifications</span>
          </a>
      </li>-->
    </ul>

    <!-- User profile -->
    <div v-if="layout === 'horizontal' && isAuthenticated" class="flex flex-col items-center my-3 mb-3 pr-1">
      <div class="flex flex-row justify-start">
        <div class=" w-full relative" key="userSign" @click="toggleUserMenu()">
          <div class="flex items-center justify-center">
            <div class="h-50 w-50 inline-flex items-center justify-center mr-2 rounded-full uppercase text-sm font-bold leading-none cursor-pointer bg-primary text-white">{{ sign }}</div>
            <!-- <span v-if="showNav" class="font-semibold text-gray-800 capitalize tracking-wide cursor-pointer mr-1">
              {{user.name}}
            </span> -->
            <!-- <f-icon class="text-gray-800 text-xs cursor-pointer" :icon="showUserMenu ? 'caret-up' : 'caret-down'" /> -->
          </div>
          <div :class="(showUserMenu ? 'show' : 'hidden') + ' min-w-max absolute ' + (layout === 'horizontal' ? 'right-2' : 'left-0') + ' z-50 bg-white rounded-lg shadow-2xl mt-1 border-gray-400 noselect'">
            <div class="text-center px-8 py-2 bg-primary text-white rounded-t-lg">
              <!--<span class="font-semibold capitalize tracking-wide">
                {{user.name}}
              </span>-->
              <span v-if="roles" class="roles flex flex-col items-center space-x-1">
                <f-icon class="text-xs" icon="user-tag" /> 
                <span v-for="(role, index) in roles" :key="'role-'+index" class="text-xs tracking-wide "><span class="">{{ $t('roles.'+role.name) }}</span>
                </span>
              </span>
            </div>
            <div v-if="archives" class="roles flex items-center space-x-1 px-4 pt-4 pb-2 bg-gray-200">
              <div class="flex-col flex">
                  <span v-for="(archive, index) in archives" :key="'role-'+index" class="text-xs tracking-wide pb-2">
                    <f-icon class="text-gray-600 text-xs mr-1" icon="box-archive" /><span class="text-gray-600">{{ archive.name }}</span>
                  </span>
              </div>
            </div>
            <ul :class="' text-base float-left py-2 list-none text-left bg-clip-padding border-none p-2'">
              <li v-if="isAuthenticated">
                <div class="flex flex-row items-center mb-2">
                  <button :class="'py-1 px-2 text-xs hover:bg-gray-300 focus:bg-gray-200 focus:shadow-outline rounded-md mr-2 ' + ($i18n.locale === 'en' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 ')" @click="switchLang('en')">ENG</button>
                  <button :class="'py-1 px-2 text-xs  hover:bg-gray-300 focus:bg-gray-200 focus:shadow-outline rounded-md mr-2 ' + (!$i18n.locale || $i18n.locale === 'it' ? 'bg-primary text-white' : 'bg-gray-200 text-gray-700 ')" @click="switchLang(null)">ITA</button>
                </div>
              </li>
              <li v-if="isAuthenticated">
                  <router-link to="/profile" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md text-xs hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
                      <span class="text-gray-600"><f-icon icon="user" /></span>
                      <span>{{ $t('menu.profile') }}</span>
                  </router-link>
              </li>
              <li v-if="isAuthenticated">
                <a href="#" @click="logout" class="flex items-center space-x-3 text-gray-700 p-2 rounded-md text-xs hover:bg-gray-200 focus:bg-gray-200 focus:shadow-outline">
                  <span class="text-gray-600"><f-icon icon="right-from-bracket" /></span>
                  <span>{{ $t('menu.logout') }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </nav>
</template>

<script type="text/javascript">

import _ from 'lodash'
import { mapGetters } from 'vuex'
import auth from '@/services/auth'
import config from '@/config'

import Dropdown from '@/components/Dropdown'

export default {
  name: 'NavBar',
  props: {
    classes: {
      type: String
    },
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  components: {
    Dropdown
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      user: 'auth/user',
      locale: 'app/locale',
      showUserMenu: 'app/showUserMenu'
    }),
    sign() {
      return this.user.name ? this.user.name.substr(0,1) : 'R'
    },
    roles() {
      if(this.user.roles) {
        return this.user.roles.filter( function(role){
          return 'name' in role;
        }).map( function( role ){
          return { 'name' : role['name'] }
        });
      }else{
        return null
      }
    },
    archives() {
      if(this.user.archives) {
        return this.user.archives.filter( function(archive){
          return 'name' in archive;
        }).map( function( archive ){
          return { 'name' : archive['name'] }
        });
      }else{
        return null
      }
    },
    appName() {
      return process.env.VUE_APP_NAME || 'ReSole'
    },
  },
  data: () => ({
    isOn: false,
    showNav: true,
    menuItems: [
      {route: '/home', permission: ['all'], label: 'menu.home', icon: 'chart-pie'}
    ],
    unloggedMenuItems: [
      {route: '/login', permissions: ['all'], label: 'menu.login', icon: 'right-to-bracket'},
      {route: '/register', permissions: ['all'], label: 'menu.register', icon: 'plus'},
    ]
  }),
  created(){
    // add default config menu items and from plugins
    //this.menuItems = [...this.plugins.menu, ...config.menuItems]
    config.menuItems.filter((item) => {
      if(item.label === 'menu.cards') item.items = [...item.items, ..._.clone(this.plugins.menu.cards)]
      if(item.label === 'menu.taxonomies') item.items = [...item.items, ..._.clone(this.plugins.menu.taxonomies)]
    })
    this.menuItems = _.clone(config.menuItems)

    this.$i18n.locale = this.locale || 'it' // get locale from state
  },
  mounted(){
    // filter menu items based on user's permissions
    if(this.user.permissions) this.menuItems = this.menuItems.filter((item) => { 
      let check = false
      item.permissions.forEach(permission => {
        if(!check) check = this.user.permissions.includes(permission) || permission === 'all'
      })
      if(item.items && item.items.length > 0) {
        item.items = item.items.filter((subitem) => { 
          let check = false
          subitem.permissions.forEach(permission => {
            if(!check) check = this.user.permissions.includes(permission) || permission === 'all'
          })
          return check
        })
      }
      return check
    })
  },
  methods: {
    async logout(){
      await auth.logout().then(async () => {
        this.$toasted.info(this.$i18n.t('global.success.logout'))
        this.$router.push({name: 'login'}).catch(e => console.log(e))
      }).catch(e => { console.log('[logout errors]', e) })
    },
    switchLang(locale){
      this.$store.dispatch(`app/switchLocale`, {locale: locale || 'it'})
      this.$i18n.locale = locale || 'it'
    },
    toggleUserMenu(){
      this.$store.dispatch(`app/showUserMenu`, {showUserMenu: !this.showUserMenu})
    },
    hideUserMenu(){
      this.$store.dispatch(`app/showUserMenu`, {showUserMenu: false})
    },
    toggleNav(){
      this.showNav = !this.showNav
      this.$emit('showNav', this.showNav)
    },
    initials(name){
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      let initials = [...name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();
      return initials
    },
    checkLogo(){
      return process.env.VUE_APP_LOGO
    },
    getLogo(){
      return process.env.VUE_APP_LOGO ? require(process.env.VUE_APP_LOGO) : null
    }
  }
}
</script>
<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
a:hover {
  cursor: pointer;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>