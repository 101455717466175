var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stratigraphies"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.rometransformed.views.stratigraphies.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"card","has-location":true,"fields":[

          // Base Card fields
          // Base Card fields
          {text: _vm.$t('views.cards.fields.card_separator'), type: 'separator'},
          {name: _vm.$t('views.cards.fields.code'), field: 'code', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.name'), field: 'name', related: 'card', type: 'text'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', type: 'checkbox', ttip: _vm.$t('plugins.rometransformed.views.stratigraphies.tips.is_visible')},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', type: 'checkbox', tip: _vm.$t('plugins.rometransformed.views.stratigraphies.tips.is_published')},
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.archive'), field: 'archive_id', related: 'card', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('views.cards.fields.user_id'), field: 'user_id', related: 'card', type: 'select', model: 'users', labels: ['name', 'surname'], select: _vm.select_user },
          {name: _vm.$t('views.cards.fields.tags'), field: 'tags', model: 'tags', related: 'card', type: 'multi-select', span: '2', label: 'name', taggable: true},

          // Main fields
          {text: '', type: 'separator'},
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'), 
            field: 'description', type: 'text-editor', span: '2'},
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.bibliography'), 
            field: 'bibliography', type: 'text-editor', span: '2'},
    
          // setup Lemma + pivot
          {text: _vm.$t('views.cards.fields.lemma_separator'), type: 'separator'},
          { // Zones
            field: 'zone_id', type: 'lemma', model: 'zones', label: 'name', span: '2',
            pivots: [ 
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', type: 'text'}
            ],
            name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.zone'), 
            title: _vm.$t('plugins.rometransformed.views.stratigraphies.zone.title')
          },
          { // Methodologies
            field: 'methodology_id', type: 'lemma', model: 'methodologies', label: 'name', span: '2',
            pivots: [ 
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', type: 'text'}
            ],
            name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.methodology'), 
            title: _vm.$t('plugins.rometransformed.views.stratigraphies.methodology.title')
          },
          { // Types
            field: 'type_id', type: 'lemma', model: 'types', label: 'name', span: '2',
            pivots: [ 
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'),     field: 'description', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.function'),        field: 'function', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.interpretation'),  field: 'interpretation', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.start_date'),      field: 'start_date', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.end_date'),        field: 'end_date', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.source'),          field: 'source', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.bibliography'),    field: 'bibliography', type: 'text'},
            ],
            name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.type'), 
            title: _vm.$t('plugins.rometransformed.views.stratigraphies.type.title')
          },
          { // Interpretations
            field: 'interpretation_id', type: 'lemma', model: 'interpretations', label: 'name', span: '2',
            pivots: [ 
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.description'),     field: 'description', type: 'text'},
            ],
            name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.interpretation'), 
            title: _vm.$t('plugins.rometransformed.views.stratigraphies.interpretation.title')
          },

          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.observation'), field: 'observation_id', type: 'select', model: 'observations', label: 'name' },

          // Location fields
          {text: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.location_separator'), type: 'separator'},
          {name: _vm.$t('components.locations_manager.title'), field: 'locations', related: 'card', model: 'cards', type: 'location-manager', span: '4',
            fields: [ 
              {name: _vm.$t('views.locations.fields.name'), field: 'name', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.address'), field: 'address', type: 'text', span: '2'},
              {name: _vm.$t('views.locations.fields.accuracy'), field: 'accuracy', type: 'text', span: '2'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.x'), field: 'x', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.y'), field: 'y', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.top'), field: 'top', type: 'text'},
              {name: _vm.$t('plugins.rometransformed.views.stratigraphies.fields.bottom'), field: 'bottom', type: 'text'},
              {name: _vm.$t('views.locations.fields.pointz'),       related: 'card', subrelated: 'location', field: 'pointz', type: 'geometry',      geometry: 'pointz', span: '4'},
              {name: _vm.$t('views.locations.fields.multipointz'),  related: 'card', subrelated: 'location', field: 'multipointz', type: 'geometry', geometry: 'multipointz', span: '4'}
            ],
            pivots: [ 
              {name: _vm.$t('views.cards.locations.fields.type'), field: 'type', type: 'text', span: '2'},
              {name: _vm.$t('views.cards.locations.fields.description'), field: 'description', type: 'text', span: '2'},
            ],
            pivotTitle: _vm.$t('plugins.rometransformed.views.observations.locations.pivot')
          },
        ]}}):_vm._e(),(_vm.id)?_c('div',{staticClass:"mt-2"},[(_vm.canLoad)?_c('FileManager',{attrs:{"mode":_vm.crud_mode,"model-id":parseInt(_vm.id),"model":_vm.model,"entity-data":_vm.entity,"related":true,"uploaderText":_vm.$t('plugins.rometransformed.views.stratigraphies.uploader_text'),"file-types-allowed":[
            {name: 'PNG', type: 'image/png'},
            {name: 'JPG', type: 'image/jpeg'},
            {name: 'TIFF', type: 'image/tiff'},
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'OBJ', type: 'text/plain'},
          ],"file-model-types":[
            {type: 'document', name: _vm.$t('views.cards.files.types.document'),  fields: ['description']},
            {type: 'raster',   name: _vm.$t('views.cards.files.types.raster'),    fields: ['description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
            {type: 'image', name: _vm.$t('views.cards.files.types.image'),  fields: ['description']},
            {type: 'photo', name: _vm.$t('views.cards.files.types.photo'),  fields: ['description']},
            {type: 'threed_model', name: _vm.$t('views.cards.files.types.threed_model'), fields: ['description', 'file_id']},
            {type: 'threed_texture', name: _vm.$t('views.cards.files.types.threed_texture'), fields: ['description', 'file_id']},
            {type: 'spheric_picture', name: _vm.$t('views.cards.files.types.spheric_picture'), fields: ['description']},
          ],"file-model-fields":[
            {name: _vm.$t('views.cards.files.fields.description'), field: 'description', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: _vm.$t('views.cards.files.fields.file_select')},
            {name: _vm.$t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
          ]}}):_vm._e()],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }