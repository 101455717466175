// Plugin import
import ereditaculturali from './ereditaculturali'
import rometransformed from './rometransformed'

// Add here plugins you want activate
var list = [
  ereditaculturali,
  rometransformed
]
var plugins = { models: [], routes: [], menu: {cards: [], taxonomies: []}, dashboards: [], messages: {} }

list.forEach(function(plugin) {
  plugins.routes = plugins.routes.concat(plugin.routes)
  plugins.menu.cards = plugins.menu.cards.concat(plugin.menu.cards)
  plugins.menu.taxonomies = plugins.menu.taxonomies.concat(plugin.menu.taxonomies)
  plugins.dashboards = plugins.dashboards.concat(plugin.dashboard)
  plugins.models = plugins.models.concat(plugin.models)
  plugins.messages[plugin.name] = plugin.messages
})

export default plugins