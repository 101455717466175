<template>
  <div class="modal modal-confirm">
    <div class="p-8">
      <h1 class="text-xl break-words">{{ title }}</h1>
      <p class="my-5">{{ text }}</p>
      <div class="actions mt-5 flex justify-between">
          <button class="bg-green-700 hover:bg-green-dark text-white font-bold py-2 px-4 rounded" @click="confirm()">{{ confirmText }}</button>
          <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'ModalConfirm',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    update: {}
  },
  data: function(){
    return {
      showError: false,
      error: []
    }
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async confirm(){
      this.update('confirm')
      this.$emit('close')
    },
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
