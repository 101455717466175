<template>
  <div class="scan_cards">
    <BaseLayout>
      <template v-slot:header>
        {{ $t('plugins.ereditaculturali.views.wiki.subtitle') }}
      </template>
      <template v-slot:main>
        
        <div>
          <h2 class="text-xl px-10 py-5 bg-slate-50">Wiki e documentazione ufficiale per la catalogazione</h2>
        </div>
        <div class="px-10 py-5 flex flex-col">
          <div v-for="(document, index) in documentations" :key="'doc-label-'+index" class="flex flex-row justify-between mb-5 items-center">
            <div class="p-2 text-base">
              <f-icon icon="file"></f-icon> {{ document.title }}<br/>
              <span class="text-slate-400">{{ document.description }}</span>
            </div>
            <div class="p-3 bg-blue-400 text-white rounded-md text-base text-center">
              <a :href="document.link" target="_blank"><f-icon icon="up-right-from-square" class="mr-1"></f-icon> {{ $t('actions.go_to_doc') }}</a>
            </div>
          </div>
        </div>

        <!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          <div class="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 bg-red-400">prova</div>
          <div class="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 bg-red-400">prova</div>
          <div class="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 bg-red-400">prova</div>
          <div class="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 bg-red-400">prova</div>
          <div class="col-span-1 md:col-span-1 lg:col-span-1 xl:col-span-1 bg-red-400">prova</div>
        </div> -->
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import BaseLayout from '@/Layout/Base'

export default {
  name: 'Wiki',
  components: {
    BaseLayout
  },
  data: function(){
    return {
      documentations: [
        {title: 'Scheda SCAN ver. 4.0', description: 'Documentazione descrizione standard SCAN di ICCD', link: 'http://www.iccd.beniculturali.it/getFile.php?id=8661'},
        {title: 'Thesaurus Tipologia di bene', description: 'File excel con le tipologie di beni gerarchici (gruppo di campi OG della Scheda SCAN)', link: 'http://www.iccd.beniculturali.it/it/ricercanormative/186/thesaurus-generale-per-la-definizione-dei-beni-culturali'},
      ]
    }
  }
}
</script>