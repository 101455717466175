<template>
  <div class="modal modal-shapefile-exporter">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-8">
      <h1 class="text-xl">{{ title }}</h1>
      <p class="my-5" v-html="text" />
      <select v-model="geometry" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        <option disabled value="">{{ $t('global.select') }}</option>
        <option v-for="(option, index) in types" :key="`geometry-option-${index}`" :value="option.value">
          {{ option.name }}
        </option>
      </select>
      <VSelect v-if="categoryModel"
        v-model="category"
        :model="categoryModel"
        :search-field="'name'"
        :label="'name'"
        value-field="id"
        @selected="getSelected"
        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
      <div class="actions mt-5 flex justify-between">
        <button class="bg-green-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="exportShapefile()">{{ exportText }}</button>
        <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'ModalShapefileExporter',
  components: {
    LoadingSpinner
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    exportText: {
      type: String,
      default: 'Export'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String
    },
    categoryModel: {
      type: String,
      required: true
    },
    archive: {
      type: Number,
      default: null
    },
    update: {}
  },
  data: function(){
    return {
      showError: false,
      error: [],
      isLoading: false,
      download: true,
      geometry: null,
      category: null,
      categoryName: 'data',
      types: [
        {value: 'multipointz',  name: 'MultiPointZ'},
        {value: 'pointz',       name: 'PointZ'},
        {value: 'polygonz',     name: 'PolygonZ'}
      ]
    }
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async exportShapefile(){
      const vm = this
      if(!vm.geometry) {
        vm.$toasted.show(vm.$i18n.t('modals.error.missing_geometry')) 
        return
      }
      if(vm.categoryModel && !vm.category) { // TODO: category not mandatory (?)
        vm.$toasted.show(vm.$i18n.t('modals.error.missing_category')) 
        return
      }
      vm.isLoading = true
      try{
        api.download(`${vm.model}/export/shapefile`, {
            archive: vm.archive,
            geometry: vm.geometry,
            download: vm.download,
            category: vm.category,
            filename: `${vm.model}-${vm.categoryName}-${vm.geometry}.zip`
          }).then(async (response) => {
            if(response.success){
              vm.isLoading = false
              vm.$toasted.show(vm.$i18n.t('modals.exporter.confirmed')) 
              vm.$emit('close')
            }else{
              vm.isLoading = false
              vm.$toasted.show(vm.$i18n.t('modals.error.export')) 
            }
        })
      }catch(e){
        vm.isLoading = false
        vm.$toasted.show(vm.$i18n.t('modals.error.export'))
      }
    },
    getSelected(selected) {
      if(selected) this.categoryName = this.slugify(selected.name)
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()
      // remove accents, swap ñ for n, etc
      let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
      let to   = "aaaaeeeeiiiioooouuuunc------"
      for(var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }
      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes
      return str
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
