// === ReSole RomeTransformed Plugin === //

import store from '@/store'

// Import Views
import Observations from './views/Observations'
import Observation from './views/Observation'
import Stratigraphies from './views/Stratigraphies'
import Stratigraphy from './views/Stratigraphy'
import Stratigraphies3D from './views/Stratigraphies3D'
import Zones from './views/Zones'
import Zone from './views/Zone'
import Methodologies from './views/Methodologies'
import Methodology from './views/Methodology'
import Types from './views/Types'
import Type from './views/Type'
import Interpretations from './views/Interpretations'
import Interpretation from './views/Interpretation'
import ArcheologicalArchives from './views/ArcheologicalArchives'
import ArcheologicalArchive from './views/ArcheologicalArchive'

const rometransformed = {
    name: 'rometransformed',
    routes: [
      // Observations
      {
        path: '/observations',
        name: 'observations',
        component: Observations,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/observations/edit/:id',
        name: 'observation.edit',
        component: Observation,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/observations/new',
        name: 'observation.new',
        component: Observation,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Stratigraphies
      {
        path: '/stratigraphies',
        name: 'stratigraphies',
        component: Stratigraphies,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/stratigraphies/edit/:id',
        name: 'stratigraphy.edit',
        component: Stratigraphy,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/stratigraphies/new',
        name: 'stratigraphy.new',
        component: Stratigraphy,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Stratigraphies 3D
      {
        path: '/stratigraphies-3d',
        name: 'stratigraphy.3d',
        component: Stratigraphies3D,
        props: {},
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Zones
      {
        path: '/zones',
        name: 'zones',
        component: Zones,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/zones/edit/:id',
        name: 'zone.edit',
        component: Zone,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/zones/new',
        name: 'zone.new',
        component: Zone,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Methodologies
      {
        path: '/methodologies',
        name: 'methodologies',
        component: Methodologies,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/methodologies/edit/:id',
        name: 'methodology.edit',
        component: Methodology,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/methodologies/new',
        name: 'methodology.new',
        component: Methodology,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Types
      {
        path: '/types',
        name: 'types',
        component: Types,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/types/edit/:id',
        name: 'type.edit',
        component: Type,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/types/new',
        name: 'type.new',
        component: Type,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // Interpretations
      {
        path: '/interpretations',
        name: 'interpretations',
        component: Interpretations,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/interpretations/edit/:id',
        name: 'interpretation.edit',
        component: Interpretation,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      {
        path: '/interpretations/new',
        name: 'interpretation.new',
        component: Interpretation,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(!user.id) next({name: 'login'})
          next()
        }
      },
      // ArcheologicalArchive
      {
        path: '/archeological_archives',
        name: 'interpretations',
        component: ArcheologicalArchives,
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(user.permissions.includes('manage_users')){
            next()
          }else{
            next({name: 'home'})
          }
        }
      },
      {
        path: '/archeological_archives/edit/:id',
        name: 'archeological_archive.edit',
        component: ArcheologicalArchive,
        props: { mode: 'edit', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(user.permissions.includes('manage_users')){
            next()
          }else{
            next({name: 'home'})
          }
        }
      },
      {
        path: '/archeological_archives/new',
        name: 'archeological_archive.new',
        component: ArcheologicalArchive,
        props: { mode: 'new', },
        beforeEnter: (to, from, next) => {
          let user = store.getters['auth/user']
          if(user.permissions.includes('manage_users')){
            next()
          }else{
            next({name: 'home'})
          }
        }
      },
    ],
    menu: {
      cards: [
        {route: 'separator',            permissions: ['manage_stratigraphies'], label: 'plugins.rometransformed.menu.cards', items: []},
        {route: '/observations',      permissions: ['manage_stratigraphies'], label: 'plugins.rometransformed.menu.observations', icon: 'bullseye'},
        {route: '/stratigraphies',    permissions: ['manage_stratigraphies'], label: 'plugins.rometransformed.menu.stratigraphies', icon: 'layer-group'},
        {route: '/archeological_archives',    permissions: ['manage_users'], label: 'plugins.rometransformed.menu.archeological_archives', icon: 'box-archive'},
        {route: '/stratigraphies-3d', permissions: ['manage_stratigraphies'], label: 'plugins.rometransformed.menu.stratigraphies_3d', icon: 'layer-group'},
      ],
      taxonomies: [
        {route: 'separator',            permissions: ['manage_users'], label: 'plugins.rometransformed.menu.taxonomies', items: []},
        {route: '/zones',             permissions: ['manage_users'], label: 'plugins.rometransformed.menu.zones', icon: 'map-location'},
        {route: '/types',             permissions: ['manage_users'], label: 'plugins.rometransformed.menu.types', icon: 'bore-hole'},
        {route: '/methodologies',     permissions: ['manage_users'], label: 'plugins.rometransformed.menu.methodologies', icon: 'bong'},
        {route: '/interpretations',   permissions: ['manage_users'], label: 'plugins.rometransformed.menu.interpretations', icon: 'timeline'},
      ]
    },

    models: ['stratigraphies', 'observations'],
    dashboard: {
      stats: 'rometransformed',
      charts: [
        {id: 'chart-stratigraphies', title: 'Stratigraphies', model: 'stratigraphies', stats: 'stratigraphies', type: 'doughnut', },
        {id: 'chart-observations', title: 'Observation Points', model: 'observations', stats: 'observations', type: 'doughnut'},
      ]
    },
    messages: {
      it: {
        menu: {
          observations: 'Punti di osservazione',
          stratigraphies: 'Stratigrafie',
          zones: 'Zone',
          types: 'Tipologie',
          methodologies: 'Metodologie',
          interpretations: 'Interpretazioni',
          stratigraphies_3d: 'Stratigrafie 3D',
          lemmas: 'Lemmi',
          cards: 'RT3D',
          taxonomies: 'RT3D'
        },
        views: {
          observations: {
            title: 'Punti di osservazione',
            subtitle: 'Punti di osservazione',
            single: 'Punto di osservazione',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              observation: 'Punto di osservazione',
              card: 'Scheda',
              archive: 'Archivio',
              location_separator: 'Dati geometrici del punto di osservazione',
              x: 'X',
              y: 'Y',
              quota: 'Quota',
            },
            tips: {
              is_visible: 'Visibile agli altri operatori',
              is_published: 'Può essere esportata'
            },
            locations: {
              pivot: 'Campi di relazione per le Geometrie GIS',
            },
          },
          stratigraphies: {
            title: 'Stratigrafie',
            subtitle: 'Stratigrafie',
            single: 'Stratigrafia',
            fields: {
              code: 'Codice',
              name: 'Nome',
              x: 'X',
              y: 'Y',
              top: 'Tetto',
              bottom: 'Letto',
              description: 'Descrizione',
              observation: 'Punto di osservazione',
              archive: 'Archivio',
              zone: 'Zona',
              methodology: 'Metodologia',
              type: 'Tipologia',
              function: 'Funzione', 
              interpretation: 'Interpretazione', 
              start_date: 'Data inizio', 
              end_date: 'Data fine', 
              source: 'Sorgente', 
              bibliography: 'Bibliografia',
              location_separator: 'Dati geometrici della stratigrafia'
            },
            zone: {
              title: 'Associa le Zone alla Stratigrafia',
              text: 'Aggiorna le associazioni della Stratigrafia alle Zone'
            },
            methodology: {
              title: 'Associa le Metodologie alla Stratigrafia',
              text: 'Aggiorna le associazioni della Stratigrafia alle Metodologie'
            },
            type: {
              title: 'Associa le Tipologie alla Stratigrafia',
              text: 'Aggiorna le associazioni della Stratigrafia alle Tipologie'
            },
            interpretation: {
              title: 'Associa le Interpretazioni alla Stratigrafia',
              text: 'Aggiorna le associazioni della Stratigrafia alle Interpretazioni'
            },
            importer_archive_text: 'Seleziona un Archivio dove salvare queste Stratigrafie',
            uploader_text: 'Carica la documentazione alla scheda',
            location: {
              title: 'Dati GIS associati'
            },
            tips: {
              is_visible: 'Visibile agli altri operatori',
              is_published: 'Può essere esportata'
            }
          },
          zones: {
            title: 'Zone',
            subtitle: 'Zone',
            single: 'Zona',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
            }
          },
          methodologies: {
            title: 'Metodologie',
            subtitle: 'Metodologie',
            single: 'Metodologia',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              color: 'Colore'
            }
          },
          types: {
            title: 'Tipologie',
            subtitle: 'Tipologie',
            single: 'Tipologia',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              function: 'Funzione', 
              interpretation: 'Interpretazione', 
              start_date: 'Data inizio', 
              end_date: 'Data fine', 
              source: 'Sorgente', 
              bibliography: 'Bibliografia'
            }
          },
          interpretations: {
            title: 'Interpretazioni',
            subtitle: 'Interpretazioni',
            single: 'Interpretazione',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              color: 'Colore'
            }
          },
          stratigraphies_3d: {
            title: 'Stratigrafie 3D',
            subtitle: '',
            single: 'Stratigrafia 3D',
          },
          archeological_archives: {
            title: 'Fondi archeologici',
            subtitle: 'Dati aggiuntivi ai fondi per la gestione dei dati stratigrafici',
            single: 'Fondo archeologico',
            fields: {
              code: 'Codice',
              name: 'Nome',
              description: 'Descrizione',
              archive: 'Archivio',
              nw: 'Latidutine Confine Nord-Ovest',
              ne: 'Longitudine Confine Nord-Ovest',
              sw: 'Latidutine Confine Sud-Est',
              se: 'Longitudine Confine Sud-Est',
              center_x: 'Latidutine del centro ambiente 3D',
              center_y: 'Longitudine del centro ambiente 3D', 
              crs: 'Sistema di riferimento (SR, CRS) in formato EPSG',
            },
            uploader_text: `Carica i file da associare all'archivio. Imposta la cover come sfondo per l'ambiente 3D`,
            separator: `Dati aggiuntivi per la gestione dell'ambiente 3D dei dati geografici`,
            interpretations: {
              name: `Interpretazioni associate all'archivio`,
              title: 'Titolo',
              add_new: 'Aggiungi nuovo',
              tip: `Aggiungi i periodi storici all'archivio`,
              separator: `Gestisci le interpretazioni da associare all'archivio`
            }
          }
        }
      },
      en: {
        menu: {
          observations: 'Observations',
          stratigraphies: 'Stratigraphies',
          zones: 'Zones',
          types: 'Types',
          methodologies: 'Methodologies',
          interpretations: 'Interpretations',
          stratigraphies_3d: '3D Stratigraphies',
          lemmas: 'Lemmas',
          cards: 'RT3D',
          taxonomies: 'RT3D',
          archeological_archives: 'Fondi archeologici',
        },
        views: {
          observations: {
            title: 'Observation Points',
            subtitle: 'Observation Points',
            single: 'Observation Point',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              observation: 'Observation',
              card: 'Card',
              archive: 'Archive',
              location_separator: 'Observation point geometric data',
              x: 'X',
              y: 'Y',
              quota: 'Quota',
            },
            tips: {
              is_visible: 'Visible to other compilers',
              is_published: 'Can be exported'
            },
            locations: {
              pivot: 'Location GIS Relationships field',
            },
          },
          stratigraphies: {
            title: 'Stratigraphies',
            subtitle: 'Stratigraphies',
            single: 'Stratigraphy',
            fields: {
              code: 'Code',
              name: 'Name',
              x: 'X',
              y: 'Y',
              top: 'Top level',
              bottom: 'Bottom level',
              description: 'Description',
              observation: 'Observation Point',
              archive: 'Archive',
              zone: 'Zone',
              methodology: 'Methodology',
              type: 'Type',
              function: 'Function', 
              interpretation: 'Interpretation', 
              start_date: 'Start date', 
              end_date: 'End date', 
              source: 'Source', 
              bibliography: 'Bibliography',
              location_separator: 'Stratigraphy geometric data'
            },
            zone: {
              title: 'Associate Zones to Stratigraphy',
              text: 'Update associations of Stratigraphy to zones'
            },
            methodology: {
              title: 'Associate Methodologies to Stratigraphy',
              text: 'Update associations of Stratigraphy to methodologies'
            },
            type: {
              title: 'Associate Types to Stratigraphy',
              text: 'Update associations of Stratigraphy to types'
            },
            interpretation: {
              title: 'Associate Interpretations to Stratigraphy',
              text: 'Update associations of Stratigraphy to interpretations'
            },
            importer_archive_text: 'Select an archive where to store those stratigraphies',
            uploader_text: 'Upload documentation to card',
            location: {
              title: 'Associated GIS data'
            },
            tips: {
              is_visible: 'Visible to other compilers',
              is_published: 'Can be exported'
            }
          },
          zones: {
            title: 'Zones',
            subtitle: 'Zones',
            single: 'Zone',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
            }
          },
          methodologies: {
            title: 'Methodologies',
            subtitle: 'Methodologies',
            single: 'Methodology',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              color: 'Color'
            }
          },
          types: {
            title: 'Types',
            subtitle: 'Types',
            single: 'Type',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              function: 'Function', 
              interpretation: 'Interpretation', 
              start_date: 'Start date', 
              end_date: 'End date', 
              source: 'Source', 
              bibliography: 'Bibliography'
            }
          },
          interpretations: {
            title: 'Interpretations',
            subtitle: 'Interpretations',
            single: 'Interpretation',
            fields: {
              code: 'Code',
              name: 'Name',
              description: 'Description',
              color: 'Color'
            }
          },
          stratigraphies_3d: {
            title: '3D Stratigraphies',
            subtitle: '3D Stratigraphies',
            single: '3D Stratigraphy',
          }
        }
      }
    }
}

export default rometransformed