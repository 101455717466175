var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"observations"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.rometransformed.views.observations.subtitle'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('CrudTable',{attrs:{"title":_vm.$t('plugins.rometransformed.views.observations.title'),"model":"observations","model-name-field":"code","check-owner":true,"check-roles":['stratigraphy_manager'],"columns":[
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.code'), field: 'code', related: 'card'},
          {name: _vm.$t('plugins.rometransformed.views.stratigraphies.title'), field: 'stratigraphies_count'},
          {name: _vm.$t('plugins.rometransformed.views.observations.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
          {name: _vm.$t('views.cards.fields.user'), parent: 'user', field: 'name', related: 'card'},
          {field: 'actions'},
        ],"search-filters":[
          {field: 'code',     type: 'text',   label: _vm.$t('plugins.rometransformed.views.observations.fields.code')},
          {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: _vm.$t('plugins.rometransformed.views.observations.fields.archive'), placeholder: _vm.$t('views.archives.select')}
        ]}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }