<template>
  <div ref="spinner" class="loading-wrapper">
    <div class="loading-container">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>
<script>
// TODO gestire la grafica nuova con tailwind o gif

export default {
  props: {
    toggle: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    toggle: function(val) {
      if (val) {
        //UIkit.modal(this.$refs.spinner).show()
        this.$refs.spinner.style.display="flex"
      } else {
        //UIkit.modal(this.$refs.spinner).hide()
        this.$refs.spinner.style.display="none"
      }
    }
  }
}
</script>
<style lang="less" scoped>
.loading-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    align-items: center;
    left: 0;
    top: 0;
    background: rgba(255,255,255,0.7);
    z-index: 1;
    display: none;
    justify-content: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate (360deg);
  }
}
.loading-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading-spinner {
  border-top: 4px #42B9B6 solid;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  animation: spin 1000ms linear infinite;
}
</style>