<template>
  <div class="stratigraphies_3d">
    <BaseLayout>
      <template v-slot:main>
        <div v-if="!archive" class="grid grid-cols-4 gap-4 my-10">
          <div class="col-span-3 text-white py-3 px-5">{{ $t('components.threed_visualizer.select_archive') }}</div>
          <VSelect 
            v-model="archive"
            model="archeological_archives"
            search-field="name"
            label="name"
            value-field="id"
            :placeholder="$t('global.select')"
            @selected="getArchive"
            class="col-span-1 p-1 mx-4 my-2 border text-xs border-slate-200 bg-white rounded focus:outline-none" />
          
        </div>
        <div v-if="archive && hasThreeDData()">
          <!-- <div>
            <ul>
              <li><img :src="getBackground()" alt="background" /></li>
              <li>{{ archive.center_x }}</li>
              <li>{{ archive.center_y }}</li>
              <li>{{ archive.nw }}</li>
              <li>{{ archive.ne }}</li>
              <li>{{ archive.sw }}</li>
              <li>{{ archive.se }}</li>
            </ul>
          </div> -->
          <div class="box-content px-4 py-2 text-white flex items-center justify-between">
            <div class="left">
              <h2 class="text-xl">{{ $t('plugins.rometransformed.views.stratigraphies_3d.subtitle') }}</h2>
              <button @click="clearCanvas" class="px-3 py-2 text-sm border-slate-900 bg-slate-600 text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:bg-slate-900"><f-icon icon="circle-minus" /> {{ $t('actions.clear_canvas') }}</button>
            </div>
            <div class="right flex gap-1">
              
              <ButtonShapefileExporter :title="$t('actions.export_shapefile')" model="stratigraphies" :archive="archive.id" categoryModel="interpretations" />
              <ButtonShapefileExporter title="Observations SHP" model="observations" :archive="archive.id" />
            </div>
          </div>
          <!--:background="require(`@/plugins/rometransformed/assets/background.jpg`)"
          :model-file="require(`@/plugins/rometransformed/assets/dtm.obj`)"
          :model-texture="require(`@/plugins/rometransformed/assets/Terrain_DEM_tile_material.jpg`)"-->
          <ThreeDVisualizer 
            model="gis/geometries/stratigraphies"
            :archive="archive.id"
            :background="getBackground()"
            :model-options="{
              scale: 18.5, rotation: -90, pan: true, height: 30, 
              center_x: archive.center_x, 
              center_y: archive.center_y, 
              nw: archive.nw,
              ne: archive.ne,
              sw: archive.sw,
              se: archive.se
            }"
            :fields="[
              {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', related: 'cardable', type: 'text'},
              {name: $t('views.cards.fields.code'),                                         field: 'code', type: 'text'},
              {name: $t('views.cards.fields.archive'),                                      field: 'name', related: 'archive', type: 'text'},
              {name: $t('views.cards.fields.tags'),                                      field: 'name', related: 'tags', type: 'text'},
              {name: $t('plugins.rometransformed.views.observations.single'),               field: 'name', related: 'cardable', subrelated: 'observation', type: 'text'},
              {name: $t('plugins.rometransformed.views.types.single'),                      field: 'name', related: 'cardable', subrelated: 'types', relation: 'multiple', type: 'text'},
              {name: $t('plugins.rometransformed.views.methodologies.single'),              field: 'name', related: 'cardable', subrelated: 'methodologies', relation: 'multiple', type: 'text'},
              {name: $t('plugins.rometransformed.views.zones.single'),                      field: 'name', related: 'cardable', subrelated: 'zones', relation: 'multiple', type: 'text'},
              {name: $t('plugins.rometransformed.views.interpretations.single'), 
                related: 'cardable',
                field: 'interpretation_id', model: 'interpretations', type: 'lemma', label: 'name',
                pivots: [ 
                  {name: $t('plugins.rometransformed.views.stratigraphies.fields.description'), field: 'description', type: 'text'},
                ],
                name: $t('plugins.rometransformed.views.stratigraphies.fields.interpretation'), 
                title: $t('plugins.rometransformed.views.stratigraphies.interpretation.title')},
                {name: $t('plugins.rometransformed.views.observations.single'), field: 'code', related: 'cardable', subrelated: 'observation', type: 'text'},
                {name: $t('plugins.rometransformed.views.observations.fields.quota'), field: 'quota', related: 'cardable', subrelated: 'observation', type: 'number'},
            ]"
            :localizations="[
              {slug: 'stratigraphies', single: $t('plugins.rometransformed.views.stratigraphies.single'), name: $t('plugins.rometransformed.views.stratigraphies.single')},
              {slug: 'observations', single: $t('plugins.rometransformed.views.observations.single'), name: $t('plugins.rometransformed.views.observations.single')},
            ]"
            legend="legend/interpretations"
            :legend-name="$t('plugins.rometransformed.views.interpretations.title')" />
        </div>
        <div v-else-if="archive && !hasThreeDData()" class="h-full w-full text-white text-center py-20 flex flex-col items-center">
          <div class="mb-50">{{ $t('components.threed_visualizer.archive_missing_data') }}</div>
          <button @click="clearCanvas" class="mt-5 px-3 py-2 text-sm border-slate-900 bg-slate-600 text-white rounded-lg transition-all hover:border-opacity-100 hover:bg-opacity-0 hover:bg-slate-900"><f-icon icon="circle-minus" /> {{ $t('actions.clear_canvas') }}</button>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>
<script>

import _ from 'lodash'
import BaseLayout from '@/Layout/Zen'
import Api from '@/components/Api'
import ThreeDVisualizer from '@/components/ThreeDVisualizer'
import ButtonShapefileExporter from '@/components/ButtonShapefileExporter'

export default {
  name: 'Stratigraphies3D',
  data() {
    return {
      archive: null,
    }
  },
  mixins: [
    Api
  ],
  components: {
    BaseLayout, ThreeDVisualizer, ButtonShapefileExporter
  },
  methods: {
    getArchive(archive){
      this.archive = _.clone(archive)
    },
    getBackground(){
      if(process.env.NODE_ENV === 'production') {
        let background = null
        console.log('[getBackground]', this.archive)
        if(this.archive.files && this.archive.files.length > 0) this.archive.files.forEach(file => {
          if(file.is_cover) background = file.url
        })
        return background
      }else{
        // perché in locale ThreeJS da errore CORS
        return 'https://app.ereditaculturali.sagas.unifi.it/backgrounds/RTA-001.jpg'
      }
    },
    hasThreeDData(){
      console.log('[hasThreeDData]', this.archive, this.archive ? this.getBackground(this.archive) : 'no archive...')
      return this.getBackground(this.archive) && this.archive.center_x && this.archive.center_y && this.archive.nw && this.archive.ne && this.archive.sw && this.archive.se
    },
    clearCanvas(){
      this.archive = null

    }
  }
}
</script>