<template>
  <div class="modal modal-delete">
    <div class="p-8">
      <h1 class="text-xl break-words">{{ title }}</h1>
      <p class="my-5">{{ text }}</p>
      <div class="actions mt-5 flex justify-between">
          <button class="bg-red-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="destroy(entity)">{{ deleteText }}</button>
          <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import Api from '@/components/Api'

export default {
  name: 'ModalDelete',
  mixins: [
    Api
  ],
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    deleteText: {
      type: String,
      default: 'Delete'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    entity: {
      type: [Object, Array]
    },
    model: {
      type: String
    },
    multiple: {
      type: Boolean,
      default: false
    },
    update: {}
  },
  data: function(){
    return {
      showError: false,
      error: []
    }
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async destroy(entity){
      let destroyed = null
      if(this.multiple){
        destroyed = await this.batch('delete', this.model, {data: entity})
        if(destroyed.success){
          this.update('reload') // emit a reload param to parent component in order to reload entire page
          this.$emit('close', entity)
        }else{
          this.$toasted.show(destroyed.message)
        }
      }else{
        destroyed = await this.delete(this.model, entity.id)
        console.log(destroyed)
        if(destroyed){
          this.update(entity)
          this.$emit('close', entity)
          this.$emit('deleted', entity)
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
