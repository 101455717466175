<template>
  <vue-select
    v-model="selected"
    @input="emit"
    @search="onSearch"
    :options="options"
    :label="label"
    :placeholder="selectPlaceholder"
    :taggable="taggable"
    :ref="`select-${model}`"
    :class="classes"
    :disabled="disabled"> <!--@click="getOptions"-->
      <span slot="no-options">{{ $t('global.no_results') }}</span>
      <template v-slot:option="option">
        <div class="my-1">
          <f-icon v-if="icon" class="text-sm pr-1" :icon="iconCode(option[iconName])" />
          <span v-html="labels.length > 0 ? getLabel(option, labels) : option[label]"></span>
          <span v-if="icon" class="rounded-sm bg-gray-300 text-xs text-green-800 py-1 px-1 my-1">{{ extension(option[iconName]) }}</span>
        </div>
      </template>
  </vue-select>  
</template>
<script>
  import _ from 'lodash'
  import api from '@/services/api'
  
  export default {
    props: {
      label: {
        type: String,
        default: "name"
      },
      labels: {
        type: Array,
        default: () => []
      },
      valueField: {
        type: String,
        default: 'id'
      },
      value: {
        required: true,
      },
      model: {
        type: String,
        required: true,
        default: 'cards'
      },
      searchField: {
        type: String,
        default: 'search'
      },
      delay: {
        type: Number,
        default: 500
      },
      placeholder: {
        type: String
      },
      taggable: {
        type: Boolean,
        default: false
      },
      classes: {
        default: null
      },
      disable: {
        default: false
      },
      icon: {
        default: false
      },
      iconName: {
        default: null
      },
      init: {
        type: Boolean,
        default: true
      },
      extraParams: {
        type: Object,
        default: () => {}
      },
      defaultOptions: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        options: [],
        selected: null,
        disabled: false
      }
    },
    computed: {
      selectPlaceholder(){
        return this.placeholder || this.$t('actions.select_one')
      }
    },
    created() {
      if (this.value) this.getSelected()
      this.updateOptions = _.debounce(this.getOptions, this.delay)
      if(this.init) this.getOptions('', null, this)
      this.disabled = this.disable
    },
    methods: {
      onSearch: function(search, loading) {
        loading(true)
        this.updateOptions(search, loading, this)
      },
      updateOptions: () => {},
      async getOptions(search, loading, vm) {
        var params = {}
        if(vm.extraParams) params = vm.extraParams
        params[vm.searchField] = search
        params['type'] = 'associations' // in order to not fail access route
        if(this.defaultOptions.length > 0){
          //console.log('[print defaultOptions]', this.defaultOptions)
          vm.options = _.clone(this.defaultOptions)
          if(loading) loading(false)
        }else{
          await api.fetch(vm.model, null, {'params': params}).then((result) => {
            vm.options = result.data
            if(loading) loading(false)
          })
        }
      },
      async getSelected() {
        const vm = this
        if (vm.value) {
          if(vm.defaultOptions.length > 0){
            console.log('[filter selected...]', vm.value)
            vm.selected = _.clone(vm.defaultOptions.filter((option) => option.value === vm.value))
          }else{
            await api.fetch(vm.model, vm.value).then((result) => {
              vm.selected = result
            })
          }
        } else {
          vm.selected = _.clone(vm.value)
        }
      },
      emit: function(val = null) {
        const vm = this
        if (!val) {
          vm.subLabelText = null
          this.$emit('input', null)
          this.$emit('select', null)
        } else {
          const newVal = (val[this.valueField]) ? val[this.valueField] : val
          this.$emit('input', newVal)
          this.$emit('select', val)
          this.$emit('selected', vm.selected)
        }
      },
      extension(file){
        let re = /(?:\.([^.]+))?$/
        return re.exec(file)[1]
      },
      iconCode(file){
        let ext = this.extension(file)
        switch(ext){
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'tif':
            return 'image'
          case 'obj':   
            return 'cube'
          case 'xls':
          case 'xlsx':
          case 'csv':
            return 'file-excel'
          default:      
            return 'file'
        }
      },
      getLabel(option, labels){
        let text = ''
        let counter = 0
        labels.forEach(label => { 
          if(option[label]) text += (counter === 0 && label === 'code' ? '<span class="bg-primary text-xs text-white px-2 py-1 mr-2 rounded">' : '') + option[label] + (counter === 0 ? '</span> ' : ' ')
          counter++
        })
        return text
      }
    }
  }
</script>
<style>
.v-select .vs__actions {
  cursor: pointer;
}
.v-select .vs__dropdown-toggle {
  border: none;
  max-height: 35px;
}
.v-select .vs__dropdown-menu {
  @apply mt-1 border border-gray-300 bg-white rounded-md shadow-sm;
}
.v-select .vs__selected-options {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.v-select input::placeholder {
  font-size: 0.75rem;
  margin-top: 0px;
}
.vs__search, .vs__search:focus {
  margin-top: 0px;
}
</style>
