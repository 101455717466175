var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"archeological_archives"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.rometransformed.views.archeological_archives.single'))+" ")]},proxy:true},{key:"main",fn:function(){return [(_vm.canLoad)?_c('CrudEntity',{attrs:{"model":_vm.model,"id":_vm.id,"mode":_vm.crud_mode,"entity":_vm.entity,"is-extended-card":true,"extends":"archive","fields":[
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.code'), field: 'code', type: 'text'},
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.name'), field: 'name', type: 'text'},
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.archive'), field: 'archive_id', type: 'select', model: 'archives', label: 'name' },
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.description'), field: 'description', type: 'text-editor', span: '2'},
          {text: _vm.$t('plugins.rometransformed.views.archeological_archives.separator'), type: 'separator'},       
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.nw'), field: 'nw', type: 'text'},          
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.ne'), field: 'ne', type: 'text'},          
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.sw'), field: 'sw', type: 'text'},          
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.se'), field: 'se', type: 'text'},          
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.center_x'), field: 'center_x', type: 'text'},    
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.center_y'), field: 'center_y', type: 'text'},    
          {name: _vm.$t('plugins.rometransformed.views.archeological_archives.fields.crs'), field: 'crs', type: 'text'},

          // Interpretations
          {text: _vm.$t('plugins.rometransformed.views.archeological_archives.interpretations.separator'), type: 'separator'},
          {
            field: 'interpretations', type: 'relations-manager', model: 'archeological_archives', relatedModel: 'interpretations', relatedSearchField: 'name', relatedLabel: 'name', labels: ['name'], span: '4',
            pivots: [],
            name: _vm.$t('plugins.rometransformed.views.archeological_archives.interpretations.name'), 
            title: _vm.$t('plugins.rometransformed.views.archeological_archives.interpretations.title'),
            addNewText: _vm.$t('plugins.rometransformed.views.archeological_archives.interpretations.add_new'),
            tip: _vm.$t('plugins.rometransformed.views.archeological_archives.interpretations.tip'),
            labeled: true
          },
        ]}}):_vm._e(),(_vm.id)?_c('div',{staticClass:"mt-2"},[(_vm.canLoad)?_c('FileManager',{attrs:{"mode":_vm.crud_mode,"model-id":parseInt(_vm.id),"model":_vm.model,"entity-data":_vm.entity,"related":false,"uploaderText":_vm.$t('plugins.rometransformed.views.archeological_archives.uploader_text'),"file-types-allowed":[
            {name: 'PNG', type: 'image/png'},
            {name: 'JPG', type: 'image/jpeg'},
            {name: 'TIFF', type: 'image/tiff'},
            {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
            {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            {name: 'OBJ', type: 'text/plain'},
          ],"file-model-types":[
            {type: 'document', name: _vm.$t('views.cards.files.types.document'),  fields: ['description']},
            {type: 'raster',   name: _vm.$t('views.cards.files.types.raster'),    fields: ['description', 'ne_lat', 'ne_lng', 'sw_lat', 'sw_lng', 'elevation']},
            {type: 'image', name: _vm.$t('views.cards.files.types.image'),  fields: ['description']},
            {type: 'photo', name: _vm.$t('views.cards.files.types.photo'),  fields: ['description']},
            {type: 'threed_model', name: _vm.$t('views.cards.files.types.threed_model'), fields: ['description', 'file_id']},
            {type: 'threed_texture', name: _vm.$t('views.cards.files.types.threed_texture'), fields: ['description', 'file_id']},
            {type: 'spheric_picture', name: _vm.$t('views.cards.files.types.spheric_picture'), fields: ['description']},
          ],"file-model-fields":[
            {name: _vm.$t('views.cards.files.fields.description'), field: 'description', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.crs'), field: 'crs', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lat'), field: 'ne_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.ne_lng'), field: 'ne_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lat'), field: 'sw_lat', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.sw_lng'), field: 'sw_lng', type: 'text'},
            {name: _vm.$t('views.cards.files.fields.file_id'), field: 'file_id', type: 'select', related: 'files', label: 'id', placeholder: _vm.$t('views.cards.files.fields.file_select')},
            {name: _vm.$t('views.cards.files.fields.elevation'), field: 'elevation', type: 'text'},
          ]}}):_vm._e()],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }