<template>
  <div class="modal modal-importer">
    <LoadingSpinner :toggle="isLoading" />
    <div class="p-8">
      <h1 class="text-xl">{{ title }}</h1>
      <p class="my-5" v-html="text" />
      <CrudInput
        v-model="format"
        type="radio"
        :field="field"
        classes="h-5 w-5 mt-2 ml-1 border-gray-50 text-teal-600 flex flex-row" />
      <div class="actions mt-5 flex justify-between">
        <button class="bg-green-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="exportCards()">{{ exportText }}</button>
        <button class="bg-gray-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded" @click="closeModal()">{{ cancelText }}</button>
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/services/api'
import LoadingSpinner from '@/components/LoadingSpinner'
import CrudInput from '@/components/CrudInput'

export default {
  name: 'ModalExporter',
  components: {
    LoadingSpinner, CrudInput
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    exportText: {
      type: String,
      default: 'Export'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    model: {
      type: String
    },
    update: {},
    filters: {
      type: Object
    }
  },
  data: function(){
    return {
      showError: false,
      error: [],
      isLoading: false,
      field: {
        'field': 'format',
        'options': [
          {'value': 'excel', 'label': 'Excel'},
          {'value': 'pdf', 'label': 'PDF'},
        ]
      },
      format: 'excel'
    }
  },
  methods: {
    closeModal(){
      this.$emit('close')
    },
    async exportCards(){
      this.isLoading = true
      try{
        this.filters['format'] = this.format
        api.create(`${this.model}/export`, this.filters)
        this.isLoading = false
        this.$toasted.show(this.$i18n.t('modals.exporter.confirmed')) 
        this.$emit('close')
      }catch(e){
        this.isLoading = false
        this.$toasted.show(this.$i18n.t('modals.error.export')) 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
