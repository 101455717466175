var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"f_cards"},[_c('BaseLayout',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('plugins.ereditaculturali.views.f_cards.subtitle'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('CrudTable',{attrs:{"title":_vm.$t('plugins.ereditaculturali.views.f_cards.title'),"model":"f_cards","model-name-field":"code","check-owner":true,"check-roles":['archive_manager', 'card_manager'],"columns":[
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.code'), field: 'code', related: 'card'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.image'), field: 'code', related: 'card', view: 'thumb'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.archive'), parent: 'archive', field: 'name', related: 'card'},
          {name: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.collection'), parent: 'collection', field: 'name', model: 'collections'},
          {name: _vm.$t('views.cards.fields.is_visible'), field: 'is_visible', related: 'card', view: 'check', icon: 'eye'},
          {name: _vm.$t('views.cards.fields.is_published'), field: 'is_published', related: 'card', view: 'check', icon: 'globe'},
          {field: 'actions'},
        ],"cards-importer":true,"cards-importer-archive-text":_vm.$t('plugins.ereditaculturali.views.f_cards.importer_archive_text'),"cards-import-model":"f-card-import-model.xlsx","cards-file-types-allowed":[
          {name: 'Excel (xls)', type: 'application/vnd.ms-excel'},
          {name: 'Excel (xlsx)', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
          {name: 'CSV', type: 'text/csv'}
        ],"search-filters":[
          {field: 'code',     type: 'text',   label: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.code')},
          {field: 'archive_id', type: 'select', model: 'archives', search_field: 'name', label: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.archive'), placeholder: _vm.$t('views.archives.select')},
          {field: 'collection_id', type: 'select', model: 'collections', search_field: 'name', label: _vm.$t('plugins.ereditaculturali.views.f_cards.fields.collection'), labels: ['code', 'name'], placeholder: _vm.$t('plugins.ereditaculturali.views.collections.select')}
        ],"media-importer":true,"media-importer-text":_vm.$t('plugins.ereditaculturali.views.f_cards.importer_media_text'),"media-file-types-allowed":[
          {name: 'PNG', type: 'image/png'},
          {name: 'JPG', type: 'image/jpeg'},
          {name: 'TIFF', type: 'image/tiff'}
        ]}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }